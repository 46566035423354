<template>
    <div class="p-4">
        <div class="text-xl text-center font-black mb-4">Choose pattern</div>

        <div class="w-full relative z-20 pb-28 h-screen overflow-y-auto">
            <div
                @click="setModule(1), setPopup('graf'), haptic()"
                class="flex justify-start items-center mb-4 p-4 bg-cyan-600/30 border border-cyan-600/50 text-cyan-100 text-base rounded backdrop-blur-sm"
            >
                <div class="flex justify-start items-center gap-2">
                    <CandleHummer />
                    <div>Hummer</div>
                </div>
            </div>

            <div
                @click="setModule(2), setPopup('graf'), haptic()"
                class="flex justify-between items-center mb-4 p-4 bg-cyan-600/30 border border-cyan-600/50 text-cyan-200 opacity-50 text-base rounded backdrop-blur-sm"
            >
                <div class="flex justify-start items-center gap-2">
                    <CandleHummer />
                    <div>Doji</div>
                </div>

                <span class="text-sm">locked</span>
            </div>

            <div
                @click="setPopup('graf'), haptic()"
                class="flex justify-between items-center mb-4 p-4 bg-cyan-600/30 border border-cyan-600/50 text-cyan-200 opacity-50 text-base rounded backdrop-blur-sm"
            >
                <div class="flex justify-start items-center gap-2">
                    <CandleHummer />
                    <div>Three soldiers / crows</div>
                </div>

                <span class="text-sm">locked</span>
            </div>

            <div
                @click="setPopup('graf'), haptic()"
                class="flex justify-between items-center mb-4 p-4 bg-cyan-600/30 border border-cyan-600/50 text-cyan-200 opacity-50 text-base rounded backdrop-blur-sm"
            >
                <div class="flex justify-start items-center gap-2">
                    <CandleHummer />
                    <div>Engulfing</div>
                </div>

                <span class="text-sm">locked</span>
            </div>

            <!-- <div
                v-for="item in list"
                :key="item.id"
                class="flex justify-between items-center mb-4 p-4 bg-cyan-600/30 border border-cyan-600/50 text-cyan-200 opacity-50 text-base rounded backdrop-blur-sm"
            >
                Task <span class="text-sm">locked</span>
            </div> -->
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";
import { usePredictorStore } from "@/store/predictor";

export default {
    name: "TasksPage",

    data() {
        return {
            vivusInstance: null,
            list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "haptic"]),
        ...mapState(useUserStore, ["student", "setModule"]),
        ...mapState(usePredictorStore, ["training", "nextTraining", "sendAnswer"]),
    },

    methods: {},

    mounted() {},
};
</script>
