<template>
    <div class="px-4 h-screen overflow-hidden">
        <div class="h-[calc(100vh-124px)] flex flex-col justify-center items-center bg-white text-black text-center p-4 rounded-lg relative">
            <h2 class="text-3xl font-bold mb-8">Статистика</h2>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";

export default {
    name: "StatPage",

    data() {
        return {
            result: [],
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "haptic"]),
        ...mapState(useUserStore, ["student"]),
    },

    methods: {},
};
</script>
