import axios from "axios";
import authHeader from "./header";
// const API = "http://localhost/";
const API = "https://yii.botteach.ru/";
class UserService {
    GET(url, data) {
        return axios.get(API + url, { params: data, headers: authHeader() });
    }
    POST(url, formData) {
        return axios.post(API + url, formData, { headers: authHeader() });
    }

    goGET(url, data) {
        return axios.get("https://go.botteach.ru/" + url, { params: data, headers: authHeader() });
    }

    goPOST(url, formData) {
        return axios.post("https://go.botteach.ru/" + url, formData, { headers: authHeader() });
    }
}
export default new UserService();
