<template>
    <div>
        <canvas ref="canvas"></canvas>
    </div>
</template>

<script>
export default {
    name: "StarsBackground",

    mounted() {
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext("2d");
        const stars = [];

        class Star {
            constructor(x, y, size, speed, direction, opacity) {
                this.x = x;
                this.y = y;
                this.size = size;
                this.speed = speed;
                this.direction = direction; // Направление движения: 1 - вправо, -1 - влево
                this.opacity = opacity; // Прозрачность звезды
            }

            update() {
                this.x += (this.speed * this.direction) / 10; // Уменьшаем скорость движения
                if (this.x > canvas.width) {
                    // Если звезда вышла за правую границу, перемещаем ее в левую часть экрана
                    this.x = 0;
                } else if (this.x < 0) {
                    // Если звезда вышла за левую границу, перемещаем ее в правую часть экрана
                    this.x = canvas.width;
                }
            }

            draw() {
                ctx.fillStyle = `rgba(128, 222, 234, ${this.opacity})`; // Задаем цвет звезды с указанием прозрачности
                ctx.beginPath();
                ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
                ctx.fill();
            }
        }

        function initStars() {
            for (let i = 0; i < 100; i++) {
                const x = Math.random() * canvas.width;
                const y = Math.random() * canvas.height;
                const size = Math.random() * 3;
                const speed = Math.random() * 0.5 + 0.1; // Уменьшаем скорость движения
                // Направление движения выбирается случайно
                const direction = Math.random() < 0.5 ? 1 : -1;
                const opacity = Math.random(); // Генерируем случайную прозрачность от 0 до 1
                stars.push(new Star(x, y, size, speed, direction, opacity));
            }
        }

        function draw() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            stars.forEach((star) => {
                star.update();
                star.draw();
            });
            requestAnimationFrame(draw);
        }

        function init() {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            initStars();
            draw();
        }

        window.addEventListener("resize", init);
        init();
    },
};
</script>

<style scoped>
canvas {
    width: 100%;
    height: 100%;
    display: block;
}
</style>
