<template>
    <div class="px-4 h-full overflow-hidden">
        <div class="h-[calc(100vh-124px)] flex flex-col justify-center items-center bg-white text-black text-center p-4 rounded-lg relative">
            <h2 class="text-3xl font-bold mb-8">Игры</h2>

            <div class="w-full grid grid-cols-4 gap-4 mb-8">
                <div class="flex flex-col items-center text-xl mb-2" @click="play('plus')">
                    <div class="flex justify-center items-center w-full aspect-square bg-sky-500 text-5xl text-white rounded">
                        <GamePlus />
                    </div>
                    <div class="mt-2 text-sm">Plus</div>
                </div>

                <div class="flex flex-col items-center text-xl mb-2" @click="play('mins')">
                    <div class="flex justify-center items-center w-full aspect-square bg-sky-500 text-5xl text-white rounded"><GameMinus /></div>
                    <div class="mt-2 text-sm">Minus</div>
                </div>

                <div class="flex flex-col items-center text-xl mb-2" @click="play('mult')">
                    <div class="flex justify-center items-center w-full aspect-square bg-sky-500 text-5xl text-white rounded"><GameMulti /></div>
                    <div class="mt-2 text-sm">Multi</div>
                </div>

                <div class="flex flex-col items-center text-xl mb-2" @click="play('dvde')">
                    <div class="flex justify-center items-center w-full aspect-square bg-sky-500 text-5xl text-white rounded"><GameDivide /></div>
                    <div class="mt-2 text-sm">Divide</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";
import { useMathStore } from "@/store/math";

export default {
    name: "StartPage",

    data() {
        return {};
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "haptic"]),
        ...mapState(useUserStore, ["student"]),
        ...mapState(useMathStore, ["cleanAll", "clearResults", "pickGame"]),
    },

    methods: {
        play(game) {
            this.pickGame(game);
            this.setPopup("m:play");
            this.clearResults();
        },
    },

    mounted() {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        this.cleanAll();
    },
};
</script>
