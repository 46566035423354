<template>
    <svg
        class="fill-stroke"
        :class="'w-' + size + ' h-' + size"
        :width="size * 4"
        :height="size * 4"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <!-- <circle cx="12" cy="12" r="10"></circle>
        <polyline points="12 6 12 12 16 14"></polyline> -->
        <path
            d="M2 12C2 17.5228 6.47715 22 12 22C13.8214 22 15.5291 21.513 17 20.6622M12 2C17.5228 2 22 6.47715 22 12C22 13.8214 21.513 15.5291 20.6622 17"
        />
        <path d="M12 9V13H16" />
        <path
            d="M17 20.6622C15.5291 21.513 13.8214 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 13.8214 21.513 15.5291 20.6622 17"
            stroke-dasharray="0.5 3.5"
        />
    </svg>
</template>

<script>
export default {
    name: "IconTimer",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
