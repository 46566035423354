<template>
    <router-view></router-view>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";

export default {
    name: "App",

    computed: {
        ...mapState(useMainStore, ["s", "alert", "routes", "closePopup"]),
    },

    watch: {
        alert() {
            if (this.alert?.type == "success") {
                this.$toast.success(this.alert.msg);
            }
            if (this.alert?.type == "error") {
                this.$toast.error(this.alert.msg);
            }
            if (this.alert?.type == "warning") {
                this.$toast.warning(this.alert.msg);
            }
            if (this.alert?.type == "info") {
                this.$toast.info(this.alert.msg);
            }
        },
    },
};
</script>

<style>
html,
body {
    overflow: hidden;
    height: 100%;
    margin: 0;
}

/* .mclass { // что это??
    position: relative;
    min-height: 100%;
} */
.v--default-css .c-toast--success {
    @apply !bg-teal-500;
}
.v--default-css .c-toast--error {
    @apply !bg-rose-500;
}
.v--default-css .c-toast--info {
    @apply !bg-sky-500;
}
</style>
