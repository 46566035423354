import { defineStore } from "pinia";
import API from "../services/api";
import { useMainStore as mainStore } from "./main";

export const useUserStore = defineStore("user", {
    state: () => ({
        old: { point: 0, level: 0 },
        student: {},
        referrals: [],
        art: [],
        goal: [],
    }),

    actions: {
        // TELEGRAM MINI WEB APP

        setFid(data) {
            this.student.bot_id = data.bot_id;
            this.student.fid = data.fid;
        },

        setStudent(student) {
            if (this.old.point != this.student.points) this.old.point = this.student.point ?? 0;
            if (this.old.level != this.student.level) this.old.level = this.student.level ?? 0;

            this.student = student;

            if (student.point && student.point != this.old.point) mainStore().setModal("point");
            if (student.level && student.level != this.old.level) mainStore().setModal("level");
        },

        setModule(module) {
            this.student.module_id = module;
        },

        addPoint(points) {
            this.student.point += parseInt(points);
        },

        async sendApp(data) {
            // if (!data || !data.bot_id || !data.fid) return;

            // if (!data || !data.bot_id || !data.fid) {
            //     data.bot_id = 11;
            //     data.fid = 406209800;
            // }

            // if (!data || !data.bot_id || !data.fid) {
            //     data.bot_id = 11;
            //     data.fid = 469693350;
            // }

            if (!data || !data.bot_id || !data.fid) {
                data.bot_id = 11;
                data.fid = 574461920;
            }

            const res = await API.GET("app/main/me", { bot_id: data.bot_id, fid: data.fid });
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.student = res.data.student;
            this.student.staking = { sum: 5000, time: "2024-06-12 08:00:00", rate: 0.35 };
            this.referrals = res.data.referrals;
        },

        async getReferrals(fid) {
            this.referrals = [];
            const res = await API.GET("app/main/referrals", { fid: fid });
            this.referrals = res.data.referrals;
        },

        // СТАРОЕ

        // ART
        async getAuction(bot_id) {
            const res = await API.GET("app/art/auction", { bot_id: bot_id, fid: 406209800 });
            if (res.data.alert) this.setAlert(res.data.alert);
            console.log("getAuction", res.data.auction);
            this.art = res.data.auction;
        },

        picAuction() {},

        // GOAL
        async getGoal() {
            let bot_id = 7;
            let student_id = 262;
            const res = await API.GET("app/goal/my", { bot_id: bot_id, student_id: student_id });
            if (res.data.alert) this.setAlert(res.data.alert);
            this.goal = res.data.goal;
        },
    },
});
