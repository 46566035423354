<template>
    <!-- {{ s.answer }} -->
    <div id="chart" class="backdrop-blur-sm p-2"></div>
    <!-- rounded-xl border border-cyan-500/50 -->
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";

import { createChart } from "lightweight-charts";

export default {
    name: "CandleAnimatedGraf",

    data() {
        return {
            chart: null,
            candlestickSeries: null,
            h: 250,
            w: 300,
        };
    },

    props: {
        candles: { type: Array },
        answer: { type: Array },
    },

    watch: {
        candles(newValue) {
            if (newValue) {
                this.showGraf(); // пересоздаем график перед добавлением новых данных
                this.candleSeries();
            }
        },
        "s.answer": {
            handler(newValue) {
                if (newValue) this.addAnswerData();
            },
            deep: true,
        },
    },

    computed: {
        ...mapState(useMainStore, ["s", "setHidden"]),
    },

    methods: {
        showGraf() {
            this.destroyChart();
            this.setHidden(true);

            this.chart = createChart(document.getElementById("chart"), {
                timeScale: {
                    timeVisible: false,
                    visible: false,
                    // borderColor: "#222",
                },
                rightPriceScale: {
                    // borderColor: "#222",
                    visible: false,
                    borderVisible: false,
                },
                layout: {
                    background: { color: "transparent" },
                    textColor: "#777",
                },
                grid: {
                    horzLines: { color: "transparent" },
                    vertLines: { color: "transparent" },
                },
            });

            this.chart.applyOptions({ height: this.h, width: this.w });

            this.candlestickSeries = this.chart.addCandlestickSeries({
                upColor: "rgb(14, 116, 144)",
                downColor: "rgb(190, 18, 60)",
                wickUpColor: "rgb(14, 116, 144)",
                wickDownColor: "rgb(190, 18, 60)",
                borderVisible: false,
            });

            this.chart.timeScale().fitContent();
        },

        candleSeries() {
            if (!this.chart || !this.candlestickSeries) return;

            let index = 0;
            const addCandle = () => {
                if (index < this.candles.length) {
                    this.candlestickSeries.update(this.candles[index]);
                    this.chart.timeScale().fitContent();
                    index++;
                    setTimeout(addCandle, 50);
                } else {
                    this.setHidden(false); // Анимация завершена: прятать кнопки - нет
                }
            };
            addCandle();
        },

        destroyChart() {
            if (this.chart) {
                if (this.candlestickSeries) {
                    this.chart.removeSeries(this.candlestickSeries);
                    this.candlestickSeries = null;
                }
                this.chart.remove();
                this.chart = null;
            }
        },

        addAnswerData() {
            if (this.answer && this.answer.length > 0) {
                this.answer.forEach((data) => this.candlestickSeries.update(data));
                this.chart.timeScale().fitContent();
            }
        },
    },

    mounted() {
        this.w = this.$el.parentElement.clientWidth;

        const chartElement = document.getElementById("chart");
        if (chartElement) this.w = chartElement.clientWidth - 16;

        this.showGraf();
        this.candleSeries();
    },
};
</script>
