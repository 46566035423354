<template>
    <div class="p-4">
        <div class="flex flex-col items-center text-center mb-4">
            Staking rate
            <div class="text-xl">{{ staking }}%</div>
        </div>

        <div class="grid grid-cols-3 gap-4">
            <div class="flex flex-col items-center p-4 bg-cyan-600/30 border border-cyan-600/50 text-xl rounded backdrop-blur-sm">
                {{ student.point ?? 0 }}
                <span class="text-[9px]">BALANCE</span>
                <div class="flex items-center bg-cyan-600 text-xs rounded py-1 px-2 opacity-30">STAKE</div>
            </div>
            <div class="flex flex-col items-center p-4 bg-cyan-600/30 border border-cyan-600/50 text-xl rounded backdrop-blur-sm">
                {{ stakingNow.percent }}

                <span class="text-[9px]">PROFIT</span>
                <div class="flex items-center bg-cyan-600 text-xs rounded py-1 px-2 opacity-30">CLAIM&nbsp;{{ leftTime }}</div>
            </div>
            <div class="flex flex-col items-center p-4 bg-cyan-600/30 border border-cyan-600/50 text-xl rounded backdrop-blur-sm">
                {{ student.staking?.sum ?? 0 }}
                <span class="text-[9px]">STAKING</span>
                <div class="flex items-center bg-cyan-500 text-xs rounded py-1 px-2">UNSTAKE</div>
            </div>
        </div>

        <div class="flex flex-col items-center mt-16 opacity-50">
            <span>Your staking rate: {{ student.staking.rate }}%</span>
            <span>Until the end of staking: {{ leftTime }}</span>
            <span>You will lose profit if you withdraw your coins early</span>
        </div>
        <!-- <div class="flex justify-center items-center gap-2 mt-4 p-4 bg-cyan-600/30 border border-cyan-600/50 text-xl rounded backdrop-blur-sm">
            <input type="text" class="h-8 p-2 bg-cyan-600/10 rounded" />
            <div class="flex items-center h-8 bg-cyan-500 text-sm rounded px-2">STAKE</div>
        </div> -->
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";
import { usePredictorStore } from "@/store/predictor";

import moment from "moment";

export default {
    name: "StakingPage",

    data() {
        return {
            moment: moment,
            currentTime: new Date(),
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup"]),
        ...mapState(useUserStore, ["student", "referrals", "getReferrals"]),
        ...mapState(usePredictorStore, ["staking"]),

        stakingNow() {
            if (!this.student || !this.student.staking) return 0;

            // Используем реактивное свойство currentTime
            const currentTime = this.currentTime;
            const startTime = new Date(this.student.staking.time);
            const timeDifference = (currentTime - startTime) / 1000;
            const percent = (this.student.staking.rate / 86400) * timeDifference * this.student.staking.sum;

            return { percent: percent.toFixed(2), time: parseInt(86400 - timeDifference) };
        },

        leftTime() {
            const totalSeconds = this.stakingNow.time;
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;

            // Форматирование для двузначного отображения
            const H = hours.toString().padStart(2, "0");
            const M = minutes.toString().padStart(2, "0");
            const S = seconds.toString().padStart(2, "0");

            return `${H}:${M}:${S}`;
        },
    },

    mounted() {
        this.timer = setInterval(() => {
            // Обновляем реактивное свойство currentTime
            this.currentTime = new Date();
        }, 100);
    },

    beforeUnmount() {
        clearInterval(this.timer);
    },
};
</script>
