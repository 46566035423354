<template>
    <div class="w-full h-screen items-center pb-12 bg-sky-800 bg-gradient-to-tl from-sky-600/50 via-sky-300/50 to-sky-600/50 overflow-hidden">
        <div class="w-full text-sky-600 text-center relative z-10">
            <component :is="currentComponent" />
        </div>

        <div
            @click="haptic()"
            class="absolute bottom-4 left-4 right-4 z-30 h-12 grid grid-cols-5 bg-sky-200/20 text-white/50 backdrop-blur-sm overflow-hidden rounded-lg active:scale-95 transition-transform duration-500"
        >
            <div class="flex justify-center items-center icon" :class="{ '!text-sky-200': s.popup == 'f:start' }" @click="setPopup('f:start')">
                <IconPlay />
            </div>

            <div class="flex justify-center items-center icon" :class="{ '!text-sky-200': s.popup == 'f:catalog' }" @click="setPopup('f:catalog')">
                <IconText />
            </div>

            <div class="flex justify-center items-center icon" :class="{ '!text-sky-200': s.popup == 'f:stat' }" @click="setPopup('f:stat')">
                <IconGraf />
            </div>

            <div class="flex justify-center items-center icon" :class="{ '!text-sky-200': s.popup == 'f:settings' }" @click="setPopup('f:settings')">
                <IconSetting />
            </div>

            <div class="flex justify-center items-center icon" :class="{ '!text-sky-200': s.popup == 'f:profile' }" @click="setPopup('f:profile')">
                <IconProfile />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";
import { defineAsyncComponent } from "vue";

export default {
    name: "MainPage",

    data() {
        return {
            telegram: null,
            bot_id: 129,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "loadS", "setPopup", "closePopup", "setSettings", "setView", "setLang", "haptic"]),
        ...mapState(useUserStore, ["student", "sendApp"]),

        currentComponent() {
            const componentsMap = {
                "f:start": defineAsyncComponent(() => import("./Start.vue")),
                "f:catalog": defineAsyncComponent(() => import("./Catalog.vue")),
                "f:game": defineAsyncComponent(() => import("./Game.vue")),
                "f:stat": defineAsyncComponent(() => import("./Stat.vue")),
                "f:settings": defineAsyncComponent(() => import("./Settings.vue")),
                "f:profile": defineAsyncComponent(() => import("./Profile.vue")),
            };
            return componentsMap[this.s.popup] || componentsMap["f:start"];
        },
    },

    methods: {
        initTelegram() {
            this.telegram = window.Telegram.WebApp;
            if (this.telegram && this.telegram.initDataUnsafe) {
                const lang = this.telegram.initDataUnsafe.user?.language_code;
                this.setLang(lang);
                this.telegram.expand();
                this.telegram.disableVerticalSwipes();

                this.sendApp({ bot_id: this.bot_id, fid: this.telegram.initDataUnsafe.user?.id });
            }
        },

        animatePoint(start, end) {
            if (parseInt(start) < 1 || start == undefined) start = 0;
            const step = (end - start) / 10;
            let current = start;
            const interval = setInterval(() => {
                current += step;
                if ((step > 0 && current >= end) || (step < 0 && current <= end)) {
                    current = end;
                    clearInterval(interval);
                }
                this.point = Math.round(current);
            }, 100);
        },
    },

    mounted() {
        if (window && window?.Telegram && window.Telegram?.WebApp) {
            this.initTelegram();
        } else {
            // Load the Telegram WebApp script dynamically
            const scriptElement = document.createElement("script");
            scriptElement.src = "https://telegram.org/js/telegram-web-app.js";
            scriptElement.async = true;

            scriptElement.onload = () => {
                if (window.Telegram?.WebApp) this.initTelegram();
            };

            const firstChild = document.head.firstChild;
            document.head.insertBefore(scriptElement, firstChild);
        }

        this.loadS();
        if (!this.s.popup) this.setPopup("f:start");
    },
};
</script>

<style>
.icon path {
    stroke-dashoffset: 0;
    stroke-dasharray: 100;
}
.icon:active path {
    animation: draw 1s linear forwards;
}

@keyframes draw {
    0% {
        stroke-dashoffset: 100;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
</style>
