<template>
    <div class="coin" :style="{ '--diameter': size + 'px' }">
        <div class="side head">{{ text }}</div>
        <div class="side tail">{{ text }}</div>
        <div class="edge"></div>
    </div>
</template>

<script>
export default {
    name: "CoinLogo",
    props: {
        size: {
            type: Number,
            default: 50,
        },
        text: {
            type: String,
            default: "G",
        },
    },
};
</script>

<style scoped>
.coin {
    --diameter: 50px;
    --thickness: 5px;
    --scale: 0.75;
    --period: 5s;
    --gradient: linear-gradient(rgb(34 211 238) 0%, rgb(0, 242, 255) 50%, rgb(34 211 238) 100%);

    transform-style: preserve-3d;
    width: var(--diameter);
    height: var(--diameter);
    animation: spin var(--period) linear infinite;
}

.side {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgb(34 211 238);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: calc(var(--diameter) * 0.6);
    font-weight: bold;
}

.head {
    transform: translateZ(calc(var(--thickness) / 2));
    animation: flip-head var(--period) linear infinite;
}

.tail {
    transform: translateZ(calc(0px - var(--thickness) / 2)) rotateY(180deg);
    animation: flip-tail var(--period) linear infinite;
}

.edge {
    position: absolute;
    width: var(--thickness);
    height: 100%;
    transform: translateX(calc(var(--diameter) / 2)) translateZ(calc(var(--thickness) / 2)) rotateY(90deg);
    transform-origin: 0 0;
    background-color: rgb(34 211 238);
    background-image: var(--gradient);
}

@keyframes spin {
    0% {
        transform: rotateY(0deg) scale(var(--scale));
    }
    100% {
        transform: rotateY(360deg) scale(var(--scale));
    }
}

@keyframes flip-tail {
    0% {
        background-image: var(--gradient);
        color: transparent;
    }
    50% {
        background-image: none;
        color: white;
    }
    100% {
        background-image: var(--gradient);
        color: transparent;
    }
}

@keyframes flip-head {
    0% {
        background-image: none;
        color: white;
    }
    50% {
        background-image: var(--gradient);
        color: transparent;
    }
    100% {
        background-image: none;
        color: white;
    }
}
</style>
