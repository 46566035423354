<template>
    <svg
        class="fill-stroke"
        :class="'w-' + size + ' h-' + size"
        :width="size * 4"
        :height="size * 4"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M12 9V13L14.5 15.5" />
        <path d="M3.5 4.5L7.50002 2" />
        <path d="M20.5 4.5L16.5 2" />
        <path
            d="M7.5 5.20404C8.82378 4.43827 10.3607 4 12 4C16.9706 4 21 8.02944 21 13C21 17.9706 16.9706 22 12 22C7.02944 22 3 17.9706 3 13C3 11.3607 3.43827 9.82378 4.20404 8.5"
        />
    </svg>
</template>

<script>
export default {
    name: "IconAlarm",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
