<template>
    <div class="flex min-h-full bg-gradient-to-tr from-cyan-200 via-purple-100/0 to-sky-200 max-xl:flex-col">
        <div class="w-96 my-4 rounded max-l:mx-auto">
            <div class="p-5 v-bbgray">
                <h2 class="uppercase text-center text-xl font-latobold">Профиль</h2>
            </div>

            <div class="p-5 v-bbgray">
                <div class="flex text-base text-zinc-800 font-latobold flex-col items-center">
                    <div class="w-48 h-48 my-5 mx-auto rounded-full bg-sky-500 overflow-hidden relative">
                        <span class="w-full h-full group">
                            <img
                                v-if="user?.picture"
                                class="w-full h-full object-cover"
                                :src="'https://yii.botteach.ru/users/orig/' + user?.picture"
                            />
                            <img v-else class="w-full h-full object-cover" src="https://yii.botteach.ru/users/mini/u0.png" />
                        </span>
                    </div>

                    {{ user?.name }} {{ user?.secondname }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/store/user";

export default {
    name: "PageProfile",

    computed: {
        ...mapState(useUserStore, ["user", "uploadImg", "editProfile"]),
    },
};
</script>
