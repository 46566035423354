<template>
    <div class="p-4 h-screen pb-20 overflow-auto">
        <div class="flex justify-between items-center mb-4">
            <div>
                <div @click="haptic(), toggleLang()" class="flex justify-center items-center w-12 py-2 px-4 text-sm text-orange-200">
                    {{ s.lang }}
                </div>

                <div v-if="langDropdown" class="absolute z-10 mt-2 w-12 text-orange-200 bg-[#2c1d15]/90 rounded">
                    <ul>
                        <li v-for="lang in langs" :key="lang" @click="changeLang(lang)" class="px-4 py-2 cursor-pointer">{{ lang }}</li>
                    </ul>
                </div>
            </div>

            <div class="flex justify-between items-center gap-6">
                <div @click="haptic(), (sort = !sort)" class="text-orange-200/50" :class="{ '!text-orange-200': sort }"><IconSort /></div>
                <div @click="haptic(), (view = 'add')" class="text-orange-200"><IconPlus /></div>
            </div>
        </div>

        <template v-if="sort">
            <draggable :list="total" @end="setSort" itemKey="id" @start="dragStart" @update="dragUpdate">
                <template #item="{ element, index }">
                    <div>
                        <div
                            class="flex justify-between items-center backdrop-blur-sm mb-px p-2 px-4 bg-orange-200/20 text-sm text-white/80 rounded wiggle"
                            :style="{ '--i': index }"
                        >
                            <div class="flex justify-start items-center gap-2">
                                <IconSortMini />
                                {{ element.name }}
                            </div>

                            <div class="text-orange-200" @click="delCat(element.id)"><IconDelete :size="4" /></div>
                        </div>
                    </div>
                </template>
            </draggable>
        </template>

        <template v-else>
            <div v-for="item in total" :key="item">
                <div class="grid grid-cols-2 backdrop-blur-sm mb-px p-2 px-4 bg-orange-200/20 text-sm text-white/80 rounded">
                    {{ item.name }}
                    <div class="text-right">{{ formatTime(item.total) }}</div>
                </div>
            </div>
        </template>

        <div class="mt-8">
            <div class="mb-2 px-4 text-orange-200/80 text-xl">{{ $t("history") }}</div>
            <div v-for="item in allTimes" :key="item" class="grid grid-cols-3 text-xs text-white/80 rounded p-1 px-4">
                <div>{{ item.name }}</div>
                <div class="text-right">{{ moment(item.start).format("DD.MM") }} {{ moment(item.start).format("HH:mm") }}</div>
                <div class="flex justify-end items-center gap-4 text-right">
                    {{ formatTime(item.t) }}

                    <div @click="haptic(), setTime(item)" class="text-orange-200/50"><IconEditMini :size="3" /></div>
                </div>
            </div>
        </div>
    </div>

    <template v-if="view == 'add'">
        <div class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-50 w-2/3 flex flex-col justify-center items-center p-2 rounded">
            <div class="mb-4 text-lg">{{ $t("categoryname") }}</div>

            <input class="w-full p-2 bg-white/20 rounded mb-4" type="text" v-model="name" />
            <div
                @click="addNew(name)"
                class="flex flex-col justify-center items-center w-fit py-2 px-6 text-sm bg-orange-200/20 rounded backdrop-blur-sm"
            >
                {{ $t("add") }}
            </div>
        </div>
        <div @click="haptic(), (view = '')" class="fixed top-0 left-0 w-full h-full z-40 bg-black/70 backdrop-blur-sm"></div>
    </template>

    <template v-if="view == 'editTime' && time">
        <div class="absolute top-1/2 -translate-y-1/2 left-0 z-50 w-full p-8"><SliderComponent /></div>
        <div @click="haptic(), (view = '')" class="fixed top-0 left-0 w-full h-full z-40 bg-black/70 backdrop-blur-sm"></div>
    </template>

    <!-- <div class="absolute bottom-0 left-1 text-[8px] text-orange-200/20" @click="cleanAll()">DEL</div> -->
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useTimeStore } from "@/store/time";
import moment from "moment";

import draggable from "vuedraggable";

import SliderComponent from "./SliderComponent.vue";

export default {
    name: "CategoryPage",

    components: { draggable, SliderComponent },

    data() {
        return {
            moment: moment,
            view: "",
            name: "",
            currentTime: null,
            sort: false,
            sortingStop: false,
            langDropdown: false,
            langs: ["en", "ru"],
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "setLang", "haptic"]),
        ...mapState(useTimeStore, ["cats", "times", "time", "addCat", "sortCats", "delCat", "pickTime"]),

        total() {
            const totals = this.cats.map((cat) => ({ ...cat, total: 0 }));

            // Перебор временных записей и суммирование времени для соответствующих категорий
            this.times.forEach((time) => {
                const category = totals.find((cat) => cat.id == time.id);
                if (category) category.total += parseInt(time.t); // Добавляем время к найденной категории
            });

            // время у текущей катеории, если сейчас идет таймер
            let lastTime = this.times[this.times.length - 1];
            if (lastTime?.id && lastTime.t == 0) {
                let category = totals.find((cat) => cat.id == lastTime.id);
                category.total += (Date.now() - lastTime.s) / 1000;
            }

            return totals;
        },

        allTimes() {
            const data = this.times.map((time, index) => {
                const category = this.cats.find((cat) => cat.id === time.id);
                const start = time.s;
                let end;

                if (index < this.times.length - 1) {
                    end = this.times[index + 1].s;
                } else {
                    end = Date.now(); // или другое значение для последнего элемента
                }

                const duration = end - start;

                return { ...time, name: category ? category.name : "Unknown", start: start, end: end, duration: duration };
            });

            // Возвращаем массив в обратном порядке
            return data.reverse();
        },
    },

    methods: {
        formatTime(sec) {
            const s = Math.floor(sec);
            const m = Math.floor(s / 60);
            const h = Math.floor(m / 60);
            const S = parseInt(s % 60);
            const M = parseInt(m % 60);
            const H = parseInt(h % 24);

            if (H > 0) return `${H}h ${M}m ${S}s`;
            if (M > 0) return `${M}m ${S}s`;
            if (S > 0) return `${S}s`;
            return `-`;
        },

        toggleLang() {
            this.langDropdown = !this.langDropdown;
        },

        changeLang(lang) {
            this.setLang(lang);
            this.langDropdown = false;
        },

        addNew(name) {
            this.haptic();
            this.add = false;
            this.addCat(name);
            this.name = "";
        },

        setSort() {
            if (this.sortingStop) return;
            this.sortingStop = true;

            this.sortCats(this.total);

            setTimeout(() => (this.sortingStop = false), 500);
        },

        setTime(time) {
            this.pickTime(time.s);
            this.view = "editTime";
        },

        dragStart(event) {
            if (event.pointerType === "touch") this.preventMultiTouch(event);
        },

        dragUpdate(event) {
            if (event.pointerType === "touch") this.preventMultiTouch(event);
        },

        preventMultiTouch(event) {
            if (event.touches && event.touches.length > 1) event.preventDefault();
        },
    },
};
</script>

<style scoped>
@keyframes wiggle {
    0%,
    100% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(0.3deg);
    }
    75% {
        transform: rotate(-0.3deg);
    }
}

.wiggle {
    animation: wiggle 0.5s ease-in-out infinite alternate;
    animation-delay: calc(var(--i) * 0.1s);
}
</style>
