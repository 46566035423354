<template>
    <svg
        class="fill-stroke"
        :class="'w-' + size + ' h-' + size"
        :width="size * 4"
        :height="size * 4"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M14,17V7l3,3.4" />
        <path d="M10,7v10l-3-3.4" />
        <path
            d="M22,12c0,4.7,0,7.1-1.5,8.5C19.1,22,16.7,22,12,22c-4.7,0-7.1,0-8.5-1.5C2,19.1,2,16.7,2,12
		c0-4.7,0-7.1,1.5-8.5C4.9,2,7.3,2,12,2c4.7,0,7.1,0,8.5,1.5c1,1,1.3,2.3,1.4,4.5"
        />
    </svg>
</template>

<script>
export default {
    name: "IconSort",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
