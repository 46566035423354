import { defineStore } from "pinia";
import API from "../services/api";
import { zip, unzip } from "./utils/zip";
import { key } from "./utils/random";

import { useMainStore as mainStore } from "./main";
import { useUserStore as userStore } from "./user";

export const useTimeStore = defineStore("time", {
    state: () => ({
        // список категорий
        cats: [
            { id: 1, name: "Работа", o: 1 },
            { id: 2, name: "Отдых", o: 2 },
        ],
        times: [],
        time: {},
        reset: 0, // Триггерим изменение состояния
        log: [],
    }),

    actions: {
        async loadData() {
            // this.log.push({ a: "loadData" });
            // console.log("loadData");

            const storedCats = localStorage.getItem("t:cats");
            if (storedCats) this.cats = unzip(storedCats);

            const storedTimes = localStorage.getItem("t:times");
            if (storedTimes) this.times = unzip(storedTimes);

            this.times = this.times.map((time) => {
                if (!time.i || time.i === undefined) time.i = key(3, this.times);
                return time;
            });
            localStorage.setItem("t:times", zip(this.times));

            // if (!storedCats || !storedTimes)
            await this.getData();
        },

        addCat(name) {
            // Проверка на существование категории с таким же именем
            const existingCat = this.cats.find((cat) => cat.name === name);
            if (existingCat) return;

            const id = this.cats.length ? Math.max(...this.cats.map((cat) => cat.id)) + 1 : 1;

            const newCategory = { id, name, o: id };
            this.cats.push(newCategory);
            localStorage.setItem("t:cats", zip(this.cats));
            this.send();
        },

        sortCats(data) {
            this.cats = Array.from(new Map(data.map((cat, index) => [cat.id, { id: cat.id, name: cat.name, o: index + 1 }])).values());

            // this.cats = unique.map((cat, index) => ({ id: cat.id, name: cat.name, o: index + 1 }));
            localStorage.setItem("t:cats", zip(this.cats));
            this.send();
        },

        delCat(id) {
            this.cats = this.cats.filter((cat) => cat.id != id);
            localStorage.setItem("t:cats", zip(this.cats));

            this.times = this.times.filter((time) => time.id != id);
            localStorage.setItem("t:times", zip(this.times));

            this.send();
        },

        cleanAll() {
            localStorage.removeItem("t:cats");
            localStorage.removeItem("t:times");
        },

        // Добавляем новую запись в массив times
        addTime(data) {
            return new Promise((resolve) => {
                data.i = key(3, this.times);
                this.times.push({ i: data.i, s: data.s, id: data.cat_id, t: 0 });
                this.times.sort((a, b) => a.s - b.s);

                this.reTime(data);
                this.pickTime(data.s);
                resolve();
            });
        },

        delTime(i) {
            this.times = this.times.filter((time) => time.i != i);
            localStorage.setItem("t:times", zip(this.times));

            this.times.forEach((time, index) => {
                if (index < this.times.length - 1) {
                    const nextTime = this.times[index + 1];
                    time.t = Math.floor((nextTime.s - time.s) / 1000); // В секундах
                }
            });

            this.time = null;

            this.send();
        },

        startTimer(cat_id) {
            // this.log.push({ a: "startTimer", cat_id: cat_id });

            const now = Date.now();

            if (this.times.length > 0) {
                // Получаем последний таймер
                const lastTime = this.times[this.times.length - 1];

                // Если последняя активная категория та же, что и текущая, прекращаем выполнение
                if (lastTime.id === cat_id) return;

                // Вычисляем разницу во времени с последней записи
                lastTime.t = Math.floor((now - lastTime.s) / 1000); // В секундах

                // Обновляем последнюю запись в массиве
                this.times[this.times.length - 1] = lastTime;
            }

            // Добавляем новую запись в массив
            this.times.push({ i: key(3, this.times), s: now, id: cat_id, t: 0 });

            this.reset = now; // Триггерим изменение состояния

            // Сохраняем обновленные данные в LocalStorage и на сервер
            localStorage.setItem("t:times", zip(this.times));
            this.send();
        },

        pickTime(s) {
            this.time = this.times.find((time) => time.s == s);
        },

        reTime(data) {
            let one = this.times.find((time) => time.i == data.i);
            if (one) one.s = data.s;

            const index = this.times.findIndex((time) => time.i == data.i);
            let prev = null;
            let next = null;

            if (index > 0) {
                prev = this.times[index - 1];
                prev.t = Math.floor((one.s - prev.s) / 1000);
            }

            if (index < this.times.length - 1) {
                next = this.times[index + 1];
                one.t = Math.floor((next.s - one.s) / 1000);
            }

            // Сохраняем обновленные данные в LocalStorage и на сервер
            localStorage.setItem("t:times", zip(this.times));
            this.send();
        },

        async getData() {
            // this.log.push({ a: "getData" });
            // console.log("getData");

            await this.waitForStudentId();
            const student = userStore().student;
            if (!student || !student.student_id) return;
            // console.log("student_id", student.student_id);

            const res = await API.GET("app/time/get", { id: student.student_id });

            if (res.data?.categories) {
                localStorage.setItem("t:cats", res.data?.categories);
                this.cats = unzip(res.data.categories);
            }
            if (res.data?.times) {
                localStorage.setItem("t:times", res.data?.times);
                this.times = unzip(res.data.times);

                this.times = this.times.map((time) => {
                    if (!time.i || time.i === undefined) time.i = key(3, this.times);
                    return time;
                });
                localStorage.setItem("t:times", zip(this.times));
            }
        },

        async send() {
            // this.log.push({ a: "send" });

            await this.waitForStudentId();
            const student = userStore().student;
            if (!student || !student.student_id) return;

            const formData = new FormData();
            formData.append("student_id", student.student_id);
            formData.append("cats", zip(this.cats));
            formData.append("times", zip(this.times));

            // console.log("cats", zip(this.cats));
            // console.log("times", zip(this.times));

            await API.POST("app/time/save", formData);
            // this.log.push({ a: "send", res: res.data });

            // if (res.data.alert) mainStore().setAlert(res.data.alert);
        },

        async remind(time) {
            await this.waitForStudentId();
            const student = userStore().student;
            if (!student || !student.student_id) return;

            mainStore().setAlarm(time);

            const formData = new FormData();
            formData.append("student_id", student.student_id);
            formData.append("time", time);

            const res = await API.POST("app/time/remind", formData);
            // this.log.push({ a: "send", res: res.data });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
        },

        async waitForStudentId() {
            while (!userStore().student || !userStore().student.student_id) {
                await new Promise((resolve) => setTimeout(resolve, 100)); // ждем 100 мс перед следующей проверкой
            }
        },
    },
});
