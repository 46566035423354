<template>
    <div ref="scene" class="canvas-container"></div>
</template>

<script>
import { mapState } from "pinia";
import { useTimeStore } from "@/store/time";
import { onMounted, ref, watch } from "vue";
import { Engine, Render, Runner, Bodies, Body, World, Mouse, MouseConstraint } from "matter-js";

export default {
    name: "HourGlass",

    computed: {
        ...mapState(useTimeStore, ["timers"]),
    },

    setup() {
        const scene = ref(null);
        const timeStore = useTimeStore();
        let engine, render, runner, world;
        let particles = [];
        let sandInterval;

        const initScene = () => {
            engine = Engine.create();
            engine.positionIterations = 1;
            engine.velocityIterations = 1;
            engine.world.gravity.y = 0.05;
            world = engine.world;

            render = Render.create({
                element: scene.value,
                engine: engine,
                options: {
                    width: window.innerWidth,
                    height: window.innerHeight,
                    wireframes: false,
                    background: "transparent",
                },
            });

            Render.run(render);

            runner = Runner.create({
                deltaSampleSize: 1,
                maxDelta: 35,
            });
            Runner.run(runner, engine);

            const floor = Bodies.rectangle(window.innerWidth / 2, window.innerHeight + 25, window.innerWidth, 50, { isStatic: true });
            const leftWall = Bodies.rectangle(-25, window.innerHeight / 2, 50, window.innerHeight, { isStatic: true });
            const rightWall = Bodies.rectangle(window.innerWidth + 25, window.innerHeight / 2, 50, window.innerHeight, { isStatic: true });

            const centerRect = Bodies.rectangle(window.innerWidth / 2, window.innerHeight - 40, window.innerWidth * 0.85, 48, {
                isStatic: true,
                render: { fillStyle: "rgba(0, 0, 0, 0)" },
            });

            const alarm1 = Bodies.circle(window.innerWidth / 2 - 33, window.innerHeight - 132, 18, {
                isStatic: true,
                render: { fillStyle: "rgba(0, 0, 0, 0)" },
            });

            const alarm2 = Bodies.circle(window.innerWidth / 2 + 33, window.innerHeight - 132, 18, {
                isStatic: true,
                render: { fillStyle: "rgba(0, 0, 0, 0)" },
            });

            World.add(world, [floor, leftWall, rightWall, centerRect, alarm1, alarm2]);

            // Мышь
            const mouse = Mouse.create(render.canvas);

            const mouseConstraint = MouseConstraint.create(engine, {
                mouse: mouse,
                collisionFilter: { category: 0x0001, mask: 0xffffffff },
                constraint: {
                    stiffness: 0.8,
                    render: { visible: false },
                },
            });
            World.add(world, mouseConstraint);
            render.mouse = mouse;

            // Events.on(mouseConstraint, "mousemove", function (event) {
            //     const mousePosition = event.mouse.position;
            //     particles.forEach((particle) => {
            //         const distance = Vector.magnitude(Vector.sub(mousePosition, particle.position));
            //         if (distance < 15) {
            //             // Увеличиваем радиус захвата до 50 пикселей
            //             const forceMagnitude = 0.015 * particle.mass;
            //             const force = Vector.normalise(Vector.sub(mousePosition, particle.position));
            //             Body.applyForce(particle, particle.position, Vector.mult(force, -forceMagnitude));
            //         }
            //     });
            //     console.log("Mouse move event:", mousePosition);
            // });

            const colors = ["#dec99b", "#dfc284", "#b7a783", "#d3b27a", "#cdb686"];

            particles = [];

            const addSandParticle = () => {
                if (particles.length >= 10000) {
                    const oldParticle = particles.shift();
                    World.remove(world, oldParticle);
                }

                const x = window.innerWidth / 2 + (Math.random() - 0.5) * 30;
                const y = 100;
                const size = 2 + Math.random() * 3;
                const color = colors[Math.floor(Math.random() * colors.length)];
                const mass = 0.0001 + Math.random() * 0.0004;

                const particleOptions = {
                    density: 0.001,
                    friction: 0.2,
                    frictionStatic: 1,
                    frictionAir: 0.01,
                    chamfer: 0,
                    restitution: 0,
                    render: { fillStyle: color },
                    mass: mass,
                };

                const particle = Bodies.circle(x, y, size / 2, particleOptions);
                particles.push(particle);
                World.add(world, particle);

                // Установка таймера на 60 секунд для перевода частицы в статическое состояние если она ниже середины
                setTimeout(() => {
                    if (particle.position.y > window.innerHeight / 2) Body.setStatic(particle, true);
                }, 60000);
            };

            const animate = () => {
                Engine.update(engine, 1000 / 30);
                requestAnimationFrame(animate);
            };

            sandInterval = setInterval(() => addSandParticle(), 1000);

            animate();
        };

        const clearScene = () => {
            if (world) {
                World.clear(world, true); // Измените false на true для удаления всех ограничений
                Engine.clear(engine);
                particles.forEach((particle) => World.remove(world, particle));
                particles = [];
            }
            if (render) {
                Render.stop(render);
                render.canvas.remove(); // Удалите канвас с DOM
                render.canvas = null;
                render.context = null;
                render.textures = {}; // Очистите кэшированные текстуры
            }
            if (runner) Runner.stop(runner);
            if (sandInterval) clearInterval(sandInterval);
        };

        const resetScene = () => {
            clearScene();
            initScene();
        };

        onMounted(() => {
            initScene();
        });

        // Наблюдаем за изменениями в состоянии хранилища
        watch(
            () => timeStore.reset,
            (newVal) => {
                if (newVal) resetScene();
            }
        );

        return { scene };
    },
};
</script>
