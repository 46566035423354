<template>
    <svg
        class="fill-stroke"
        :class="'w-' + size + ' h-' + size"
        :width="size * 4"
        :height="size * 4"
        viewBox="0 0 24 24"
        stroke-width="1"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <g>
            <line class="st0" x1="4.5" y1="1.5" x2="4.5" y2="15.3" />
            <rect x="1.7" y="2.4" class="st1" width="5.6" height="14.7" />
        </g>
        <g>
            <line class="st2" x1="12.1" y1="17.1" x2="12.1" y2="23.5" />
            <rect x="9.3" y="14.1" class="st3" width="5.6" height="3" />
        </g>
        <g>
            <line class="st2" x1="19.8" y1="2.9" x2="19.8" y2="15" />
            <rect x="17" y="2.9" class="st3" width="5.6" height="11" />
        </g>
    </svg>
</template>

<script>
export default {
    name: "CandleHummer",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>

<style type="text/css">
.st0 {
    fill: none;
    stroke: #be123c;
}
.st1 {
    fill: #be123c;
}
.st2 {
    fill: none;
    stroke: #0e7490;
}
.st3 {
    fill: #0e7490;
}
</style>
