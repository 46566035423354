<template>
    <div class="p-4">
        <!-- <div class="my-4">{{ s.start }} - {{ s.end }}</div> -->
        <!-- <div class="my-4">{{ total }}</div> -->

        <div class="flex justify-between items-center h-10 mb-4 gap-2">
            <div
                class="flex justify-center items-center px-2 shrink-0 cursor-pointer text-[7px] text-orange-200 bg-orange-200/10 rounded w-fit h-8 uppercase"
                :class="{ '!bg-orange-200/20 !text-white': s.start == '2023-01-01' && s.end == moment().format('YYYY-MM-DD') }"
                @click="setPeriod({ start: '2024-01-01', end: moment().format('YYYY-MM-DD') })"
            >
                {{ $t("all") }}
            </div>
            <div
                class="flex justify-center items-center px-2 shrink-0 cursor-pointer text-[7px] text-orange-200 bg-orange-200/10 rounded w-fit h-8 uppercase"
                :class="{ '!bg-orange-200/20 !text-white': s.start == moment().format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD') }"
                @click="setPeriod({ start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
            >
                {{ $t("today") }}
            </div>
            <div
                class="flex justify-center items-center px-2 shrink-0 cursor-pointer text-[7px] text-orange-200 bg-orange-200/10 rounded w-fit h-8 uppercase"
                :class="{
                    '!bg-orange-200/20 !text-white':
                        s.start == moment().subtract(1, 'days').format('YYYY-MM-DD') && s.end == moment().subtract(1, 'days').format('YYYY-MM-DD'),
                }"
                @click="
                    setPeriod({ start: moment().subtract(1, 'days').format('YYYY-MM-DD'), end: moment().subtract(1, 'days').format('YYYY-MM-DD') })
                "
            >
                {{ $t("yesterday") }}
            </div>
            <div
                class="flex justify-center items-center px-2 shrink-0 cursor-pointer text-[7px] text-orange-200 bg-orange-200/10 rounded w-fit h-8 uppercase"
                :class="{
                    '!bg-orange-200/20 !text-white':
                        s.start == moment().subtract(6, 'days').format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD'),
                }"
                @click="setPeriod({ start: moment().subtract(6, 'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
            >
                {{ $t("week") }}
            </div>
            <div
                class="flex justify-center items-center px-2 shrink-0 cursor-pointer text-[7px] text-orange-200 bg-orange-200/10 rounded w-fit h-8 uppercase"
                :class="{
                    '!bg-orange-200/20 !text-white': s.start == moment().format('YYYY-MM-01') && s.end == moment().format('YYYY-MM-DD'),
                }"
                @click="setPeriod({ start: moment().format('YYYY-MM-01'), end: moment().format('YYYY-MM-DD') })"
            >
                {{ $t("month") }}
            </div>

            <input
                type="date"
                class="flex justify-center items-center px-2 w-fit h-8 text-[7px] text-orange-200 bg-orange-200/10 rounded"
                @change="setSettings(s)"
                v-model="s.start"
            />

            <input
                type="date"
                class="flex justify-center items-center px-2 w-fit h-8 text-[7px] text-orange-200 bg-orange-200/10 rounded"
                @change="setSettings(s)"
                v-model="s.end"
            />
        </div>

        <DonutChart :data="total" />
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useTimeStore } from "@/store/time";
import moment from "moment";

import DonutChart from "../components/D3/Donut.vue";

export default {
    name: "StatPage",

    components: { DonutChart },

    data() {
        return {
            moment: moment,
            size: window.innerWidth * 0.8,
            now: Date.now(),
            intervalId: null,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "setPeriod", "haptic"]),
        ...mapState(useTimeStore, ["cats", "times"]),

        total() {
            if (!this.cats || !this.times) return [];

            const totals = {};
            this.cats.forEach((cat) => (totals[cat.id] = { id: cat.id, name: cat.name, total: 0 }));

            let ts = this.times;

            if (this.s.start && this.s.end) {
                const startTime = new Date(this.s.start + "T00:00:00").getTime();
                const endTime = new Date(this.s.end + "T23:59:59").getTime();
                ts = this.times.filter((time) => time.s >= startTime && time.s <= endTime);
            }

            // Перебор временных записей и суммирование времени для соответствующих категорий
            ts.forEach((time) => {
                if (!totals[time.id]) totals[time.id] = { id: time.id, name: "none", total: 0 };
                totals[time.id].total += parseInt(time.t);
            });

            // Время у текущей категории, если сейчас идет таймер
            let lastTime = ts[ts.length - 1];
            if (lastTime?.id && lastTime.t == 0) {
                if (totals[lastTime.id]) totals[lastTime.id].total += parseInt((this.now - lastTime.s) / 1000);
            }

            // Преобразование объекта в массив
            // фильтрация категорий с нулевыми total
            // Сортировка массива по убыванию total
            return Object.values(totals)
                .filter((cat) => cat.total > 0)
                .sort((a, b) => b.total - a.total);
        },
    },

    methods: {
        drawPieChart() {
            const pieCanvas = this.$refs.pieCanvas;

            if (!pieCanvas) return;

            const ctx = pieCanvas.getContext("2d");
            const totalValue = this.total.reduce((sum, item) => sum + item.total, 0);
            let startAngle = 0;

            this.total.forEach((item) => {
                const sliceAngle = (item.total / totalValue) * 2 * Math.PI;
                ctx.beginPath();
                ctx.moveTo(this.size / 2, this.size / 2);
                ctx.arc(this.size / 2, this.size / 2, this.size / 2, startAngle, startAngle + sliceAngle);
                ctx.lineTo(this.size / 2, this.size / 2);
                ctx.fillStyle = item.color;
                ctx.fill();

                // Draw the text
                const middleAngle = startAngle + sliceAngle / 2;
                const textX = this.size / 2 + (this.size / 2.5) * Math.cos(middleAngle);
                const textY = this.size / 2 + (this.size / 2.5) * Math.sin(middleAngle);
                ctx.fillStyle = "#000000";
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.font = "12px Arial";
                ctx.fillText(item.name, textX, textY);

                startAngle += sliceAngle;
            });
        },

        // updateNow() {
        //     this.now = Date.now();
        // },
    },

    mounted() {
        this.drawPieChart();
        // this.intervalId = setInterval(this.updateNow, 1000); // Обновляем `now` каждую секунду
    },

    // beforeUnmount() {
    //     clearInterval(this.intervalId); // Очищаем интервал при уничтожении компонента
    // },
};
</script>

<style scoped>
canvas {
    display: block;
    margin: auto;
}
</style>
