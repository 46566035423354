<template>
    <svg
        class="fill-stroke"
        :class="'w-' + size + ' h-' + size"
        :width="size * 4"
        :height="size * 4"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M14,17V7l3,3.4" />
        <path d="M10,7v10l-3-3.4" />
    </svg>
</template>

<script>
export default {
    name: "IconSortMini",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
