import { defineStore } from "pinia";
// import API from "../services/api";
import { zip, unzip } from "./utils/zip";
// import { key } from "./utils/random";

// import { useMainStore as mainStore } from "./main";
import { useUserStore as userStore } from "./user";

export const useMathStore = defineStore("math", {
    state: () => ({
        all: {
            game: "mult", // выбор игры
            modules: [4], // модули
            random: true, // разрешить случайный порядок
            repeat: false, // разрешить повторов вопросов
            timeLesson: 60, // время всего занятия
            timeOne: 10, // время на один пример
            points: 0,
            results: [],
        },

        quests: [
            {
                plus: [
                    { module: 1, point: 1, qest: "1 + 0", answ: 1 },
                    { module: 1, point: 1, qest: "1 + 1", answ: 2 },
                    { module: 1, point: 1, qest: "1 + 2", answ: 3 },
                    { module: 1, point: 1, qest: "1 + 3", answ: 4 },
                    { module: 1, point: 1, qest: "1 + 4", answ: 5 },
                    { module: 1, point: 1, qest: "1 + 5", answ: 6 },
                    { module: 1, point: 1, qest: "1 + 6", answ: 7 },
                    { module: 1, point: 1, qest: "1 + 7", answ: 8 },
                    { module: 1, point: 1, qest: "1 + 8", answ: 9 },
                    { module: 1, point: 1, qest: "1 + 9", answ: 10 },
                    { module: 2, point: 1, qest: "2 + 0", answ: 2 },
                    { module: 2, point: 1, qest: "2 + 1", answ: 3 },
                    { module: 2, point: 1, qest: "2 + 2", answ: 4 },
                    { module: 2, point: 1, qest: "2 + 3", answ: 5 },
                    { module: 2, point: 1, qest: "2 + 4", answ: 6 },
                    { module: 2, point: 1, qest: "2 + 5", answ: 7 },
                    { module: 2, point: 1, qest: "2 + 6", answ: 8 },
                    { module: 2, point: 1, qest: "2 + 7", answ: 9 },
                    { module: 2, point: 1, qest: "2 + 8", answ: 10 },
                    { module: 3, point: 1, qest: "3 + 0", answ: 3 },
                    { module: 3, point: 1, qest: "3 + 1", answ: 4 },
                    { module: 3, point: 1, qest: "3 + 2", answ: 5 },
                    { module: 3, point: 1, qest: "3 + 3", answ: 6 },
                    { module: 3, point: 1, qest: "3 + 4", answ: 7 },
                    { module: 3, point: 1, qest: "3 + 5", answ: 8 },
                    { module: 3, point: 1, qest: "3 + 6", answ: 9 },
                    { module: 3, point: 1, qest: "3 + 7", answ: 10 },
                    { module: 4, point: 1, qest: "4 + 0", answ: 4 },
                    { module: 4, point: 1, qest: "4 + 1", answ: 5 },
                    { module: 4, point: 1, qest: "4 + 2", answ: 6 },
                    { module: 4, point: 1, qest: "4 + 3", answ: 7 },
                    { module: 4, point: 1, qest: "4 + 4", answ: 8 },
                    { module: 4, point: 1, qest: "4 + 5", answ: 9 },
                    { module: 4, point: 1, qest: "4 + 6", answ: 10 },
                    { module: 5, point: 1, qest: "5 + 0", answ: 5 },
                    { module: 5, point: 1, qest: "5 + 1", answ: 6 },
                    { module: 5, point: 1, qest: "5 + 2", answ: 7 },
                    { module: 5, point: 1, qest: "5 + 3", answ: 8 },
                    { module: 5, point: 1, qest: "5 + 4", answ: 9 },
                    { module: 5, point: 1, qest: "5 + 5", answ: 10 },
                    { module: 6, point: 1, qest: "6 + 0", answ: 6 },
                    { module: 6, point: 1, qest: "6 + 1", answ: 7 },
                    { module: 6, point: 1, qest: "6 + 2", answ: 8 },
                    { module: 6, point: 1, qest: "6 + 3", answ: 9 },
                    { module: 6, point: 1, qest: "6 + 4", answ: 10 },
                    { module: 7, point: 1, qest: "7 + 0", answ: 7 },
                    { module: 7, point: 1, qest: "7 + 1", answ: 8 },
                    { module: 7, point: 1, qest: "7 + 2", answ: 9 },
                    { module: 7, point: 1, qest: "7 + 3", answ: 10 },
                    { module: 8, point: 1, qest: "8 + 0", answ: 8 },
                    { module: 8, point: 1, qest: "8 + 1", answ: 9 },
                    { module: 8, point: 1, qest: "8 + 2", answ: 10 },
                    { module: 9, point: 1, qest: "9 + 0", answ: 9 },
                    { module: 9, point: 1, qest: "9 + 1", answ: 10 },
                ],
            },
            {
                mins: [
                    { module: 1, point: 1, qest: "1 - 0", answ: 1 },
                    { module: 1, point: 1, qest: "1 - 1", answ: 0 },
                    { module: 2, point: 1, qest: "2 - 0", answ: 2 },
                    { module: 2, point: 1, qest: "2 - 1", answ: 1 },
                    { module: 2, point: 1, qest: "2 - 2", answ: 0 },
                    { module: 3, point: 1, qest: "3 - 0", answ: 3 },
                    { module: 3, point: 1, qest: "3 - 1", answ: 2 },
                    { module: 3, point: 1, qest: "3 - 2", answ: 1 },
                    { module: 3, point: 1, qest: "3 - 3", answ: 0 },
                    { module: 4, point: 1, qest: "4 - 0", answ: 4 },
                    { module: 4, point: 1, qest: "4 - 1", answ: 3 },
                    { module: 4, point: 1, qest: "4 - 2", answ: 2 },
                    { module: 4, point: 1, qest: "4 - 3", answ: 1 },
                    { module: 4, point: 1, qest: "4 - 4", answ: 0 },
                    { module: 5, point: 1, qest: "5 - 0", answ: 5 },
                    { module: 5, point: 1, qest: "5 - 1", answ: 4 },
                    { module: 5, point: 1, qest: "5 - 2", answ: 3 },
                    { module: 5, point: 1, qest: "5 - 3", answ: 2 },
                    { module: 5, point: 1, qest: "5 - 4", answ: 1 },
                    { module: 5, point: 1, qest: "5 - 5", answ: 0 },
                    { module: 6, point: 1, qest: "6 - 0", answ: 6 },
                    { module: 6, point: 1, qest: "6 - 1", answ: 5 },
                    { module: 6, point: 1, qest: "6 - 2", answ: 4 },
                    { module: 6, point: 1, qest: "6 - 3", answ: 3 },
                    { module: 6, point: 1, qest: "6 - 4", answ: 2 },
                    { module: 6, point: 1, qest: "6 - 5", answ: 1 },
                    { module: 6, point: 1, qest: "6 - 6", answ: 0 },
                    { module: 7, point: 1, qest: "7 - 0", answ: 7 },
                    { module: 7, point: 1, qest: "7 - 1", answ: 6 },
                    { module: 7, point: 1, qest: "7 - 2", answ: 5 },
                    { module: 7, point: 1, qest: "7 - 3", answ: 4 },
                    { module: 7, point: 1, qest: "7 - 4", answ: 3 },
                    { module: 7, point: 1, qest: "7 - 5", answ: 2 },
                    { module: 7, point: 1, qest: "7 - 6", answ: 1 },
                    { module: 7, point: 1, qest: "7 - 7", answ: 0 },
                    { module: 8, point: 1, qest: "8 - 0", answ: 8 },
                    { module: 8, point: 1, qest: "8 - 1", answ: 7 },
                    { module: 8, point: 1, qest: "8 - 2", answ: 6 },
                    { module: 8, point: 1, qest: "8 - 3", answ: 5 },
                    { module: 8, point: 1, qest: "8 - 4", answ: 4 },
                    { module: 8, point: 1, qest: "8 - 5", answ: 3 },
                    { module: 8, point: 1, qest: "8 - 6", answ: 2 },
                    { module: 8, point: 1, qest: "8 - 7", answ: 1 },
                    { module: 8, point: 1, qest: "8 - 8", answ: 0 },
                    { module: 9, point: 1, qest: "9 - 0", answ: 9 },
                    { module: 9, point: 1, qest: "9 - 1", answ: 8 },
                    { module: 9, point: 1, qest: "9 - 2", answ: 7 },
                    { module: 9, point: 1, qest: "9 - 3", answ: 6 },
                    { module: 9, point: 1, qest: "9 - 4", answ: 5 },
                    { module: 9, point: 1, qest: "9 - 5", answ: 4 },
                    { module: 9, point: 1, qest: "9 - 6", answ: 3 },
                    { module: 9, point: 1, qest: "9 - 7", answ: 2 },
                    { module: 9, point: 1, qest: "9 - 8", answ: 1 },
                    { module: 9, point: 1, qest: "9 - 9", answ: 0 },
                    { module: 10, point: 1, qest: "10 - 0", answ: 10 },
                    { module: 10, point: 1, qest: "10 - 1", answ: 9 },
                    { module: 10, point: 1, qest: "10 - 2", answ: 8 },
                    { module: 10, point: 1, qest: "10 - 3", answ: 7 },
                    { module: 10, point: 1, qest: "10 - 4", answ: 6 },
                    { module: 10, point: 1, qest: "10 - 5", answ: 5 },
                    { module: 10, point: 1, qest: "10 - 6", answ: 4 },
                    { module: 10, point: 1, qest: "10 - 7", answ: 3 },
                    { module: 10, point: 1, qest: "10 - 8", answ: 2 },
                    { module: 10, point: 1, qest: "10 - 9", answ: 1 },
                    { module: 10, point: 1, qest: "10 - 10", answ: 0 },
                ],
            },
            {
                mult: [
                    { module: 1, point: 1, qest: "1 x 1", answ: 1 },
                    { module: 1, point: 1, qest: "1 x 2", answ: 2 },
                    { module: 1, point: 1, qest: "1 x 3", answ: 3 },
                    { module: 1, point: 1, qest: "1 x 4", answ: 4 },
                    { module: 1, point: 1, qest: "1 x 5", answ: 5 },
                    { module: 1, point: 1, qest: "1 x 6", answ: 6 },
                    { module: 1, point: 1, qest: "1 x 7", answ: 7 },
                    { module: 1, point: 1, qest: "1 x 8", answ: 8 },
                    { module: 1, point: 1, qest: "1 x 9", answ: 9 },
                    { module: 1, point: 1, qest: "1 x 10", answ: 10 },
                    { module: 2, point: 1, qest: "2 x 1", answ: 2 },
                    { module: 2, point: 1, qest: "2 x 2", answ: 4 },
                    { module: 2, point: 1, qest: "2 x 3", answ: 6 },
                    { module: 2, point: 1, qest: "2 x 4", answ: 8 },
                    { module: 2, point: 1, qest: "2 x 5", answ: 10 },
                    { module: 2, point: 1, qest: "2 x 6", answ: 12 },
                    { module: 2, point: 1, qest: "2 x 7", answ: 14 },
                    { module: 2, point: 1, qest: "2 x 8", answ: 16 },
                    { module: 2, point: 1, qest: "2 x 9", answ: 18 },
                    { module: 2, point: 1, qest: "2 x 10", answ: 20 },
                    { module: 3, point: 1, qest: "3 x 1", answ: 3 },
                    { module: 3, point: 1, qest: "3 x 2", answ: 6 },
                    { module: 3, point: 1, qest: "3 x 3", answ: 9 },
                    { module: 3, point: 1, qest: "3 x 4", answ: 12 },
                    { module: 3, point: 1, qest: "3 x 5", answ: 15 },
                    { module: 3, point: 1, qest: "3 x 6", answ: 18 },
                    { module: 3, point: 1, qest: "3 x 7", answ: 21 },
                    { module: 3, point: 1, qest: "3 x 8", answ: 24 },
                    { module: 3, point: 1, qest: "3 x 9", answ: 27 },
                    { module: 3, point: 1, qest: "3 x 10", answ: 30 },
                    { module: 4, point: 1, qest: "4 x 1", answ: 4 },
                    { module: 4, point: 1, qest: "4 x 2", answ: 8 },
                    { module: 4, point: 1, qest: "4 x 3", answ: 12 },
                    { module: 4, point: 1, qest: "4 x 4", answ: 16 },
                    { module: 4, point: 1, qest: "4 x 5", answ: 20 },
                    { module: 4, point: 1, qest: "4 x 6", answ: 24 },
                    { module: 4, point: 1, qest: "4 x 7", answ: 28 },
                    { module: 4, point: 1, qest: "4 x 8", answ: 32 },
                    { module: 4, point: 1, qest: "4 x 9", answ: 36 },
                    { module: 4, point: 1, qest: "4 x 10", answ: 40 },
                    { module: 5, point: 1, qest: "5 x 1", answ: 5 },
                    { module: 5, point: 1, qest: "5 x 2", answ: 10 },
                    { module: 5, point: 1, qest: "5 x 3", answ: 15 },
                    { module: 5, point: 1, qest: "5 x 4", answ: 20 },
                    { module: 5, point: 1, qest: "5 x 5", answ: 25 },
                    { module: 5, point: 1, qest: "5 x 6", answ: 30 },
                    { module: 5, point: 1, qest: "5 x 7", answ: 35 },
                    { module: 5, point: 1, qest: "5 x 8", answ: 40 },
                    { module: 5, point: 1, qest: "5 x 9", answ: 45 },
                    { module: 5, point: 1, qest: "5 x 10", answ: 50 },
                    { module: 6, point: 1, qest: "6 x 1", answ: 6 },
                    { module: 6, point: 1, qest: "6 x 2", answ: 12 },
                    { module: 6, point: 1, qest: "6 x 3", answ: 18 },
                    { module: 6, point: 1, qest: "6 x 4", answ: 24 },
                    { module: 6, point: 1, qest: "6 x 5", answ: 30 },
                    { module: 6, point: 1, qest: "6 x 6", answ: 36 },
                    { module: 6, point: 1, qest: "6 x 7", answ: 42 },
                    { module: 6, point: 1, qest: "6 x 8", answ: 48 },
                    { module: 6, point: 1, qest: "6 x 9", answ: 54 },
                    { module: 6, point: 1, qest: "6 x 10", answ: 60 },
                    { module: 7, point: 1, qest: "7 x 1", answ: 7 },
                    { module: 7, point: 1, qest: "7 x 2", answ: 14 },
                    { module: 7, point: 1, qest: "7 x 3", answ: 21 },
                    { module: 7, point: 1, qest: "7 x 4", answ: 28 },
                    { module: 7, point: 1, qest: "7 x 5", answ: 35 },
                    { module: 7, point: 1, qest: "7 x 6", answ: 42 },
                    { module: 7, point: 1, qest: "7 x 7", answ: 49 },
                    { module: 7, point: 1, qest: "7 x 8", answ: 56 },
                    { module: 7, point: 1, qest: "7 x 9", answ: 63 },
                    { module: 7, point: 1, qest: "7 x 10", answ: 70 },
                    { module: 8, point: 1, qest: "8 x 1", answ: 8 },
                    { module: 8, point: 1, qest: "8 x 2", answ: 16 },
                    { module: 8, point: 1, qest: "8 x 3", answ: 24 },
                    { module: 8, point: 1, qest: "8 x 4", answ: 32 },
                    { module: 8, point: 1, qest: "8 x 5", answ: 40 },
                    { module: 8, point: 1, qest: "8 x 6", answ: 48 },
                    { module: 8, point: 1, qest: "8 x 7", answ: 56 },
                    { module: 8, point: 1, qest: "8 x 8", answ: 64 },
                    { module: 8, point: 1, qest: "8 x 9", answ: 72 },
                    { module: 8, point: 1, qest: "8 x 10", answ: 80 },
                    { module: 9, point: 1, qest: "9 x 1", answ: 9 },
                    { module: 9, point: 1, qest: "9 x 2", answ: 18 },
                    { module: 9, point: 1, qest: "9 x 3", answ: 27 },
                    { module: 9, point: 1, qest: "9 x 4", answ: 36 },
                    { module: 9, point: 1, qest: "9 x 5", answ: 45 },
                    { module: 9, point: 1, qest: "9 x 6", answ: 54 },
                    { module: 9, point: 1, qest: "9 x 7", answ: 63 },
                    { module: 9, point: 1, qest: "9 x 8", answ: 72 },
                    { module: 9, point: 1, qest: "9 x 9", answ: 81 },
                    { module: 9, point: 1, qest: "9 x 10", answ: 90 },
                ],
            },
            {
                dvde: [
                    { module: 1, point: 1, qest: "1 / 1", answ: 1 },
                    { module: 2, point: 1, qest: "2 / 1", answ: 2 },
                    { module: 2, point: 1, qest: "2 / 2", answ: 1 },
                    { module: 3, point: 1, qest: "3 / 1", answ: 3 },
                    { module: 3, point: 1, qest: "3 / 3", answ: 1 },
                    { module: 4, point: 1, qest: "4 / 1", answ: 4 },
                    { module: 4, point: 1, qest: "4 / 2", answ: 2 },
                    { module: 4, point: 1, qest: "4 / 4", answ: 1 },
                    { module: 5, point: 1, qest: "5 / 1", answ: 5 },
                    { module: 5, point: 1, qest: "5 / 5", answ: 1 },
                    { module: 6, point: 1, qest: "6 / 1", answ: 6 },
                    { module: 6, point: 1, qest: "6 / 2", answ: 3 },
                    { module: 6, point: 1, qest: "6 / 3", answ: 2 },
                    { module: 6, point: 1, qest: "6 / 6", answ: 1 },
                    { module: 7, point: 1, qest: "7 / 1", answ: 7 },
                    { module: 7, point: 1, qest: "7 / 7", answ: 1 },
                    { module: 8, point: 1, qest: "8 / 1", answ: 8 },
                    { module: 8, point: 1, qest: "8 / 2", answ: 4 },
                    { module: 8, point: 1, qest: "8 / 4", answ: 2 },
                    { module: 8, point: 1, qest: "8 / 8", answ: 1 },
                    { module: 9, point: 1, qest: "9 / 1", answ: 9 },
                    { module: 9, point: 1, qest: "9 / 3", answ: 3 },
                    { module: 9, point: 1, qest: "9 / 9", answ: 1 },
                    { module: 10, point: 1, qest: "10 / 1", answ: 10 },
                    { module: 10, point: 1, qest: "10 / 2", answ: 5 },
                    { module: 10, point: 1, qest: "10 / 5", answ: 2 },
                    { module: 10, point: 1, qest: "10 / 10", answ: 1 },
                ],
            },
        ],
    }),

    actions: {
        async loadData() {
            const all = localStorage.getItem("m:all");
            if (all) this.all = unzip(all);

            // await this.getData();
        },

        pickGame(game) {
            this.all.game = game;
        },

        // настройки модулей
        toggleModule(number) {
            const index = this.all.modules.indexOf(number);
            if (index === -1) {
                this.all.modules.push(number); // Добавить модуль, если не выбран
            } else {
                this.all.modules.splice(index, 1); // Удалить модуль, если уже выбран
            }
        },

        cleanAll() {
            localStorage.removeItem("m:all");
        },

        addPoints(point) {
            this.all.points = parseInt(this.all.points + point);
            // Сохраняем в LocalStorage и на сервер
            localStorage.setItem("m:all", zip(this.all));
        },

        setTimeOne(seconds) {
            console.log("setTimeOne", seconds);
            this.all.timeOne = parseInt(seconds);
            // Сохраняем в LocalStorage и на сервер
            localStorage.setItem("m:all", zip(this.all));
        },

        addToResults(data) {
            this.all.results.push(data);
            // Сохраняем в LocalStorage и на сервер
            localStorage.setItem("m:all", zip(this.all));
        },

        clearResults() {
            this.all.results = [];
            localStorage.setItem("m:all", zip(this.all));
        },

        // startTimer(cat_id) {
        //     // this.log.push({ a: "startTimer", cat_id: cat_id });

        //     const now = Date.now();

        //     if (this.times.length > 0) {
        //         // Получаем последний таймер
        //         const lastTime = this.times[this.times.length - 1];

        //         // Если последняя активная категория та же, что и текущая, прекращаем выполнение
        //         if (lastTime.id === cat_id) return;

        //         // Вычисляем разницу во времени с последней записи
        //         lastTime.t = Math.floor((now - lastTime.s) / 1000); // В секундах

        //         // Обновляем последнюю запись в массиве
        //         this.times[this.times.length - 1] = lastTime;
        //     }

        //     // Добавляем новую запись в массив
        //     this.times.push({ i: key(3, this.times), s: now, id: cat_id, m: 0 });

        //     this.reset = now; // Триггерим изменение состояния

        //     // Сохраняем обновленные данные в LocalStorage и на сервер
        //     localStorage.setItem("m:all", zip(this.all));
        //     // this.send();
        // },

        // async getData() {
        //     // this.log.push({ a: "getData" });
        //     // console.log("getData");

        //     await this.waitForStudentId();
        //     const student = userStore().student;
        //     if (!student || !student.student_id) return;
        //     // console.log("student_id", student.student_id);

        //     const res = await API.GET("app/time/get", { id: student.student_id });

        //     if (res.data?.categories) {
        //         localStorage.setItem("m:cats", res.data?.categories);
        //         this.cats = unzip(res.data.categories);
        //     }
        //     if (res.data?.times) {
        //         localStorage.setItem("m:times", res.data?.times);
        //         this.times = unzip(res.data.times);

        //         this.times = this.times.map((time) => {
        //             if (!time.i || time.i === undefined) time.i = key(3, this.times);
        //             return time;
        //         });
        //         localStorage.setItem("m:times", zip(this.times));
        //     }
        // },

        // async send() {
        //     await this.waitForStudentId();
        //     const student = userStore().student;
        //     if (!student || !student.student_id) return;

        //     const formData = new FormData();
        //     formData.append("student_id", student.student_id);
        //     formData.append("cats", zip(this.cats));
        //     formData.append("times", zip(this.times));

        //     await API.POST("app/math/save", formData);
        // },

        async waitForStudentId() {
            while (!userStore().student || !userStore().student.student_id) {
                await new Promise((resolve) => setTimeout(resolve, 100)); // ждем 100 мс перед следующей проверкой
            }
        },
    },
});
