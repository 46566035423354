<template>
    <div class="w-full h-screen items-center py-12 bg-black bg-gradient-to-tl from-cyan-600/20 via-cyan-100/0 to-cyan-600/20 overflow-hidden">
        <div class="absolute top-1 right-1 z-50 flex gap-2" v-if="wsStatus">
            <div class="text-teal-500 text-[3px]">{{ student?.name }}</div>
            <div class="w-1 h-1 rounded-full bg-teal-500"></div>
        </div>
        <div v-else class="absolute top-1 right-1 z-50 w-1 h-1 rounded-full bg-red-500"></div>

        <div v-if="s.popup != 'start'" class="absolute top-0 z-40 w-full h-12 flex items-center overflow-hidden">
            <div v-if="student" class="grid grid-cols-2 w-full p-2 !text-cyan-200">
                <div class="flex items-center gap-2 font-bold">
                    <IconEnergy />
                    <div class="text-lg">{{ student.energy ?? 0 }}</div>
                </div>

                <div class="flex justify-end items-center gap-2 font-bold">
                    <IconCoin />
                    <div class="text-lg">{{ student.point ?? 0 }}</div>
                </div>
            </div>
        </div>

        <div class="w-full h-[calc(100vh-96px)] text-white relative z-10">
            <template v-if="s.modal == 'point'"><Point /></template>
            <template v-if="s.modal == 'level'"><Level /></template>

            <component :is="currentComponent" />
        </div>

        <div
            @click="haptic()"
            class="absolute bottom-8 left-8 right-8 z-40 h-12 grid grid-cols-4 bg-cyan-600/20 text-white/80 backdrop-blur-sm overflow-hidden rounded-lg active:scale-95 transition-transform duration-500"
        >
            <div class="flex justify-center items-center icon" :class="{ '!text-cyan-500': s.popup == 'b:start' }" @click="setPopup('b:start')">
                <IconPlay />
            </div>
            <div class="flex justify-center items-center icon" :class="{ '!text-cyan-500': s.popup == 'b:ref' }" @click="setPopup('b:ref')">
                <IconUser />
            </div>
            <div class="flex justify-center items-center icon" :class="{ '!text-cyan-500': s.popup == 'b:raiting' }" @click="setPopup('b:raiting')">
                <IconStar />
            </div>
            <div class="flex justify-center items-center icon" :class="{ '!text-cyan-500': s.popup == 'b:profile' }" @click="setPopup('b:profile')">
                <IconProfile />
            </div>
        </div>
    </div>

    <div v-if="s.popup != 'b:game'" class="absolute top-0 left-0 z-1 w-full h-screen"><ParticleBackground /></div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";
import { useBullStore } from "@/store/bull";
import { defineAsyncComponent } from "vue";

import Point from "./modal/Point.vue";
import Level from "./modal/Level.vue";

import ParticleBackground from "../components/Backgrounds/Particle.vue";

export default {
    name: "MainPage",

    components: { Point, Level, ParticleBackground },

    data() {
        return {
            telegram: null,
            bot_id: 11,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup", "setSettings", "setView", "haptic"]),
        ...mapState(useUserStore, ["student", "setFid", "addPoint"]),
        ...mapState(useBullStore, ["wsStatus", "game", "clicked", "waitGames", "connectWs"]),

        currentComponent() {
            const componentsMap = {
                "b:start": defineAsyncComponent(() => import("./Start.vue")),
                "b:friend": defineAsyncComponent(() => import("./Friend.vue")),
                "b:wait": defineAsyncComponent(() => import("./Wait.vue")),
                "b:ref": defineAsyncComponent(() => import("./Raferrals.vue")),
                "b:raiting": defineAsyncComponent(() => import("./Raiting.vue")),
                "b:profile": defineAsyncComponent(() => import("./Profile.vue")),
                "b:games": defineAsyncComponent(() => import("./Games.vue")),
                "b:game": defineAsyncComponent(() => import("./Game.vue")),
                "b:finish": defineAsyncComponent(() => import("./Finish.vue")),
            };
            return componentsMap[this.s.popup] || componentsMap["b:start"];
        },
    },

    methods: {
        async initTelegram() {
            this.telegram = window.Telegram.WebApp;
            if (this.telegram && this.telegram.initDataUnsafe) {
                this.telegram.expand();
                this.telegram.disableVerticalSwipes();

                // if (typeof this.telegram.disableVerticalSwipes === "function") {
                //     alert("disableVerticalSwipes доступна.");
                // } else {
                //     alert("disableVerticalSwipes не доступна.");
                // }

                // Ожидание ответа от sendApp перед дальнейшей игрой
                // await this.sendApp({ bot_id: this.bot_id, fid: this.telegram.initDataUnsafe.user?.id });

                if (this.telegram.initDataUnsafe.user?.id) {
                    this.setFid({ bot_id: this.bot_id, fid: this.telegram.initDataUnsafe.user?.id });
                } else {
                    // для тестов
                    this.setFid({ bot_id: this.bot_id, fid: 574461920 }); // Я 406209800
                }

                if (!this.wsStatus) this.connectWs(); // Создаем WebSocket соединение
            }
        },
    },

    mounted() {
        // this.setPopup("b:finish");

        if (!this.s.popup) this.setPopup("b:start");

        if (window && window?.Telegram && window.Telegram?.WebApp) {
            this.initTelegram();
        } else {
            // Load the Telegram WebApp script dynamically
            const scriptElement = document.createElement("script");
            scriptElement.src = "https://telegram.org/js/telegram-web-app.js";
            scriptElement.async = true;

            scriptElement.onload = () => {
                if (window.Telegram?.WebApp) this.initTelegram();
            };

            const firstChild = document.head.firstChild;
            document.head.insertBefore(scriptElement, firstChild);
        }
    },
};
</script>

<style>
.icon path {
    stroke-dashoffset: 0;
    stroke-dasharray: 100;
}
.icon:active path {
    animation: draw 1s linear forwards;
}

@keyframes draw {
    0% {
        stroke-dashoffset: 100;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
</style>
