import IconPack from "@/components/IconPack.vue";
import IconLoader from "@/components/IconLoader";
import IconSpin from "@/components/ui/IcoSpin.vue";

import CandleHummer from "@/components/icons/CandleHummer";

import IconAlarm from "@/components/icons/IconAlarm";
import IconAlarmMini from "@/components/icons/IconAlarmMini";
import IconBoxTime from "@/components/icons/IconBoxTime";
import IconClose from "@/components/icons/IconClose";
import Coin from "@/components/icons/Coin";
import Coin2 from "@/components/icons/Coin2";
import IconDelete from "@/components/icons/IconDelete";
import IconEdit from "@/components/icons/IconEdit";
import IconEditMini from "@/components/icons/IconEditMini";
import Energy from "@/components/icons/Energy";
import IconEye from "@/components/icons/IconEye";
import IconEyeClose from "@/components/icons/IconEyeClose";
import IconGraf from "@/components/icons/IconGraf";
import IconHome from "@/components/icons/IconHome";
import IconHourGlass from "@/components/icons/IconHourGlass";
import IconLevel from "@/components/icons/IconLevel";
import IconLink from "@/components/icons/IconLink";
import IconMore from "@/components/icons/IconMore";
import IconOk from "@/components/icons/IconOk";
import IconPlay from "@/components/icons/IconPlay";
import IconPlus from "@/components/icons/IconPlus";
import Profile from "@/components/icons/Profile";
import IconProtfolio from "@/components/icons/IconProtfolio";
import IconRaiting from "@/components/icons/IconRaiting";
import IconRe from "@/components/icons/IconRe";
import IconRound from "@/components/icons/IconRound";
import IconSetting from "@/components/icons/IconSetting";
import IconSort from "@/components/icons/IconSort";
import IconSortMini from "@/components/icons/IconSortMini";
import IconStar from "@/components/icons/IconStar";
import IconText from "@/components/icons/IconText";
import IconUser from "@/components/icons/IconUser";

import GamePlus from "@/components/icons/GamePlus";
import GameMinus from "@/components/icons/GameMinus";
import GameMulti from "@/components/icons/GameMulti";
import GameDivide from "@/components/icons/GameDivide";

import Send from "@/components/icons/Send";
import Share from "@/components/icons/Share";
import Timer from "@/components/icons/Timer";

export default [
    IconPack,
    IconLoader,
    IconSpin,

    CandleHummer,

    IconAlarm,
    IconAlarmMini,
    IconBoxTime,
    IconClose,
    Coin,
    Coin2,
    IconDelete,
    IconEdit,
    IconEditMini,
    Energy,
    IconEye,
    IconEyeClose,
    IconHome,
    IconHourGlass,
    IconGraf,
    IconLevel,
    IconLink,
    IconMore,
    IconOk,
    IconPlus,
    IconPlay,
    Profile,
    IconProtfolio,
    IconRaiting,
    IconRe,
    IconRound,
    IconSetting,
    IconSort,
    IconSortMini,
    IconStar,
    IconText,
    IconUser,

    GamePlus,
    GameMinus,
    GameMulti,
    GameDivide,

    Send,
    Share,
    Timer,
];
