<template>
    <div class="fixed inset-0 flex items-center justify-center z-40 backdrop-blur-sm level-animation" @click="closeModal(), haptic()">
        <div class="w-52 h-52 bg-cyan-800/50 border border-cyan-500/50 rounded flex flex-col justify-center items-center text-cyan-400">
            <span>NEW LEVEL</span>
            <span class="text-6xl font-bold mt-2">{{ currentlevel }}</span>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";

export default {
    name: "LevelModal",

    data() {
        return { currentlevel: 0 };
    },

    computed: {
        ...mapState(useMainStore, ["closeModal", "haptic"]),
        ...mapState(useUserStore, ["student", "old"]),
    },

    methods: {
        animatelevels() {
            const newlevel = this.student.level;
            setTimeout(() => {
                this.currentlevel = newlevel;
            }, 500);
        },
    },

    mounted() {
        if (this.old) this.currentlevel = this.old.level;
        this.animatelevels();
    },
};
</script>

<style scoped>
.level-animation {
    animation: popIn 0.5s ease-in-out forwards;
}

@keyframes popIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
</style>
