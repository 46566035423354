<template>
    <div class="p-4">
        <div class="text-xl text-center font-black mb-4">Choose a module!</div>

        <div class="w-full relative z-20 pb-28 h-screen overflow-y-auto">{{ student }}</div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";

// import Vivus from "vivus";

export default {
    name: "ModulesPage",

    data() {
        return {
            vivusInstance: null,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup"]),
        ...mapState(useUserStore, ["student"]),
    },

    methods: {},

    mounted() {
        // this.animateSvg();
    },
};
</script>
