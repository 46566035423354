import { createApp, watch } from "vue";
import { createPinia } from "pinia";
import { useMainStore } from "./store/main.js";
import { createI18n } from "vue-i18n";

const app = createApp(App);
const pinia = createPinia();

// Переводы
import en from "./lang/en.json";
import ru from "./lang/ru.json";

import Toaster from "@meforma/vue-toaster";
import "@/assets/tailwind.css";
import components from "@/components";

import router from "@/services/router";
import App from "@/App.vue";

components.forEach((component) => {
    app.component(component.name, component);
});

app.use(pinia);

app.use(Toaster, { position: "bottom-right" });
app.use(router);

// Языки
const mainStore = useMainStore(pinia);
const i18n = createI18n({ locale: mainStore.s.lang, fallbackLocale: "en", messages: { en, ru } });
app.use(i18n);

watch(
    () => mainStore.s.lang,
    (newLang) => {
        i18n.global.locale = newLang;
    }
);

app.mount("#app");
