<template>
    <div class="w-full h-14 flex items-center bg-sky-100">
        <div class="grid grid-cols-7 w-full p-2" v-if="webapp && webapp.student">
            <div class="flex flex-col items-center">
                <img
                    v-if="webapp.student?.picture"
                    class="w-10 h-10 shrink-0 object-cover rounded-full"
                    :src="'https://yii.botteach.ru/users/orig/' + webapp.student?.picture"
                />
                <div v-else class="flex justify-center items-center rounded-full w-10 h-10 shrink-0 bg-slate-200 text-slate-500">
                    {{ webapp.student?.name?.slice(0, 1) }}
                </div>
            </div>
            <div class="flex flex-col items-center">
                <div class="text-lg">{{ webapp.student.life ?? 0 }}</div>
                <span class="text-[9px] text-slate-400"> ЖИЗНИ</span>
            </div>
            <div class="flex flex-col items-center">
                <div class="text-lg">{{ webapp.student.energy ?? 0 }}</div>
                <span class="text-[9px] text-slate-400"> ЭНЕРГИЯ</span>
            </div>
            <div class="flex flex-col items-center">
                <div class="text-lg">{{ webapp.student.point ?? 0 }}</div>
                <span class="text-[9px] text-slate-400"> БАЛЛЫ</span>
            </div>
            <div class="flex flex-col items-center">
                <div class="text-lg">{{ webapp.student.day ?? 0 }}</div>
                <span class="text-[9px] text-slate-400"> ДНИ</span>
            </div>
            <div class="flex flex-col items-center">
                <div class="text-lg">{{ webapp.student.friend ?? 0 }}</div>
                <span class="text-[9px] text-slate-400"> ДРУЗЬЯ</span>
            </div>
            <div class="flex items-center">
                <!-- <div class="flex items-center" v-if="s.popup != 'menu'" @click="setPopup('menu')">МЕНЮ</div> -->
                <!-- <div class="flex items-center" v-if="s.popup == 'menu'" @click="setPopup()">ЗАКРЫТЬ</div> -->
            </div>
        </div>
    </div>

    <div class="w-full h-full min-h-[calc(100vh-56px)] bg-slate-100 relative p-4">
        <div class="w-full mb-4">Моя цель</div>

        <div class="justify-center items-center">
            <div v-for="g in goal.vals" :key="g" class="mb-1 bg-white/50 p-1">{{ g.name }}: {{ g.text }}</div>
        </div>
    </div>

    <template v-if="s.popup == 'menu'"><MainMenu /></template>
    <template v-if="s.popup == 'goals'"><Goals /></template>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";
import moment from "moment";

import MainMenu from "./Menu.vue";
import Goals from "./Goals.vue";

export default {
    name: "MainPage",

    components: { MainMenu, Goals },

    data() {
        return {
            moment: moment,
            telegram: null,
            bot_id: 7,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup", "setSettings", "setView"]),
        ...mapState(useUserStore, ["webapp", "sendApp", "getGoal"]),
    },

    methods: {
        initTelegram() {
            this.telegram = window.Telegram.WebApp;
            if (this.telegram && this.telegram.initDataUnsafe) {
                this.sendApp({ bot_id: this.bot_id, fid: this.telegram.initDataUnsafe.user?.id });
                this.getGoal();
            }
        },
    },

    mounted() {
        if (window && window?.Telegram && window.Telegram?.WebApp) {
            this.initTelegram();
        } else {
            // Load the Telegram WebApp script dynamically
            const scriptElement = document.createElement("script");
            scriptElement.src = "https://telegram.org/js/telegram-web-app.js";
            scriptElement.async = true;

            scriptElement.onload = () => {
                if (window.Telegram?.WebApp) this.initTelegram();
            };

            const firstChild = document.head.firstChild;
            document.head.insertBefore(scriptElement, firstChild);
        }
    },
};
</script>
