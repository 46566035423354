<template>
    <svg
        class="fill-stroke transition duration-300 cursor-pointer"
        :class="'w-' + size + ' h-' + size"
        :width="size * 4"
        :height="size * 4"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <circle cx="9" cy="9" r="2" />
        <path d="M13 15C13 16.1046 13 17 9 17C5 17 5 16.1046 5 15C5 13.8954 6.79086 13 9 13C11.2091 13 13 13.8954 13 15Z" />
        <path
            d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
        />
        <path d="M19 12H15" />
        <path d="M19 9H14" />
        <path d="M19 15H16" />
    </svg>
</template>

<script>
export default {
    name: "IconProfile",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
