<template>
    <svg
        class="fill-stroke"
        :class="'w-' + size + ' h-' + size"
        :width="size * 4"
        :height="size * 4"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M9.16998 14.83L14.83 9.17004" />
        <path d="M14.83 14.83L9.16998 9.17004" />
    </svg>
</template>

<script>
export default {
    name: "GameMulti",
    props: {
        size: {
            type: Number,
            default: 20,
        },
    },
};
</script>
