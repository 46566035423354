// Функция для генерации случайного ключа
export function key(length = 3, messages = []) {
    const characters = "0123456789abcdefghijkmnpqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ!#$%()*+-.:;<=>@[]{|}~";
    let key;

    function generateKey() {
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    // Генерация ключа и проверка его уникальности
    do {
        key = generateKey();
    } while (messages.some((message) => message.mid === key));

    return key;
}
