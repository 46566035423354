<template>
    <div class="fixed inset-0 flex items-center justify-center z-40 backdrop-blur-sm point-animation" @click="closeModal(), haptic()">
        <div class="w-52 h-52 bg-cyan-800/50 border border-cyan-500/50 rounded flex flex-col justify-center items-center text-cyan-400">
            <span>NEW POINTS</span>
            <span class="text-6xl font-bold mt-2"> {{ currentPoint }}</span>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";

export default {
    name: "PointModal",

    data() {
        return { currentPoint: 0 };
    },

    computed: {
        ...mapState(useMainStore, ["closeModal", "haptic"]),
        ...mapState(useUserStore, ["student", "old"]),
    },

    methods: {
        animatePoints() {
            const oldPoint = this.old.point;
            const newPoint = this.student.point;

            let step = (newPoint - oldPoint) / 20;
            let interval = setInterval(() => {
                if (this.currentPoint < newPoint) {
                    this.currentPoint += step;
                } else {
                    this.currentPoint = newPoint;
                    clearInterval(interval);
                }
            }, 50);
        },
    },

    mounted() {
        if (this.old) this.currentPoint = this.old.point;
        this.animatePoints();
    },
};
</script>

<style scoped>
.point-animation {
    animation: popIn 0.5s ease-in-out forwards;
}

@keyframes popIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
</style>
