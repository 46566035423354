<template>
    <div class="w-full h-dvh items-center bg-sky-800 bg-gradient-to-tl from-sky-600/50 via-sky-300/50 to-sky-600/50 overflow-hidden relative">
        <div class="grid grid-cols-4 w-full py-2 px-4 !text-cyan-100">
            <div class="flex items-center gap-2 font-bold">
                <IconEnergy />
                <div class="text-lg">{{ student?.energy ?? 100 }}</div>
            </div>

            <div></div>

            <div class="flex justify-end items-center gap-2 font-bold">
                <!-- <IconBoxTime />
                <div class="text-lg">{{ student?.staking?.sum }}</div> -->
            </div>

            <div class="flex justify-end items-center gap-2 font-bold">
                <IconCoin />
                <div class="text-lg">{{ all.points?.toLocaleString() }}</div>
            </div>
        </div>

        <div class="w-full text-white relative z-10">
            <template v-if="s.popup == 'm:start'"><Start /></template>
            <template v-if="s.popup == 'm:play'"><Play /></template>
            <template v-if="s.popup == 'm:settings'"><Settings /></template>
            <template v-if="s.popup == 'm:stat'"><Stat /></template>
        </div>

        <div
            @click="haptic()"
            class="absolute bottom-4 left-4 right-4 z-30 h-12 grid grid-cols-4 bg-sky-200/20 text-white/50 backdrop-blur-sm overflow-hidden rounded-lg active:scale-95 transition-transform duration-500"
        >
            <div class="flex justify-center items-center icon" :class="{ '!text-sky-200': s.popup == 'm:start' }" @click="setPopup('m:start')">
                <IconPlay />
            </div>

            <div class="flex justify-center items-center icon" :class="{ '!text-sky-200': s.popup == 'm:settings' }" @click="setPopup('m:settings')">
                <IconSetting />
            </div>

            <div class="flex justify-center items-center icon" :class="{ '!text-sky-200': s.popup == 'm:stat' }" @click="setPopup('m:stat')">
                <IconGraf />
            </div>

            <div class="flex justify-center items-center icon" :class="{ '!text-sky-200': s.popup == 'm:profile' }" @click="setPopup('m:profile')">
                <IconProfile />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";
import { useMathStore } from "@/store/math";
import moment from "moment";

import Play from "./Play.vue";
import Start from "./Start.vue";
import Settings from "./Settings.vue";
import Stat from "./Stat.vue";

export default {
    name: "MainPage",

    components: { Play, Start, Settings, Stat },

    data() {
        return {
            moment: moment,
            telegram: null,
            bot_id: 10,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "loadS", "setPopup", "closePopup", "setSettings", "setView", "setLang", "haptic"]),
        ...mapState(useUserStore, ["student", "sendApp"]),
        ...mapState(useMathStore, ["all", "loadData"]),
    },

    methods: {
        initTelegram() {
            this.telegram = window.Telegram.WebApp;
            if (this.telegram && this.telegram.initDataUnsafe) {
                const lang = this.telegram.initDataUnsafe.user?.language_code;
                this.setLang(lang);

                // if (typeof this.telegram.disableVerticalSwipes === "function") {
                //     alert("disableVerticalSwipes доступна.");
                // } else {
                //     alert("disableVerticalSwipes не доступна.");
                // }

                // alert("isVerticalSwipesEnabled" + this.telegram.isVerticalSwipesEnabled);

                this.telegram.expand();
                this.telegram.disableVerticalSwipes();

                this.sendApp({ bot_id: this.bot_id, fid: this.telegram.initDataUnsafe.user?.id });
            }
        },

        animatePoint(start, end) {
            if (parseInt(start) < 1 || start == undefined) start = 0;
            const step = (end - start) / 10;
            let current = start;
            const interval = setInterval(() => {
                current += step;
                if ((step > 0 && current >= end) || (step < 0 && current <= end)) {
                    current = end;
                    clearInterval(interval);
                }
                this.point = Math.round(current);
            }, 100);
        },
    },

    mounted() {
        if (window && window?.Telegram && window.Telegram?.WebApp) {
            this.initTelegram();
        } else {
            // Load the Telegram WebApp script dynamically
            const scriptElement = document.createElement("script");
            scriptElement.src = "https://telegram.org/js/telegram-web-app.js";
            scriptElement.async = true;

            scriptElement.onload = () => {
                if (window.Telegram?.WebApp) this.initTelegram();
            };

            const firstChild = document.head.firstChild;
            document.head.insertBefore(scriptElement, firstChild);
        }

        this.loadS();
        this.loadData();

        if (!this.s.popup || this.s.popup == "start") this.setPopup("m:start");
    },
};
</script>

<style>
.icon path {
    stroke-dashoffset: 0;
    stroke-dasharray: 100;
}
.icon:active path {
    animation: draw 1s linear forwards;
}

@keyframes draw {
    0% {
        stroke-dashoffset: 100;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
</style>
