<template>
    <div class="p-4">
        <div class="text-center text-lg">Invite a friend</div>
        <div class="text-center opacity-50">Get 1000 coins</div>
        <div class="text-center opacity-50">And receive 5% of their coins</div>

        <div class="flex justify-center items-center gap-2 mt-8 text-cyan-600">
            <IconSend />

            <a @click="haptic()" class="text-base cursor-copy" :href="'https://t.me/share/url?url=https://t.me/PredictorAlBot?start=' + student.fid">
                https://t.me/PredictorAlBot
            </a>
        </div>

        <div class="w-full relative mt-8 h-screen overflow-y-auto">
            <div
                v-for="item in referrals"
                :key="item"
                class="grid grid-cols-2 items-center mb-4 p-4 bg-cyan-600/30 border border-cyan-600/50 text-base rounded backdrop-blur-sm"
            >
                <div>{{ item.name }}</div>
                <div class="text-right">{{ parseInt(item.point / 20).toLocaleString() }}</div>
            </div>
        </div>
    </div>

    <!-- @click="copyToClipboard('https://t.me/PredictorAlBot?start=' + student.fid)" -->
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";
import { usePredictorStore } from "@/store/predictor";

export default {
    name: "ReferralPage",

    data() {
        return {
            modal: false,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "setAlert", "haptic"]),
        ...mapState(useUserStore, ["student", "referrals", "getReferrals"]),
        ...mapState(usePredictorStore, []),
    },

    methods: {
        copyToClipboard(text) {
            const el = document.createElement("textarea");
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);

            this.setAlert({ msg: "Скопировано в буфер", type: "success" });
        },
    },

    mounted() {
        // this.getReferrals();
    },
};
</script>
