<template>
    <div ref="chart"></div>
</template>

<script>
import * as d3 from "d3";

export default {
    name: "DonutChart",

    props: {
        data: {
            type: Array,
            required: true,
        },
    },

    watch: {
        data: {
            handler() {
                this.updateDonutChart();
            },
            deep: true,
        },
    },

    methods: {
        formatTime(sec) {
            const s = Math.floor(sec);
            const m = Math.floor(s / 60);
            const h = Math.floor(m / 60);
            const d = Math.floor(h / 24);
            // const S = (s % 60).toString().padStart(2, "0");
            // const M = (m % 60).toString().padStart(2, "0");
            // const H = (h % 24).toString().padStart(2, "0");

            const S = parseInt(s % 60);
            const M = parseInt(m % 60);
            const H = parseInt(h % 24);

            if (d > 0) return `${d}d ${H}h ${M}m ${S}s`;
            if (H > 0) return `${H}h ${M}m ${S}s`;
            if (M > 0) return `${M}m ${S}s`;
            if (S > 0) return `${S}s`;
            return `-`;

            // if (H > 0) return `${H}:${M}:${S}`;
            // if (M > 0) return `${M}:${S}`;
            // return `${S}`;
        },

        generateSandColors(numColors) {
            const colors = d3
                .scaleLinear()
                .domain([0, numColors - 1])
                .range(["#F5DEB3", "#60503f"]) // Градиент
                .interpolate(d3.interpolateLab);

            return Array.from({ length: numColors }, (_, i) => colors(i));
        },

        createDonutChart() {
            const width = window.innerWidth;
            const height = window.innerHeight;
            const radius = Math.min(width, height / 2) / 2;
            const innerRadius = radius * 0.6;
            const outerRadius = radius - 1;

            this.arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);

            this.pie = d3
                .pie()
                .sort(null)
                .value((d) => d.total);

            this.svg = d3
                .select(this.$refs.chart)
                .append("svg")
                .attr("width", width)
                .attr("height", height)
                .attr("viewBox", [-width / 2, -height / 2, width, height])
                .attr("style", "max-width: 100%; height: auto;");

            this.donutGroup = this.svg.append("g").attr("transform", `translate(0, ${-height / 5})`);

            this.updateDonutChart(true);
        },

        updateDonutChart() {
            // Пересоздаем цветовую шкалу при каждом обновлении данных
            this.colors = this.generateSandColors(this.data.length);
            this.color = d3
                .scaleOrdinal()
                .domain(this.data.map((d) => d.name))
                .range(this.colors);

            this.drawCenterText(); // Текст в центре

            const path = this.donutGroup.selectAll("path").data(this.pie(this.data), (d) => d.data.name);

            path.enter()
                .append("path")
                .attr("fill", (d) => this.color(d.data.name))
                .attr("d", this.arc)
                .each(function (d) {
                    this._current = d;
                })
                .append("title")
                .text((d) => `${d.data.name}: ${this.formatTime(d.data.total)}`);

            path.transition()
                .duration(750)
                .attrTween(
                    "d",
                    function (a) {
                        const i = d3.interpolate(this._current, a);
                        this._current = i(0);
                        return (t) => this.arc(i(t));
                    }.bind(this)
                )
                .attr("fill", (d) => this.color(d.data.name));

            path.exit().remove();

            this.updateLegend();
        },

        drawCenterText() {
            const totalSec = this.data.reduce((sum, item) => sum + item.total, 0); // Суммирование всех значений
            const formattedTime = this.formatTime(totalSec); // Форматирование времени

            // Удаление предыдущего текста (если он есть), чтобы избежать дублирования
            this.donutGroup.selectAll(".center-text").remove();

            // Добавление нового текста
            this.donutGroup
                .append("text")
                .attr("class", "center-text")
                .attr("text-anchor", "middle") // Выравнивание текста по центру
                .attr("dy", "0.35em") // Вертикальное выравнивание
                .text(formattedTime) // Отображение отформатированного времени
                .style("font-size", "20px") // Установка размера шрифта
                .style("fill", "#F5DEB3"); // Цвет текста
        },

        updateLegend() {
            const width = window.innerWidth;
            const height = window.innerHeight;
            const numColumns = 2; // Количество колонок для легенды

            const legend = this.svg.selectAll(".legend").data(this.data, (d) => d.name);

            legend.exit().remove();

            const legendEnter = legend.enter().append("g").attr("class", "legend");

            legendEnter
                .append("rect")
                .attr("width", 18)
                .attr("height", 18)
                .attr("rx", 4) // Закругление углов прямоугольника
                .attr("ry", 4);

            legendEnter.append("text").attr("x", 24).attr("y", 9).attr("dy", "0.35em");

            // Обновляем цвета для всех элементов легенды после слияния
            legend
                .merge(legendEnter)
                .select("rect")
                .attr("fill", (d) => this.color(d.name)); // Обновление цвета для прямоугольника

            legend
                .merge(legendEnter)
                .select("text")
                .attr("fill", (d) => this.color(d.name)) // Обновление цвета текста
                .text((d) => `${d.name}: ${this.formatTime(d.total)}`); // Обновление текста

            // Обновляем позицию элементов легенды
            legend.merge(legendEnter).attr("transform", (d, i) => {
                const col = i % numColumns;
                const row = Math.floor(i / numColumns);
                const x = -width / 2 + 20 + col * (width / numColumns);
                const y = height / 10 + row * 20 + 20;
                return `translate(${x}, ${y})`;
            });
        },
    },

    mounted() {
        this.createDonutChart();
    },
};
</script>
