<template>
    <div class="absolute top-14 left-0 z-50 w-full h-[calc(100vh-56px)] bg-sky-200 p-4">
        <div class="w-full mb-4">Моя цель</div>

        <div class="justify-center items-center">
            <div v-for="g in goal.vals" :key="g" class="mb-1 bg-white/50 p-1">{{ g.name }}: {{ g.text }}</div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";

export default {
    name: "GoalsPage",

    computed: {
        ...mapState(useMainStore, ["s", "setPopup"]),
        ...mapState(useUserStore, ["goal", "getGoal"]),
    },

    mounted() {
        this.getGoal();
    },
};
</script>
