<template>
    <div v-if="view != 'pickCat'" class="w-full h-[200px] relative" ref="slider">
        <div class="absolute top-1/2 -translate-y-1/2 h-1 w-full bg-orange-200/30"></div>

        <div
            v-for="(time, index) in ts"
            :key="index"
            class="absolute top-1/2 -translate-y-1/2 pointer-events-auto"
            :style="{ left: `calc(${getPercentage(time.s)}% )` }"
        >
            <div class="absolute left-1/2 -translate-x-1/2 bottom-6 text-sm flex flex-col justify-center items-center gap-4">
                <span class="text-orange-200" @click="delTime(time.i)"><IconDelete :size="4" /></span>
                <span>{{ moment(time.s).format("HH:mm:ss") }}</span>
            </div>
        </div>

        <div
            v-for="(time, index) in ts"
            :key="index"
            class="absolute top-1/2 -translate-y-1/2 cursor-pointer pointer-events-auto"
            @mousedown.prevent="startDrag(time, $event)"
            @touchstart.prevent="startDrag(time, $event)"
            :style="{ left: `calc(${getPercentage(time.s)}% )` }"
        >
            <div class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-5 h-5 bg-orange-200 rounded-full"></div>
            <div class="absolute left-1/2 -translate-x-1/2 mt-6 text-sm">{{ time.name }}</div>
        </div>

        <div
            v-for="(button, index) in buttonPositions"
            :key="'button-' + index"
            class="absolute top-1/2 -translate-y-1/2"
            :style="{ left: `calc(${button.percentage}% - 12px)` }"
        >
            <div
                class="w-5 h-5 border border-dashed border-orange-200/50 text-orange-200/50 flex justify-center items-center rounded-full"
                @click="onButtonClick(button.time)"
            >
                +
            </div>
        </div>
    </div>

    <div v-if="view == 'pickCat'" class="absolute top-0 left-0 z-40 w-full h-full flex flex-col justify-center items-center">
        <div v-for="item in cats" :key="item">
            <div class="mb-px p-1 text-sm text-white/80" @click="pickCat(item.id)">{{ item.name }}</div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useTimeStore } from "@/store/time";
import moment from "moment";

function throttle(func, limit) {
    let lastFunc;
    let lastRan;
    return function () {
        const context = this;
        const args = arguments;
        if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(function () {
                if (Date.now() - lastRan >= limit) {
                    func.apply(context, args);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    };
}

export default {
    name: "SliderComponent",

    data() {
        return {
            dragTime: null,
            moment: moment,
            add: {}, // для добавления нового time
            view: null,
            buttonPositions: [],
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "setLang", "haptic"]),
        ...mapState(useTimeStore, ["cats", "times", "time", "reTime", "addTime", "delTime"]),

        // выбрать время для редактирования
        ts() {
            if (!this.times || !this.time) return [];

            // Создаём глубокую копию массива times
            const ts = JSON.parse(JSON.stringify(this.times));

            const index = ts.findIndex((time) => time.i === this.time.i);
            if (index === -1) return [];

            const startIndex = Math.max(0, index - 1);
            const endIndex = Math.min(ts.length, index + 2);

            const selectedTimes = ts.slice(startIndex, endIndex);

            return selectedTimes.map((time) => {
                const category = this.cats.find((cat) => cat.id === time.id);
                time.name = category.name;
                return time;
            });
        },

        minValue() {
            return this.ts.length > 0 ? this.ts[0].s : 0;
        },

        maxValue() {
            return this.ts.length > 0 ? this.ts[this.ts.length - 1].s : 0;
        },

        getPercentage() {
            return (timestamp) => {
                if (this.maxValue === this.minValue) return 0;
                return ((timestamp - this.minValue) / (this.maxValue - this.minValue)) * 100;
            };
        },
    },

    watch: {
        ts: {
            handler() {
                this.updateButtonPositions();
            },
            deep: true,
            immediate: true,
        },
    },

    methods: {
        startDrag(time, event) {
            // console.log("Начало перетаскивания", time);
            event.preventDefault();
            this.dragTime = time;

            // Добавляем обработчики событий
            document.addEventListener("mousemove", this.onDragThrottled);
            document.addEventListener("mouseup", this.stopDrag);
            document.addEventListener("touchmove", this.onDragThrottled, { passive: false });
            document.addEventListener("touchend", this.stopDrag, { passive: false });
        },

        onDrag(event) {
            if (this.dragTime == null) return;
            event.preventDefault();
            const point = event.touches ? event.touches[0] : event;
            const slider = this.$refs.slider;
            const rect = slider.getBoundingClientRect();
            const currentX = point.clientX - rect.left; // текущее положение мыши относительно левой стороны слайдера

            // Определение допустимых границ перемещения
            const index = this.times.findIndex((time) => time.i == this.dragTime.i);
            let minRange = index > 0 ? this.times[index - 1].s : this.minValue;
            let maxRange = index < this.times.length - 1 ? this.times[index + 1].s : this.maxValue;

            // Вычисляем новое время на основе процентного изменения
            const timeOffset = (currentX / rect.width) * (maxRange - minRange);
            let newValue = Math.round(minRange + timeOffset);
            newValue = Math.max(minRange, Math.min(maxRange, newValue));
            // console.log("Старое время", this.dragTime.s);
            // console.log("Новое время", newValue);

            // Применяем новое значение, учитывая ограничения
            this.reTime({ i: this.dragTime.i, s: newValue });
        },

        stopDrag() {
            // console.log("Окончание перетаскивания для", this.dragTime);
            this.dragTime = null;
            document.removeEventListener("mousemove", this.onDragThrottled);
            document.removeEventListener("mouseup", this.stopDrag);
            document.removeEventListener("touchmove", this.onDragThrottled, { passive: false });
            document.removeEventListener("touchend", this.stopDrag, { passive: false });
        },

        onButtonClick(time) {
            this.add.s = time;
            this.view = "pickCat";
        },

        async pickCat(id) {
            this.add.cat_id = id;
            this.view = "";
            await this.addTime(this.add);
            this.updateButtonPositions();
        },

        updateButtonPositions() {
            if (!this.$refs.slider) return [];

            const rect = this.$refs.slider.getBoundingClientRect();
            if (this.ts.length < 2) return [];

            const positions = [];
            for (let i = 0; i < this.ts.length - 1; i++) {
                const midPoint = (this.ts[i].s + this.ts[i + 1].s) / 2;
                const percentage = this.getPercentage(midPoint);
                const distance = (Math.abs(this.getPercentage(this.ts[i].s) - this.getPercentage(this.ts[i + 1].s)) * rect.width) / 100;

                if (distance > 100) positions.push({ percentage, time: midPoint });
            }

            this.buttonPositions = positions;
        },
    },

    mounted() {
        this.onDragThrottled = throttle(this.onDrag, 100);
        this.updateButtonPositions();
    },
};
</script>
