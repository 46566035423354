<template>
    <div class="w-full h-screen items-center py-12 bg-black bg-gradient-to-tl from-cyan-600/20 via-cyan-100/0 to-cyan-600/20 overflow-hidden">
        <div v-if="s.popup != 'start'" class="absolute top-0 z-40 w-full h-12 flex items-center overflow-hidden">
            <div v-if="student" class="grid grid-cols-4 w-full p-2 !text-cyan-500">
                <!-- <div class="flex items-center gap-2 text-white">
                    <IconPack name="logo-predictor" :size="8" />
                </div> -->

                <div class="flex items-center gap-2 font-bold">
                    <IconEnergy />
                    <div class="text-lg">{{ student.energy ?? 0 }}</div>
                </div>

                <div></div>

                <div class="flex justify-end items-center gap-2 font-bold">
                    <IconBoxTime />
                    <div class="text-lg">{{ student.staking?.sum }}</div>
                </div>

                <div class="flex justify-end items-center gap-2 font-bold">
                    <IconCoin />
                    <div class="text-lg">{{ point.toLocaleString() }}</div>
                </div>
            </div>
        </div>

        <div class="w-full h-[calc(100vh-96px)] text-white relative z-10">
            <template v-if="s.popup == 'menu'"><Menu /></template>
            <template v-if="s.popup == 'start'"><Start /></template>
            <template v-if="s.popup == 'referral'"><Raferral /></template>
            <template v-if="s.popup == 'choose'"><Choose /></template>
            <template v-if="s.popup == 'modules'"><Modules /></template>
            <template v-if="s.popup == 'tasks'"><Tasks /></template>
            <template v-if="s.popup == 'graf'"><Graf /></template>
            <template v-if="s.popup == 'staking'"><Staking /></template>
            <template v-if="s.popup == 'profile'"><Profile /></template>
        </div>

        <div
            @click="haptic()"
            v-if="s.popup != 'start1'"
            class="absolute bottom-8 left-8 right-8 z-40 h-12 grid grid-cols-5 bg-cyan-600/20 text-white/80 backdrop-blur-sm overflow-hidden rounded-lg active:scale-95 transition-transform duration-500"
        >
            <div class="flex justify-center items-center icon" :class="{ '!text-cyan-500': s.popup == 'start' }" @click="setPopup('start')">
                <IconPlay />
            </div>
            <div class="flex justify-center items-center icon" :class="{ '!text-cyan-500': s.popup == 'referral' }" @click="setPopup('referral')">
                <IconUser />
            </div>
            <div class="flex justify-center items-center icon" :class="{ '!text-cyan-500': s.popup == 'raiting' }" @click="setPopup('raiting')">
                <IconStar />
            </div>
            <div class="flex justify-center items-center icon" :class="{ '!text-cyan-500': s.popup == 'staking' }" @click="setPopup('staking')">
                <IconBoxTime />
            </div>
            <div class="flex justify-center items-center icon" :class="{ '!text-cyan-500': s.popup == 'profile' }" @click="setPopup('profile')">
                <IconProfile />
            </div>

            <!-- <div class="flex justify-center items-center" @click="setPopup('profile')">
                <img
                    v-if="!student?.picture"
                    class="w-8 h-8 my-2 shrink-0 object-cover rounded-full"
                    :src="'https://yii.botteach.ru/users/orig/' + student?.picture"
                />
                <div v-else class="flex justify-center items-center rounded-full w-8 h-8 my-2 shrink-0 bg-cyan-600 text-white">
                    {{ student?.name?.slice(0, 1) }}
                </div>
            </div> -->
        </div>

        <div class="absolute top-0 left-0 z-1 w-full h-screen"><StarsBackground /></div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";
import { usePredictorStore } from "@/store/predictor";
import moment from "moment";

import Menu from "./Menu.vue";
import Start from "./Start.vue";
import Profile from "./Profile.vue";
import Staking from "./Staking.vue";
import Raferral from "./Raferral.vue";
import Choose from "./Choose.vue";
import Modules from "./Modules.vue";
import Tasks from "./Tasks.vue";
import Graf from "./Graf.vue";

import StarsBackground from "../components/Backgrounds/Stars.vue";

export default {
    name: "MainPage",

    components: { Menu, Start, Profile, Staking, Raferral, Choose, Modules, Tasks, Graf, StarsBackground },

    data() {
        return {
            moment: moment,
            telegram: null,
            bot_id: 9,
            point: 0,
        };
    },

    watch: {
        "student.point": {
            handler(newPoint, oldPoint) {
                if (newPoint !== oldPoint) this.animatePoint(oldPoint, newPoint);
            },
            deep: true,
        },
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup", "setSettings", "setView", "haptic"]),
        ...mapState(useUserStore, ["student", "sendApp"]),
        ...mapState(usePredictorStore, ["coins", "coin", "getZip"]),
    },

    methods: {
        initTelegram() {
            this.telegram = window.Telegram.WebApp;
            if (this.telegram && this.telegram.initDataUnsafe) {
                this.telegram.expand();
                this.telegram.disableVerticalSwipes();

                this.sendApp({ bot_id: this.bot_id, fid: this.telegram.initDataUnsafe.user?.id });
            }
        },

        animatePoint(start, end) {
            if (parseInt(start) < 1 || start == undefined) start = 0;
            const step = (end - start) / 10;
            let current = start;
            const interval = setInterval(() => {
                current += step;
                if ((step > 0 && current >= end) || (step < 0 && current <= end)) {
                    current = end;
                    clearInterval(interval);
                }
                this.point = Math.round(current);
            }, 100);
        },
    },

    mounted() {
        this.getZip();

        // if (!this.s.popup || this.s.popup == "start") this.setPopup("p:start");
        if (!this.s.popup) this.setPopup("start");

        if (window && window?.Telegram && window.Telegram?.WebApp) {
            this.initTelegram();
        } else {
            // Load the Telegram WebApp script dynamically
            const scriptElement = document.createElement("script");
            scriptElement.src = "https://telegram.org/js/telegram-web-app.js";
            scriptElement.async = true;

            scriptElement.onload = () => {
                if (window.Telegram?.WebApp) this.initTelegram();
            };

            const firstChild = document.head.firstChild;
            document.head.insertBefore(scriptElement, firstChild);
        }
    },
};
</script>

<style>
.icon path {
    stroke-dashoffset: 0;
    stroke-dasharray: 100;
}
.icon:active path {
    animation: draw 1s linear forwards;
}

@keyframes draw {
    0% {
        stroke-dashoffset: 100;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
</style>
