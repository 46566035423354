import { createRouter, createWebHistory } from "vue-router";

import PageProfile from "@/Main/Profile";

import Art from "@/Art/Main";
import Goal from "@/Goal/Main";
import BullBear from "@/BullBear/Main";
import Predictor from "@/Predictor/Main";
import Time from "@/Time/Main";
import Math from "@/Math/Main";
import Flash from "@/Flash/Main";

const routes = [
    {
        path: "/art",
        name: "Art",
        component: Art,
    },
    {
        path: "/goal",
        name: "Goal",
        component: Goal,
    },
    {
        path: "/bull",
        name: "BullBear",
        component: BullBear,
    },
    {
        path: "/predictor",
        name: "Predictor",
        component: Predictor,
    },
    {
        path: "/time",
        name: "Time",
        component: Time,
    },
    {
        path: "/math",
        name: "Math",
        component: Math,
    },
    {
        path: "/flash",
        name: "Flash",
        component: Flash,
    },
    {
        path: "/profile",
        name: "PageProfile",
        component: PageProfile,
        meta: { title: "Профиль" },
    },
];

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to) => {
    document.title = to.meta.title || "BotTeachMe";
});

export default router;
