<template>
    <div class="p-4">
        <div class="text-center text-5xl pt-10 mb-10">{{ timer }}</div>

        <div class="flex flex-wrap justify-center items-center gap-2">
            <div
                v-for="cat in total"
                :key="cat.id"
                @click="haptic(), startTimer(cat.id)"
                class="flex flex-col justify-center items-center w-fit py-2 px-6 text-sm bg-orange-200/20 text-white/50 rounded-full backdrop-blur-sm"
                :class="{ '!bg-orange-200/50 !text-white': currentCat == cat.id }"
            >
                {{ cat.name }}
                <!-- <div class="text-xs">{{ formatTime(cat.total) }}</div> -->
            </div>
        </div>
    </div>

    <!-- <div class="absolute top-8 left-0 z-20 w-full flex justify-between items-center px-8">
        <div
            v-for="item in alarms"
            :key="item"
            @click="haptic(), remind(item)"
            class="flex justify-center items-center w-10 h-10 text-sm text-orange-200/30 rounded-full relative"
            :class="{ '!text-orange-200/80': s?.alarm?.time == item }"
        >
            <span class="absolute -top-1"> <IconAlarmMini :size="11" /></span>
            {{ item }}
        </div>
    </div> -->
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";
import { useTimeStore } from "@/store/time";

export default {
    name: "StartPage",

    data() {
        return {
            interval: null,
            timer: null,
            alarms: [5, 10, 15, 30, 45, 60],
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "haptic"]),
        ...mapState(useUserStore, ["student"]),
        ...mapState(useTimeStore, ["cats", "times", "startTimer", "remind"]),

        total() {
            const totals = this.cats.map((cat) => ({ ...cat, total: 0 }));

            // Перебор временных записей и суммирование времени для соответствующих категорий
            this.times.forEach((time) => {
                const category = totals.find((cat) => cat.id == time.id);
                if (category) category.total += parseInt(time.t); // Добавляем время к найденной категории
            });

            // время у текущей катеории, если сейчас идет таймер
            let lastTime = this.times[this.times.length - 1];
            if (lastTime?.id && lastTime.t == 0) {
                let category = totals.find((cat) => cat.id == lastTime.id);
                category.total += parseInt((Date.now() - lastTime.s) / 1000);
            }

            return totals;
        },

        currentCat() {
            if (!this.times.length > 0) return null;
            return this.times[this.times.length - 1].id;
        },
    },

    methods: {
        updateTime() {
            if (this.times.length > 0) {
                const now = Date.now();
                const lastTime = this.times[this.times.length - 1];
                const elapsed = parseInt((now - lastTime.s) / 1000); // время в секундах

                // Обновляем таймер
                this.timer = this.formatTime(elapsed);

                // Обновляем общее время в активной категории
                const currentCategory = this.total.find((cat) => cat.id === lastTime.id);
                if (currentCategory) currentCategory.total += 1;
            }
        },

        formatTime(sec) {
            const s = Math.floor(sec);
            const m = Math.floor(s / 60);
            const h = Math.floor(m / 60);
            const S = (s % 60).toString().padStart(2, "0");
            const M = (m % 60).toString().padStart(2, "0");
            const H = (h % 24).toString().padStart(2, "0");

            if (H > 0) return `${H}:${M}:${S}`;
            if (M > 0) return `${M}:${S}`;
            return `${S}`;
        },
    },

    mounted() {
        this.updateTime();
        this.interval = setInterval(this.updateTime, 1000);
    },

    beforeUnmount() {
        clearInterval(this.interval);
    },
};
</script>
