<template>
    <div class="w-full h-screen items-center pb-12 bg-black bg-gradient-to-tl from-orange-600/10 via-orange-100/0 to-orange-600/10 overflow-hidden">
        <div class="w-full text-white relative z-10">
            <template v-if="s.popup == 't:start'"><Start /></template>
            <template v-if="s.popup == 't:category'"><Category /></template>
            <template v-if="s.popup == 't:stat'"><Stat /></template>
            <!-- <template v-if="s.popup == 't:profile'"><Profile /></template> -->
            <!-- <div class="circle-container">
                <div class="circle circle1"></div>
                <div class="circle circle2"></div>
                <div class="circle circle3"></div>
                <div class="circle circle4"></div>
                <div class="circle circle5"></div>
            </div> -->
        </div>
        <template v-if="s.popup == 't:start'"><Alarm /></template>

        <div
            @click="haptic()"
            v-if="s.popup != 'start1'"
            class="absolute bottom-4 left-8 right-8 z-30 h-12 grid grid-cols-3 bg-orange-200/20 text-white/50 backdrop-blur-sm overflow-hidden rounded-lg active:scale-95 transition-transform duration-500"
        >
            <div class="flex justify-center items-center icon" :class="{ '!text-orange-200': s.popup == 't:start' }" @click="setPopup('t:start')">
                <IconHourGlass />
            </div>
            <!-- <div
                class="flex justify-center items-center icon"
                :class="{ '!text-orange-200': s.popup == 't:category' }"
                @click="setPopup('t:category')"
            >
                <IconPlus />
            </div> -->
            <div
                class="flex justify-center items-center icon"
                :class="{ '!text-orange-200': s.popup == 't:category' }"
                @click="setPopup('t:category')"
            >
                <IconText />
            </div>

            <div class="flex justify-center items-center icon" :class="{ '!text-orange-200': s.popup == 't:stat' }" @click="setPopup('t:stat')">
                <IconGraf />
            </div>

            <!-- <div class="flex justify-center items-center icon" :class="{ '!text-orange-200': s.popup == 't:profile' }" @click="setPopup('t:profile')">
                <IconProfile />
            </div> -->
        </div>

        <div class="absolute top-0 left-0 z-1 w-full h-screen"><DotsBackground /></div>
    </div>

    <!-- <div class="absolute bottom-0 right-0 z-2 w-full text-right text-xs text-white/30">
        <div v-for="item in log" :key="item" class="p-px">{{ item }}</div>
    </div> -->
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";
import { useTimeStore } from "@/store/time";
import moment from "moment";

import Start from "./Start.vue";
import Alarm from "./Alarm.vue";
import Category from "./Category.vue";
import Stat from "./Stat.vue";
// import Profile from "./Profile.vue";

import DotsBackground from "../components/Backgrounds/SandMatter.vue";

export default {
    name: "MainPage",

    components: { Start, Alarm, Category, Stat, DotsBackground },

    data() {
        return {
            moment: moment,
            telegram: null,
            bot_id: 145,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "loadS", "setPopup", "closePopup", "setSettings", "setView", "setLang", "haptic"]),
        ...mapState(useUserStore, ["student", "sendApp"]),
        ...mapState(useTimeStore, ["log", "loadData"]),
    },

    methods: {
        initTelegram() {
            this.telegram = window.Telegram.WebApp;
            if (this.telegram && this.telegram.initDataUnsafe) {
                const lang = this.telegram.initDataUnsafe.user?.language_code;
                this.setLang(lang);

                this.telegram.expand();
                this.sendApp({ bot_id: this.bot_id, fid: this.telegram.initDataUnsafe.user?.id });
            }
        },

        animatePoint(start, end) {
            if (parseInt(start) < 1 || start == undefined) start = 0;
            const step = (end - start) / 10;
            let current = start;
            const interval = setInterval(() => {
                current += step;
                if ((step > 0 && current >= end) || (step < 0 && current <= end)) {
                    current = end;
                    clearInterval(interval);
                }
                this.point = Math.round(current);
            }, 100);
        },
    },

    mounted() {
        if (window && window?.Telegram && window.Telegram?.WebApp) {
            this.initTelegram();
        } else {
            // Load the Telegram WebApp script dynamically
            const scriptElement = document.createElement("script");
            scriptElement.src = "https://telegram.org/js/telegram-web-app.js";
            scriptElement.async = true;

            scriptElement.onload = () => {
                if (window.Telegram?.WebApp) this.initTelegram();
            };

            const firstChild = document.head.firstChild;
            document.head.insertBefore(scriptElement, firstChild);
        }

        this.loadS();
        this.loadData();

        if (!this.s.popup) this.setPopup("t:start");
    },
};
</script>

<style>
.icon path {
    stroke-dashoffset: 0;
    stroke-dasharray: 100;
}
.icon:active path {
    animation: draw 1s linear forwards;
}

@keyframes draw {
    0% {
        stroke-dashoffset: 100;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

.circle-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
}
.circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.circle1 {
    background-color: #dec99b;
}
.circle2 {
    background-color: #dfc284;
}
.circle3 {
    background-color: #b7a783;
}
.circle4 {
    background-color: #d3b27a;
}
.circle5 {
    background-color: #cdb686;
}
</style>
