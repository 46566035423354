<template>
    <div class="absolute top-14 left-0 z-50 w-full h-[calc(100vh-56px)] bg-sky-200 p-4">
        <div class="flex flex-col justify-center items-center text-xl space-y-4">
            <div>Main Menu</div>
            <div @click="setPopup('auction')">Аукцион</div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";

export default {
    name: "MainMenu",

    computed: {
        ...mapState(useMainStore, ["s", "setPopup"]),
    },
};
</script>
