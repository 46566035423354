<template>
    <div class="absolute bottom-28 left-0 z-20 w-full flex justify-between items-center px-8">
        <div
            v-for="item in alarms"
            :key="item"
            @click="haptic(), remind(item)"
            class="flex justify-center items-center w-10 h-10 text-sm text-orange-200/30 rounded-full relative"
            :class="{ '!text-orange-200/80': s?.alarm?.time == item }"
        >
            <span class="absolute -top-1"> <IconAlarmMini :size="11" /></span>
            {{ item }}
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useTimeStore } from "@/store/time";

export default {
    name: "AlarmPage",

    data() {
        return {
            alarms: [5, 10, 15, 30, 45, 60],
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "haptic"]),
        ...mapState(useTimeStore, ["cats", "times", "startTimer", "remind"]),
    },
};
</script>
