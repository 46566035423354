<template>
    <div class="p-4">
        <div class="text-xl font-black mb-2">Choose Your Strength!</div>

        <div class="w-full grid grid-cols-3 gap-4 justify-center items-center bg-transparent relative z-20">
            <div
                v-for="item in coins"
                :key="item.id"
                @click="setCoin(item)"
                class="backdrop-blur-sm flex justify-center items-center bg-cyan-600/20 border border-cyan-600/50 rounded p-8"
            >
                <img class="w-full aspect-square object-contain" :src="require('@/assets/images/' + item.pic)" :alt="item.name" />
            </div>
        </div>

        <div class="absolute top-28 left-0 z-1 w-screen h-screen text-cyan-300">
            <svg
                id="my-svg"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 1440 2560"
                xml:space="preserve"
                fill="none"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
            >
                <path d="M0,783.6h388.8c54.7,0,99.1,44.4,99.1,99.1v304.1" />
                <path d="M1337.4,2559.4V1375.7c-0.2-51.4-44.6-95.7-99.3-95.7h-145.4" />
                <path d="M266.7,2560v-561.5c0-75.9,61.5-137.4,137.4-137.4h358c130.6,0,236.5-105.9,236.5-236.5v-251.4" />
                <path
                    d="M999.5,1373.2H440.5c-51.5,0-93.2-41.7-93.2-93.2v0c0-51.5,41.7-93.2,93.2-93.2h559.1 c51.5,0,93.2,41.7,93.2,93.2v0C1092.7,1331.5,1051,1373.2,999.5,1373.2z"
                />
            </svg>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { usePredictorStore } from "@/store/predictor";

import Vivus from "vivus";

export default {
    name: "AuctionPage",

    data() {
        return {
            vivusInstance: null,
            list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "auction", "setPopup"]),
        ...mapState(usePredictorStore, ["coins", "setCoin"]),
    },

    methods: {
        animateSvg() {
            this.vivusInstance = new Vivus("my-svg", { duration: 200 }, this.onReady.bind(this));
        },
        onReady() {
            setTimeout(() => {
                this.vivusInstance.reset();
                this.vivusInstance.play(this.onReady.bind(this));
            }, 1000);
        },
    },

    mounted() {
        this.animateSvg();
    },
};
</script>
