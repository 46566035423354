<template>
    <div class="p-4">
        <div
            v-if="student?.name"
            class="flex flex-col items-center gap-4 mb-4 p-8 bg-cyan-600/30 border border-cyan-600/50 text-base rounded backdrop-blur-sm"
        >
            <img
                v-if="student?.picture"
                class="w-40 h-40 my-2 shrink-0 object-cover rounded-full border border-cyan-600/50"
                :src="'https://yii.botteach.ru/users/orig/' + student?.picture"
            />
            <div v-else class="flex justify-center items-center rounded-full w-8 h-8 my-2 shrink-0 bg-cyan-600 text-cyan-500">
                {{ student?.name?.slice(0, 1) }}
            </div>

            <div class="text-xl">{{ student.name }} {{ student.secondname }}</div>
            <div class="flex items-center gap-2 text-3xl text-cyan-400 font-bold"><Coin /> {{ student.point?.toLocaleString() }}</div>

            {{ student.module_name }}

            <div class="w-1/2 m-auto grid grid-cols-2 items-center text-center">
                <div class="flex flex-col text-cyan-400 font-bold">
                    <span class="text-xs">right</span>
                    <span class="text-xl">{{ student.yes }}</span>
                </div>
                <div class="flex flex-col text-red-400 font-bold">
                    <span class="text-xs">wrong</span>
                    <span class="text-xl">{{ student.no }}</span>
                </div>
            </div>

            <!-- {{ student.create_at }} -->
            <!-- {{ student }} -->
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";

import Coin from "../components/Coin.vue";

export default {
    name: "ProfilePage",

    components: { Coin },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup"]),
        ...mapState(useUserStore, ["student"]),
    },

    methods: {},

    mounted() {},
};
</script>
