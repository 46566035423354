<template>
    <div class="px-4 h-screen overflow-hidden">
        <div class="h-[calc(100vh-124px)] bg-white text-black p-4 rounded-lg relative">
            <h2 class="w-full text-center text-3xl font-bold mb-8">Настройки</h2>

            <!-- <div class="w-full grid grid-cols-4 gap-2 mb-8 text-slate-400">
                    <div class="bg-slate-200 rounded p-1" :class="{ '!bg-sky-400 text-white': all.game == 'plus' }">Сложение</div>
                    <div class="bg-slate-200 rounded p-1" :class="{ '!bg-sky-400 text-white': all.game == 'mins' }">Вычитание</div>
                    <div class="bg-slate-200 rounded p-1" :class="{ '!bg-sky-400 text-white': all.game == 'mult' }">Умножение</div>
                    <div class="bg-slate-200 rounded p-1" :class="{ '!bg-sky-400 text-white': all.game == 'dvde' }">Деление</div>
                </div> -->

            <div class="w-full mb-4">
                <div class="text-slate-500 text-sm mb-2">Число для примеров</div>

                <div class="flex justify-center items-center gap-2 mb-8">
                    <button
                        v-for="n in 10"
                        :key="n"
                        @click="toggleModule(n)"
                        class="flex justify-center items-center w-8 h-8 rounded-full"
                        :class="{ 'bg-sky-400 text-white': all.modules.includes(n), 'bg-slate-200 text-slate-400': !all.modules.includes(n) }"
                    >
                        {{ n }}
                    </button>
                </div>
            </div>

            <!-- <div class="w-full mb-4">
                <div class="text-slate-500 text-sm mb-2">Секунд на одно задание</div>
                <input
                    type="text"
                    class="w-full bg-slate-100 border border-slate-200 py-2 px-4 rounded"
                    @change="setTimeOne(all.timeOne)"
                    v-model="all.timeOne"
                />
            </div> -->

            <div class="w-full mb-4">
                <div class="text-slate-500 text-sm mb-2">Секунд на одно задание</div>
                <input type="range" min="1" max="60" class="w-full custom-slider" @input="setTimeOne(all.timeOne)" v-model.number="all.timeOne" />
                <div class="text-center mt-2 text-slate-500">{{ all.timeOne }} секунд</div>
            </div>

            <!-- {{ all }} -->
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useMathStore } from "@/store/math";

export default {
    name: "SettingPage",

    data() {
        return {};
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "haptic"]),
        ...mapState(useMathStore, ["all", "toggleModule", "setTimeOne"]),
    },

    methods: {},
};
</script>
<!-- 
<style>
.custom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #38bdf8;
    cursor: pointer;
    border: 2px solid #0ea5e9;
}

.custom-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #38bdf8;
    cursor: pointer;
    border: 2px solid #0ea5e9;
}

.custom-slider::-webkit-slider-runnable-track {
    width: 100%;
    margin-top: 8px;
    height: 4px;
    cursor: pointer;
    background: #0ea5e9;
    border-radius: 2px;
}

.custom-slider::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: #0ea5e9;
    border-radius: 2px;
}

.custom-slider {
    -webkit-appearance: none;
    width: 100%;
    margin: 13.8px 0;
    background: transparent;
}
</style> -->
