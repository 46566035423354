<template>
    <svg :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`">
        <line
            v-for="(line, index) in lines"
            :key="index"
            :x1="line.x1"
            :y1="line.y1"
            :x2="line.x2"
            :y2="line.y2"
            :stroke="line.color"
            stroke-opacity="0.25"
        ></line>

        <circle v-for="(point, index) in points" :key="index" :cx="point.x" :cy="point.y" :r="2" :fill="point.color"></circle>
    </svg>
</template>

<script>
export default {
    name: "ParticleBackground",

    data() {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
            points: [],
            lines: [],
        };
    },

    methods: {
        createPoints() {
            for (let i = 0; i < 25; i++) {
                // Количество точек "bear" и "bull"
                this.points.push({
                    x: Math.random() * this.width,
                    y: Math.random() * this.height,
                    dx: (Math.random() * 2 - 1) * 0.2, // скорость
                    dy: (Math.random() * 2 - 1) * 0.2, // скорость
                    color: i < 12 ? "red" : "cyan", // Делим точки на две группы по цвету
                });
            }
        },

        connectPoints() {
            this.lines = [];
            for (let i = 0; i < this.points.length; i++) {
                for (let j = i + 1; j < this.points.length; j++) {
                    const distance = Math.sqrt(Math.pow(this.points[i].x - this.points[j].x, 2) + Math.pow(this.points[i].y - this.points[j].y, 2));
                    if (distance < 100 && this.points[i].color === this.points[j].color) {
                        this.lines.push({
                            x1: this.points[i].x,
                            y1: this.points[i].y,
                            x2: this.points[j].x,
                            y2: this.points[j].y,
                            color: this.points[i].color === "cyan" ? "#06b6d4" : "#f43f5e",
                        });
                    }
                }
            }
        },

        animatePoints() {
            const animate = () => {
                this.points = this.points.map((point) => {
                    let x = point.x + point.dx;
                    let y = point.y + point.dy;

                    if (x > this.width || x < 0) {
                        point.dx *= -1;
                        x = point.x + point.dx;
                    }
                    if (y > this.height || y < 0) {
                        point.dy *= -1;
                        y = point.y + point.dy;
                    }

                    return { ...point, x, y };
                });

                this.connectPoints();

                requestAnimationFrame(animate);
            };

            requestAnimationFrame(animate);
        },
    },

    mounted() {
        this.createPoints();
        this.connectPoints();
        this.animatePoints();
    },
};
</script>
