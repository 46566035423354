<template>
    <div class="absolute top-14 left-0 z-50 w-full h-[calc(100vh-56px)] bg-sky-200 p-4">
        <div class="w-full mb-4">Аукцион</div>

        <div class="grid grid-cols-2 gap-4 justify-center items-center">
            <div v-for="pic in auction" :key="pic">
                <img class="w-full h-full object-cover" :src="'https://yii.botteach.ru/uploads/141/main/' + pic?.name" @click="picAuction(pic)" />
            </div>
        </div>

        <!-- {{ auction }} -->
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";

export default {
    name: "AuctionPage",

    computed: {
        ...mapState(useMainStore, ["s", "auction", "setPopup"]),
        ...mapState(useUserStore, ["getAuction", "picAuction"]),
    },

    mounted() {
        this.getAuction(141);
    },
};
</script>
