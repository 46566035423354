import { defineStore } from "pinia";
import API from "../services/api";
import { useMainStore as mainStore } from "./main";
import { useUserStore as userStore } from "./user";
import moment from "moment";
// import pako from "pako";

export const usePredictorStore = defineStore("predictor", {
    state: () => ({
        // coins: [
        //     { id: 1, pic: "bitcoin.png", name: "bitcoin" },
        //     { id: 2, pic: "ethereum.png", name: "ethereum" },
        //     { id: 3, pic: "bnb.png", name: "bnb" },
        //     { id: 4, pic: "aptos.png", name: "aptos" },
        //     { id: 5, pic: "toncoin.png", name: "toncoin" },
        //     { id: 6, pic: "solana.png", name: "solana" },
        // { id: 7, pic: "tron.png", name: "tron" },
        // { id: 8, pic: "xrp.png", name: "xrp" },
        // { id: 9, pic: "polkadot.png", name: "polkadot" },
        // ],
        coin: {},
        trainings: {},
        training: {},
        staking: 0.35,

        answ: [], // массив mid на которые уже ответил
        answers: [], // полные ответы

        learn: [
            {
                bot_id: 9,
                group_id: 517,
                mid: "001",
                name: "Изучим МОЛОТ",
                message: "Изучим МОЛОТ",
                type_id: 1,
                module_id: 1,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                bot_id: 9,
                group_id: 517,
                mid: "P(5",
                name: "bitcoin: hummer",
                message: [
                    [1719086400, 64246, 64303, 64295, 64284],
                    [1719090000, 64267, 64330, 64284, 64330],
                    [1719093600, 64272, 64330, 64330, 64316],
                    [1719097200, 64260, 64328, 64316, 64262],
                    [1719100800, 64262, 64520, 64262, 64476],
                    [1719104400, 64329, 64521, 64476, 64476],
                    [1719108000, 64370, 64507, 64476, 64507],
                    [1719111600, 64416, 64520, 64507, 64416],
                    [1719115200, 64375, 64428, 64416, 64378],
                    [1719118800, 64349, 64420, 64378, 64380],
                    [1719122400, 64380, 64414, 64380, 64387],
                    [1719126000, 64376, 64433, 64387, 64415],
                    [1719129600, 64384, 64436, 64415, 64395],
                    [1719133200, 64395, 64437, 64395, 64419],
                    [1719136800, 64281, 64419, 64419, 64306],
                    [1719140400, 64253, 64341, 64306, 64341],
                    [1719144000, 64318, 64376, 64341, 64376],
                    [1719147600, 64254, 64429, 64376, 64298],
                    [1719151200, 64141, 64310, 64298, 64192],
                    [1719154800, 64049, 64200, 64192, 64094],
                    [1719158400, 63919, 64180, 64094, 63927],
                    [1719162000, 63927, 64179, 63927, 64120],
                    [1719165600, 64068, 64153, 64120, 64112],
                    [1719169200, 64094, 64188, 64112, 64150],
                    [1719172800, 63601, 64159, 64150, 63740],
                    [1719176400, 63536, 63860, 63740, 63845],
                    [1719180000, 63640, 63845, 63845, 63658],
                    [1719183600, 63178, 63700, 63658, 63210],
                    [1719187200, 62904, 63336, 63210, 63080],
                    [1719190800, 62920, 63348, 63080, 63316],
                    [1719194400, 62695, 63370, 63316, 62881],
                    [1719198000, 62768, 63077, 62881, 62862],
                    [1719201600, 62634, 62952, 62862, 62841],
                    [1719205200, 62258, 62882, 62841, 62314],
                    [1719208800, 62161, 62426, 62314, 62405],
                    [1719212400, 62177, 62596, 62405, 62456],
                    [1719216000, 62340, 62976, 62456, 62731],
                    [1719219600, 60567, 62867, 62731, 61402],
                    [1719223200, 61125, 61582, 61402, 61167],
                    [1719226800, 60836, 61383, 61167, 61292],
                    [1719230400, 61121, 61515, 61292, 61215],
                    [1719234000, 60916, 61650, 61215, 61569],
                    [1719237600, 61100, 61700, 61569, 61303],
                    [1719241200, 60670, 61368, 61303, 60844],
                    [1719244800, 60000, 61044, 60844, 60168],
                    [1719248400, 59859, 60548, 60168, 60245],
                    [1719252000, 59711, 60487, 60245, 60412],
                    [1719255600, 59058, 60478, 60412, 59307],
                    [1719259200, 58402, 59660, 59307, 59514],
                    [1719262800, 59411, 60119, 59514, 60119],
                ],
                type_id: 34,
                module_id: 1,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                bot_id: 9,
                group_id: 517,
                mid: "gIS",
                name: "bitcoin: hummer",
                message: [
                    [1713319200, 63694, 64295, 64018, 63840],
                    [1713322800, 63734, 64067, 63840, 63842],
                    [1713326400, 63672, 64248, 63842, 64101],
                    [1713330000, 63828, 64499, 64101, 63928],
                    [1713333600, 63735, 64000, 63928, 63881],
                    [1713337200, 63182, 63936, 63881, 63298],
                    [1713340800, 63150, 63588, 63298, 63326],
                    [1713344400, 63228, 63540, 63326, 63320],
                    [1713348000, 62752, 63339, 63320, 62898],
                    [1713351600, 62541, 63163, 62898, 62595],
                    [1713355200, 62061, 62836, 62595, 62318],
                    [1713358800, 62129, 62975, 62318, 62636],
                    [1713362400, 60741, 62658, 62636, 61280],
                    [1713366000, 59888, 61538, 61280, 60305],
                    [1713369600, 59678, 61100, 60305, 60195],
                    [1713373200, 60119, 61366, 60195, 61275],
                    [1713376800, 61159, 61956, 61275, 61446],
                    [1713380400, 60727, 61474, 61446, 61022],
                    [1713384000, 60777, 61458, 61022, 60847],
                    [1713387600, 60847, 61660, 60847, 61301],
                    [1713391200, 61160, 61719, 61301, 61690],
                    [1713394800, 61195, 61763, 61690, 61277],
                    [1713398400, 60940, 61526, 61277, 61515],
                    [1713402000, 61383, 61666, 61515, 61639],
                    [1713405600, 61458, 61920, 61639, 61666],
                    [1713409200, 61666, 62230, 61666, 61988],
                    [1713412800, 61556, 62075, 61988, 61640],
                    [1713416400, 60992, 61815, 61640, 61072],
                    [1713420000, 60803, 61320, 61072, 61148],
                    [1713423600, 60941, 61362, 61148, 61178],
                    [1713427200, 60864, 61550, 61178, 61336],
                    [1713430800, 61320, 61710, 61336, 61637],
                    [1713434400, 61500, 61926, 61637, 61822],
                    [1713438000, 61822, 63072, 61822, 62598],
                    [1713441600, 61581, 62730, 62598, 62233],
                    [1713445200, 61897, 62833, 62233, 62810],
                    [1713448800, 62565, 63971, 62810, 63817],
                    [1713452400, 63427, 64117, 63817, 63499],
                    [1713456000, 63282, 63866, 63499, 63537],
                    [1713459600, 62629, 63624, 63537, 62783],
                    [1713463200, 62310, 63219, 62783, 63105],
                    [1713466800, 63096, 63576, 63105, 63477],
                    [1713470400, 63272, 63638, 63477, 63491],
                    [1713474000, 63313, 63826, 63491, 63473],
                    [1713477600, 63163, 63652, 63473, 63500],
                    [1713481200, 63376, 63666, 63500, 63470],
                    [1713484800, 62655, 63470, 63470, 62711],
                    [1713488400, 60619, 62776, 62711, 61022],
                    [1713492000, 59600, 61390, 61022, 61360],
                    [1713495600, 61140, 62108, 61360, 61785],
                ],
                type_id: 34,
                module_id: 1,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                bot_id: 9,
                group_id: 517,
                mid: "002",
                name: "Это тоже МОЛОТ",
                message: "Это тоже МОЛОТ",
                type_id: 1,
                module_id: 1,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                bot_id: 9,
                group_id: 517,
                mid: "C~U",
                name: "bitcoin: hummer",
                message: [
                    [1720227600, 56208, 56612, 56601, 56220],
                    [1720231200, 56171, 56498, 56220, 56440],
                    [1720234800, 56268, 56520, 56440, 56410],
                    [1720238400, 56044, 56418, 56410, 56059],
                    [1720242000, 56018, 56490, 56059, 56363],
                    [1720245600, 56347, 56568, 56363, 56417],
                    [1720249200, 56401, 56684, 56417, 56606],
                    [1720252800, 56507, 56658, 56606, 56558],
                    [1720256400, 56437, 56780, 56558, 56766],
                    [1720260000, 56650, 56876, 56766, 56780],
                    [1720263600, 56660, 56800, 56780, 56731],
                    [1720267200, 56500, 56797, 56731, 56755],
                    [1720270800, 56660, 57299, 56755, 56991],
                    [1720274400, 56700, 57128, 56991, 57059],
                    [1720278000, 56928, 57441, 57059, 57318],
                    [1720281600, 57186, 58113, 57318, 57853],
                    [1720285200, 57747, 58032, 57853, 57965],
                    [1720288800, 57746, 58021, 57965, 57787],
                    [1720292400, 57732, 57932, 57787, 57858],
                    [1720296000, 57836, 58225, 57858, 57972],
                    [1720299600, 57807, 58278, 57972, 57868],
                    [1720303200, 57868, 58118, 57868, 58106],
                    [1720306800, 58103, 58475, 58106, 58230],
                    [1720310400, 57914, 58301, 58230, 57932],
                    [1720314000, 57838, 58319, 57932, 58114],
                    [1720317600, 58114, 58449, 58114, 58360],
                    [1720321200, 57856, 58364, 58360, 57963],
                    [1720324800, 57575, 58008, 57963, 57598],
                    [1720328400, 57416, 57800, 57598, 57648],
                    [1720332000, 57609, 57789, 57648, 57646],
                    [1720335600, 57073, 57691, 57646, 57370],
                    [1720339200, 57370, 57662, 57370, 57650],
                    [1720342800, 57566, 57734, 57650, 57733],
                    [1720346400, 57499, 57912, 57733, 57510],
                    [1720350000, 57351, 57544, 57510, 57460],
                    [1720353600, 57205, 57631, 57460, 57341],
                    [1720357200, 56778, 57361, 57341, 56872],
                    [1720360800, 56563, 56939, 56872, 56772],
                    [1720364400, 56729, 57061, 56772, 56825],
                    [1720368000, 56756, 57175, 56825, 57122],
                    [1720371600, 56794, 57286, 57122, 57231],
                    [1720375200, 57122, 57392, 57231, 57172],
                    [1720378800, 57023, 57198, 57172, 57151],
                    [1720382400, 57041, 57319, 57151, 57274],
                    [1720386000, 56210, 57285, 57274, 56596],
                    [1720389600, 56392, 56793, 56596, 56396],
                    [1720393200, 55724, 56528, 56396, 55858],
                    [1720396800, 55037, 56023, 55858, 55094],
                    [1720400400, 54260, 55110, 55094, 54958],
                    [1720404000, 54588, 55447, 54958, 55385],
                ],
                type_id: 34,
                module_id: 1,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                bot_id: 9,
                group_id: 517,
                mid: "Tn-",
                name: "bitcoin: hummer",
                message: [
                    [1653397200, 28910, 29327, 29258, 28961],
                    [1653400800, 28669, 29123, 28961, 29069],
                    [1653404400, 28980, 29650, 29069, 29382],
                    [1653408000, 29242, 29594, 29382, 29328],
                    [1653411600, 29151, 29398, 29328, 29341],
                    [1653415200, 29101, 29488, 29341, 29176],
                    [1653418800, 29161, 29471, 29176, 29392],
                    [1653422400, 29250, 29494, 29392, 29463],
                    [1653426000, 29417, 29650, 29463, 29587],
                    [1653429600, 29532, 29639, 29587, 29606],
                    [1653433200, 29582, 29846, 29606, 29655],
                    [1653436800, 29557, 29689, 29655, 29625],
                    [1653440400, 29573, 29803, 29625, 29790],
                    [1653444000, 29734, 30181, 29790, 30115],
                    [1653447600, 30070, 30223, 30115, 30198],
                    [1653451200, 30069, 30224, 30198, 30103],
                    [1653454800, 29844, 30140, 30103, 29869],
                    [1653458400, 29667, 29914, 29869, 29808],
                    [1653462000, 29691, 29851, 29808, 29701],
                    [1653465600, 29561, 29941, 29701, 29821],
                    [1653469200, 29685, 29877, 29821, 29813],
                    [1653472800, 29683, 29858, 29813, 29724],
                    [1653476400, 29294, 29758, 29724, 29409],
                    [1653480000, 29340, 29625, 29409, 29568],
                    [1653483600, 29490, 29862, 29568, 29713],
                    [1653487200, 29662, 29909, 29713, 29783],
                    [1653490800, 29600, 29828, 29783, 29629],
                    [1653494400, 29431, 29700, 29629, 29563],
                    [1653498000, 29540, 29783, 29563, 29712],
                    [1653501600, 29535, 30034, 29712, 29886],
                    [1653505200, 29571, 29977, 29886, 29599],
                    [1653508800, 29561, 29804, 29599, 29792],
                    [1653512400, 29742, 29850, 29792, 29825],
                    [1653516000, 29748, 29880, 29825, 29786],
                    [1653519600, 29537, 29787, 29786, 29542],
                    [1653523200, 29504, 29877, 29542, 29787],
                    [1653526800, 29661, 29820, 29787, 29677],
                    [1653530400, 29677, 29887, 29677, 29810],
                    [1653534000, 29675, 29857, 29810, 29837],
                    [1653537600, 29748, 29877, 29837, 29772],
                    [1653541200, 29665, 29850, 29772, 29721],
                    [1653544800, 29338, 29750, 29721, 29681],
                    [1653548400, 29565, 29747, 29681, 29734],
                    [1653552000, 28877, 29744, 29734, 29010],
                    [1653555600, 28882, 29255, 29010, 29194],
                    [1653559200, 29117, 29257, 29194, 29144],
                    [1653562800, 28925, 29186, 29144, 28998],
                    [1653566400, 28800, 29144, 28998, 28951],
                    [1653570000, 28020, 28981, 28951, 28811],
                    [1653573600, 28792, 29362, 28811, 29251],
                ],
                type_id: 34,
                module_id: 1,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                bot_id: 9,
                group_id: 517,
                mid: "Z2J",
                name: "bitcoin: hummer",
                message: [
                    [1717182000, 67342, 67999, 67415, 67681],
                    [1717185600, 67600, 67798, 67681, 67709],
                    [1717189200, 67500, 67710, 67709, 67565],
                    [1717192800, 67510, 67668, 67565, 67510],
                    [1717196400, 67239, 67603, 67510, 67540],
                    [1717200000, 67507, 67704, 67540, 67656],
                    [1717203600, 67428, 67710, 67656, 67560],
                    [1717207200, 67547, 67740, 67560, 67729],
                    [1717210800, 67623, 67757, 67729, 67715],
                    [1717214400, 67656, 67764, 67715, 67680],
                    [1717218000, 67603, 67810, 67680, 67686],
                    [1717221600, 67608, 67730, 67686, 67693],
                    [1717225200, 67575, 67733, 67693, 67632],
                    [1717228800, 67612, 67741, 67632, 67704],
                    [1717232400, 67635, 67760, 67704, 67707],
                    [1717236000, 67627, 67750, 67707, 67627],
                    [1717239600, 67627, 67694, 67627, 67661],
                    [1717243200, 67640, 67873, 67661, 67718],
                    [1717246800, 67714, 67837, 67718, 67720],
                    [1717250400, 67673, 67870, 67720, 67803],
                    [1717254000, 67668, 67824, 67803, 67691],
                    [1717257600, 67612, 67720, 67691, 67700],
                    [1717261200, 67557, 67900, 67700, 67791],
                    [1717264800, 67726, 67822, 67791, 67751],
                    [1717268400, 67686, 67783, 67751, 67754],
                    [1717272000, 67700, 67797, 67754, 67797],
                    [1717275600, 67713, 67820, 67797, 67760],
                    [1717279200, 67710, 67885, 67760, 67765],
                    [1717282800, 67723, 67820, 67765, 67767],
                    [1717286400, 67744, 67911, 67767, 67889],
                    [1717290000, 67710, 67900, 67889, 67755],
                    [1717293600, 67643, 67815, 67755, 67733],
                    [1717297200, 67726, 67808, 67733, 67789],
                    [1717300800, 67745, 67853, 67789, 67853],
                    [1717304400, 67728, 67868, 67853, 67728],
                    [1717308000, 67725, 67852, 67728, 67841],
                    [1717311600, 67720, 67843, 67841, 67721],
                    [1717315200, 67672, 67813, 67721, 67751],
                    [1717318800, 67378, 67773, 67751, 67530],
                    [1717322400, 67367, 67582, 67530, 67530],
                    [1717326000, 67493, 68320, 67530, 68252],
                    [1717329600, 67852, 68305, 68252, 67935],
                    [1717333200, 67800, 68215, 67935, 68166],
                    [1717336800, 68074, 68460, 68166, 68253],
                    [1717340400, 68016, 68297, 68253, 68142],
                    [1717344000, 67980, 68200, 68142, 68104],
                    [1717347600, 67809, 68104, 68104, 67879],
                    [1717351200, 67457, 67879, 67879, 67697],
                    [1717354800, 67257, 67798, 67697, 67737],
                    [1717358400, 67578, 67831, 67737, 67831],
                ],
                type_id: 34,
                module_id: 1,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                bot_id: 9,
                group_id: 517,
                mid: "CM7",
                name: "bitcoin: hummer",
                message: [
                    [1715036400, 63135, 63496, 63496, 63165],
                    [1715040000, 62960, 63667, 63165, 63545],
                    [1715043600, 63480, 63967, 63545, 63896],
                    [1715047200, 63620, 63953, 63896, 63703],
                    [1715050800, 62833, 63733, 63703, 63239],
                    [1715054400, 63093, 63471, 63240, 63466],
                    [1715058000, 63244, 63725, 63466, 63563],
                    [1715061600, 63394, 63719, 63563, 63624],
                    [1715065200, 63550, 63853, 63624, 63670],
                    [1715068800, 63596, 64422, 63670, 64232],
                    [1715072400, 64064, 64363, 64232, 64120],
                    [1715076000, 63747, 64172, 64120, 64086],
                    [1715079600, 63358, 64124, 64086, 63490],
                    [1715083200, 63474, 63772, 63490, 63663],
                    [1715086800, 63113, 63713, 63663, 63213],
                    [1715090400, 63051, 64089, 63213, 64024],
                    [1715094000, 63584, 64320, 64024, 63740],
                    [1715097600, 63456, 63895, 63740, 63634],
                    [1715101200, 63311, 63657, 63634, 63392],
                    [1715104800, 62790, 63394, 63392, 63170],
                    [1715108400, 62962, 63269, 63170, 63060],
                    [1715112000, 62889, 63198, 63060, 62975],
                    [1715115600, 62835, 63162, 62975, 63074],
                    [1715119200, 62739, 63124, 63074, 62851],
                    [1715122800, 62261, 62880, 62851, 62312],
                    [1715126400, 62111, 62730, 62312, 62586],
                    [1715130000, 62430, 62718, 62586, 62530],
                    [1715133600, 62530, 62849, 62530, 62756],
                    [1715137200, 62588, 62973, 62756, 62894],
                    [1715140800, 62358, 63020, 62894, 62520],
                    [1715144400, 62362, 62760, 62520, 62618],
                    [1715148000, 62135, 62710, 62618, 62506],
                    [1715151600, 62020, 62604, 62506, 62208],
                    [1715155200, 62042, 62428, 62208, 62376],
                    [1715158800, 62082, 62428, 62376, 62302],
                    [1715162400, 62264, 62520, 62302, 62450],
                    [1715166000, 62131, 62507, 62450, 62243],
                    [1715169600, 62094, 62450, 62243, 62238],
                    [1715173200, 61761, 62461, 62238, 62448],
                    [1715176800, 62161, 62828, 62448, 62560],
                    [1715180400, 61887, 62604, 62560, 62273],
                    [1715184000, 62000, 62444, 62273, 62444],
                    [1715187600, 62368, 62686, 62444, 62594],
                    [1715191200, 62450, 62760, 62594, 62515],
                    [1715194800, 61949, 62549, 62515, 62179],
                    [1715198400, 61430, 62249, 62179, 61570],
                    [1715202000, 61382, 61763, 61570, 61486],
                    [1715205600, 61103, 61569, 61486, 61251],
                    [1715209200, 60888, 61280, 61251, 61193],
                    [1715212800, 61144, 61468, 61193, 61400],
                ],
                type_id: 34,
                module_id: 1,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                bot_id: 9,
                group_id: 517,
                mid: "45Z",
                name: "bitcoin: hummer",
                message: [
                    [1664805600, 19175, 19489, 19176, 19416],
                    [1664809200, 19322, 19498, 19416, 19336],
                    [1664812800, 19310, 19420, 19337, 19410],
                    [1664816400, 19408, 19598, 19411, 19547],
                    [1664820000, 19541, 19650, 19547, 19612],
                    [1664823600, 19431, 19631, 19613, 19541],
                    [1664827200, 19509, 19607, 19543, 19592],
                    [1664830800, 19525, 19599, 19592, 19554],
                    [1664834400, 19512, 19605, 19555, 19519],
                    [1664838000, 19518, 19719, 19519, 19629],
                    [1664841600, 19529, 19723, 19629, 19562],
                    [1664845200, 19501, 19583, 19562, 19505],
                    [1664848800, 19491, 19557, 19505, 19543],
                    [1664852400, 19537, 19633, 19543, 19598],
                    [1664856000, 19554, 19647, 19598, 19593],
                    [1664859600, 19582, 19668, 19593, 19611],
                    [1664863200, 19598, 19870, 19610, 19841],
                    [1664866800, 19835, 19975, 19840, 19968],
                    [1664870400, 19853, 20152, 19968, 19884],
                    [1664874000, 19808, 19987, 19885, 19938],
                    [1664877600, 19893, 20050, 19938, 19961],
                    [1664881200, 19868, 19978, 19962, 19939],
                    [1664884800, 19905, 19988, 19939, 19980],
                    [1664888400, 19950, 20155, 19980, 20099],
                    [1664892000, 20035, 20270, 20099, 20108],
                    [1664895600, 20046, 20127, 20108, 20100],
                    [1664899200, 19950, 20116, 20100, 19993],
                    [1664902800, 19872, 20040, 19993, 20026],
                    [1664906400, 20018, 20170, 20026, 20127],
                    [1664910000, 20042, 20273, 20125, 20220],
                    [1664913600, 20140, 20475, 20220, 20326],
                    [1664917200, 20257, 20403, 20326, 20287],
                    [1664920800, 20234, 20332, 20289, 20300],
                    [1664924400, 20271, 20385, 20301, 20338],
                    [1664928000, 20143, 20366, 20338, 20182],
                    [1664931600, 20174, 20279, 20182, 20213],
                    [1664935200, 20183, 20250, 20214, 20192],
                    [1664938800, 20158, 20235, 20191, 20190],
                    [1664942400, 20075, 20226, 20191, 20121],
                    [1664946000, 20102, 20204, 20121, 20199],
                    [1664949600, 20157, 20300, 20199, 20254],
                    [1664953200, 20200, 20285, 20254, 20227],
                    [1664956800, 20003, 20246, 20227, 20134],
                    [1664960400, 20055, 20153, 20134, 20090],
                    [1664964000, 20074, 20153, 20090, 20124],
                    [1664967600, 19952, 20131, 20124, 20027],
                    [1664971200, 19960, 20071, 20029, 20022],
                    [1664974800, 19812, 20043, 20022, 19920],
                    [1664978400, 19730, 19944, 19920, 19879],
                    [1664982000, 19862, 20024, 19879, 20002],
                ],
                type_id: 34,
                module_id: 1,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                bot_id: 9,
                group_id: 517,
                mid: "003",
                name: "Еще МОЛОТ",
                message: "Еще МОЛОТ",
                type_id: 1,
                module_id: 1,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                bot_id: 9,
                group_id: 517,
                mid: "YL{",
                name: "bitcoin: hummer",
                message: [
                    [1721505600, 67224, 67430, 67224, 67339],
                    [1721509200, 66791, 67343, 67339, 67092],
                    [1721512800, 66862, 67300, 67092, 67114],
                    [1721516400, 67092, 67200, 67114, 67140],
                    [1721520000, 66962, 67322, 67140, 67066],
                    [1721523600, 67041, 67436, 67066, 67276],
                    [1721527200, 67270, 67397, 67276, 67287],
                    [1721530800, 67147, 67375, 67287, 67171],
                    [1721534400, 67001, 67221, 67171, 67040],
                    [1721538000, 66890, 67100, 67040, 67046],
                    [1721541600, 66611, 67093, 67046, 66722],
                    [1721545200, 66592, 66820, 66722, 66702],
                    [1721548800, 66680, 66892, 66702, 66796],
                    [1721552400, 66757, 67017, 66796, 66976],
                    [1721556000, 66799, 67063, 66976, 66799],
                    [1721559600, 66754, 66880, 66799, 66880],
                    [1721563200, 66840, 66987, 66880, 66928],
                    [1721566800, 66616, 66962, 66928, 66760],
                    [1721570400, 66682, 67393, 66760, 67291],
                    [1721574000, 66971, 67500, 67291, 67164],
                    [1721577600, 66952, 67590, 67164, 67554],
                    [1721581200, 66485, 67713, 67554, 66648],
                    [1721584800, 65777, 67336, 66648, 66864],
                    [1721588400, 66783, 67713, 66864, 67422],
                    [1721592000, 67275, 68209, 67422, 67724],
                    [1721595600, 67564, 68298, 67724, 68194],
                    [1721599200, 67934, 68367, 68194, 68012],
                    [1721602800, 67889, 68200, 68012, 68165],
                    [1721606400, 68036, 68475, 68165, 68188],
                    [1721610000, 67762, 68228, 68188, 67850],
                    [1721613600, 67754, 68199, 67850, 68071],
                    [1721617200, 67837, 68102, 68071, 67880],
                    [1721620800, 67760, 67996, 67880, 67924],
                    [1721624400, 67520, 67940, 67924, 67626],
                    [1721628000, 67122, 67744, 67626, 67412],
                    [1721631600, 67185, 67412, 67412, 67236],
                    [1721635200, 67030, 67400, 67236, 67080],
                    [1721638800, 66944, 67427, 67080, 67427],
                    [1721642400, 67294, 67524, 67427, 67422],
                    [1721646000, 67288, 67599, 67422, 67516],
                    [1721649600, 67366, 67697, 67516, 67697],
                    [1721653200, 66991, 67952, 67697, 67098],
                    [1721656800, 66617, 67328, 67098, 66712],
                    [1721660400, 66560, 67190, 66712, 67064],
                    [1721664000, 66798, 67314, 67064, 67052],
                    [1721667600, 67010, 67468, 67052, 67468],
                    [1721671200, 67323, 67666, 67468, 67445],
                    [1721674800, 67367, 68250, 67445, 68232],
                    [1721678400, 67822, 68256, 68232, 68124],
                    [1721682000, 67658, 68166, 68124, 67842],
                ],
                type_id: 34,
                module_id: 1,
                theme_id: 1,
                answ_id: 2,
                pointy: 10,
            },
            {
                bot_id: 9,
                group_id: 517,
                mid: "vbx",
                name: "bitcoin: hummer",
                message: [
                    [1713592800, 63939, 64222, 64159, 64046],
                    [1713596400, 63922, 64160, 64046, 63961],
                    [1713600000, 63662, 63988, 63961, 63729],
                    [1713603600, 63277, 63778, 63729, 63615],
                    [1713607200, 63377, 63782, 63615, 63567],
                    [1713610800, 63558, 63924, 63567, 63707],
                    [1713614400, 63576, 63935, 63707, 63824],
                    [1713618000, 63799, 64048, 63824, 63876],
                    [1713621600, 63746, 63985, 63876, 63935],
                    [1713625200, 63790, 64960, 63935, 64808],
                    [1713628800, 64457, 64969, 64808, 64739],
                    [1713632400, 64739, 65419, 64739, 65200],
                    [1713636000, 64837, 65311, 65200, 64964],
                    [1713639600, 64792, 65000, 64964, 64866],
                    [1713643200, 64622, 65031, 64866, 64728],
                    [1713646800, 64622, 64825, 64728, 64726],
                    [1713650400, 64555, 64778, 64726, 64614],
                    [1713654000, 64614, 64989, 64614, 64941],
                    [1713657600, 64608, 64960, 64941, 64760],
                    [1713661200, 64738, 65100, 64760, 64946],
                    [1713664800, 64921, 65377, 64946, 65298],
                    [1713668400, 65094, 65696, 65298, 65170],
                    [1713672000, 64844, 65196, 65170, 65056],
                    [1713675600, 64984, 65245, 65056, 65028],
                    [1713679200, 64942, 65200, 65028, 65048],
                    [1713682800, 64900, 65175, 65048, 65151],
                    [1713686400, 64920, 65222, 65151, 64927],
                    [1713690000, 64763, 64987, 64927, 64900],
                    [1713693600, 64785, 64986, 64900, 64955],
                    [1713697200, 64955, 65441, 64955, 65234],
                    [1713700800, 64934, 65375, 65234, 65004],
                    [1713704400, 64779, 65245, 65004, 64882],
                    [1713708000, 64790, 65037, 64882, 64894],
                    [1713711600, 64880, 65132, 64893, 65044],
                    [1713715200, 64238, 65088, 65044, 64578],
                    [1713718800, 64531, 64876, 64578, 64822],
                    [1713722400, 64596, 64863, 64822, 64772],
                    [1713726000, 64528, 64856, 64772, 64538],
                    [1713729600, 64363, 64722, 64538, 64646],
                    [1713733200, 64646, 65167, 64646, 64662],
                    [1713736800, 64569, 65110, 64662, 65030],
                    [1713740400, 64866, 65063, 65030, 64941],
                    [1713744000, 64759, 65158, 64941, 64909],
                    [1713747600, 64605, 65640, 64909, 64658],
                    [1713751200, 64500, 64863, 64658, 64807],
                    [1713754800, 64754, 66091, 64807, 65704],
                    [1713758400, 65509, 65878, 65703, 65738],
                    [1713762000, 65702, 66415, 65738, 66378],
                    [1713765600, 66151, 66413, 66378, 66300],
                    [1713769200, 65979, 66480, 66300, 65980],
                ],
                type_id: 34,
                module_id: 1,
                theme_id: 1,
                answ_id: 2,
                pointy: 10,
            },
            {
                bot_id: 9,
                group_id: 517,
                mid: "004",
                name: "Готов к задаче посложнее?",
                message: "Готов к задаче посложнее?",
                type_id: 1,
                module_id: 1,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                bot_id: 9,
                group_id: 517,
                mid: "e;i",
                name: "bitcoin: hummer",
                message: [
                    [1718200800, 69300, 69980, 69320, 69961],
                    [1718204400, 69540, 69999, 69961, 69734],
                    [1718208000, 69290, 69786, 69734, 69616],
                    [1718211600, 69492, 69862, 69616, 69800],
                    [1718215200, 68575, 69808, 69800, 68828],
                    [1718218800, 67250, 69226, 68828, 67533],
                    [1718222400, 67414, 68312, 67533, 68104],
                    [1718226000, 68098, 68667, 68104, 68536],
                    [1718229600, 68271, 68540, 68536, 68346],
                    [1718233200, 68124, 68347, 68346, 68264],
                    [1718236800, 68006, 68394, 68264, 68330],
                    [1718240400, 67910, 68363, 68330, 67980],
                    [1718244000, 67691, 68177, 67980, 67735],
                    [1718247600, 66970, 67850, 67735, 67475],
                    [1718251200, 67328, 67729, 67475, 67328],
                    [1718254800, 67328, 67680, 67328, 67604],
                    [1718258400, 67300, 67615, 67604, 67519],
                    [1718262000, 67400, 67725, 67519, 67725],
                    [1718265600, 67508, 67752, 67725, 67566],
                    [1718269200, 67168, 67570, 67566, 67420],
                    [1718272800, 67231, 67971, 67420, 67820],
                    [1718276400, 67648, 67961, 67820, 67740],
                    [1718280000, 67439, 68449, 67740, 67750],
                    [1718283600, 67522, 68272, 67750, 67590],
                    [1718287200, 67150, 67739, 67590, 67366],
                    [1718290800, 66369, 67538, 67366, 66470],
                    [1718294400, 66252, 67019, 66470, 66976],
                    [1718298000, 66639, 67080, 66976, 66920],
                    [1718301600, 66693, 66977, 66920, 66863],
                    [1718305200, 66589, 66884, 66863, 66604],
                    [1718308800, 66531, 66810, 66604, 66713],
                    [1718312400, 66680, 66970, 66713, 66970],
                    [1718316000, 66840, 66980, 66970, 66896],
                    [1718319600, 66681, 66909, 66896, 66773],
                    [1718323200, 66605, 66898, 66773, 66728],
                    [1718326800, 66480, 66833, 66728, 66806],
                    [1718330400, 66714, 67027, 66806, 66952],
                    [1718334000, 66676, 66983, 66952, 66744],
                    [1718337600, 66717, 66833, 66744, 66717],
                    [1718341200, 66708, 67221, 66717, 67052],
                    [1718344800, 66876, 67124, 67053, 66962],
                    [1718348400, 66886, 67110, 66962, 66905],
                    [1718352000, 66863, 67256, 66905, 67152],
                    [1718355600, 66899, 67152, 67152, 66920],
                    [1718359200, 66837, 67016, 66920, 66873],
                    [1718362800, 66746, 67101, 66873, 67036],
                    [1718366400, 66961, 67288, 67036, 67083],
                    [1718370000, 66950, 67370, 67083, 67071],
                    [1718373600, 66561, 67115, 67070, 66983],
                    [1718377200, 66345, 67059, 66983, 66392],
                ],
                type_id: 34,
                module_id: 1,
                theme_id: 1,
                answ_id: 2,
                pointy: 10,
            },
            // {
            //     bot_id: 9,
            //     group_id: 517,
            //     mid: "WNY",
            //     name: "bitcoin: hummer",
            //     message: [
            //         [1721970000, 66871, 67100, 67076, 66924],
            //         [1721973600, 66874, 67160, 66924, 67062],
            //         [1721977200, 66880, 67190, 67062, 66891],
            //         [1721980800, 66891, 67143, 66891, 67106],
            //         [1721984400, 67086, 67498, 67106, 67296],
            //         [1721988000, 67173, 67410, 67296, 67304],
            //         [1721991600, 67152, 67366, 67304, 67322],
            //         [1721995200, 67086, 67410, 67322, 67314],
            //         [1721998800, 67170, 67976, 67314, 67888],
            //         [1722002400, 66892, 68000, 67888, 67328],
            //         [1722006000, 67286, 67751, 67328, 67560],
            //         [1722009600, 67367, 67685, 67560, 67396],
            //         [1722013200, 67303, 67600, 67396, 67372],
            //         [1722016800, 67336, 67602, 67372, 67574],
            //         [1722020400, 67552, 68144, 67574, 67990],
            //         [1722024000, 67414, 68000, 67990, 67435],
            //         [1722027600, 67420, 68200, 67435, 67946],
            //         [1722031200, 67855, 68086, 67946, 67926],
            //         [1722034800, 67848, 67976, 67926, 67908],
            //         [1722038400, 67808, 67946, 67908, 67872],
            //         [1722042000, 67630, 67874, 67872, 67658],
            //         [1722045600, 67658, 68070, 67658, 68028],
            //         [1722049200, 67773, 68082, 68028, 67798],
            //         [1722052800, 67783, 67898, 67798, 67866],
            //         [1722056400, 67866, 67998, 67866, 67895],
            //         [1722060000, 67886, 68094, 67895, 68093],
            //         [1722063600, 68037, 68295, 68093, 68037],
            //         [1722067200, 67892, 68176, 68037, 67986],
            //         [1722070800, 67947, 68217, 67986, 68217],
            //         [1722074400, 68083, 68276, 68217, 68155],
            //         [1722078000, 68010, 68212, 68155, 68210],
            //         [1722081600, 68195, 68472, 68210, 68400],
            //         [1722085200, 68400, 69385, 68400, 69226],
            //         [1722088800, 68794, 69400, 69226, 68982],
            //         [1722092400, 68584, 69086, 68982, 68844],
            //         [1722096000, 68644, 68979, 68844, 68684],
            //         [1722099600, 68428, 68754, 68684, 68540],
            //         [1722103200, 68508, 68990, 68540, 68543],
            //         [1722106800, 67659, 68642, 68543, 68246],
            //         [1722110400, 66650, 68450, 68246, 67791],
            //         [1722114000, 67596, 69183, 67791, 68860],
            //         [1722117600, 68503, 68860, 68860, 68620],
            //         [1722121200, 67750, 68620, 68620, 67897],
            //         [1722124800, 67261, 68083, 67896, 67958],
            //         [1722128400, 67916, 68285, 67958, 68090],
            //         [1722132000, 67966, 68149, 68090, 68149],
            //         [1722135600, 67941, 68149, 68149, 67941],
            //         [1722139200, 67300, 68024, 67941, 67338],
            //         [1722142800, 67067, 67500, 67338, 67422],
            //         [1722146400, 67386, 67591, 67422, 67486],
            //     ],
            //     type_id: 34,
            //     module_id: 1,
            //     theme_id: 1,
            //     answ_id: 1,
            //     pointy: 10,
            // },
            // {
            //     bot_id: 9,
            //     group_id: 517,
            //     mid: "K-T",
            //     name: "bitcoin: hummer",
            //     message: [
            //         [1720188000, 55350, 55896, 55830, 55754],
            //         [1720191600, 55664, 56678, 55754, 56662],
            //         [1720195200, 56150, 56937, 56663, 56448],
            //         [1720198800, 56301, 57040, 56448, 56453],
            //         [1720202400, 55779, 56724, 56453, 56546],
            //         [1720206000, 56330, 56747, 56546, 56465],
            //         [1720209600, 56315, 56516, 56465, 56351],
            //         [1720213200, 56346, 56791, 56351, 56518],
            //         [1720216800, 56400, 56736, 56518, 56621],
            //         [1720220400, 56616, 56866, 56621, 56629],
            //         [1720224000, 56355, 56998, 56629, 56601],
            //         [1720227600, 56208, 56612, 56601, 56220],
            //         [1720231200, 56171, 56498, 56220, 56440],
            //         [1720234800, 56268, 56520, 56440, 56410],
            //         [1720238400, 56044, 56418, 56410, 56059],
            //         [1720242000, 56018, 56490, 56059, 56363],
            //         [1720245600, 56347, 56568, 56363, 56417],
            //         [1720249200, 56401, 56684, 56417, 56606],
            //         [1720252800, 56507, 56658, 56606, 56558],
            //         [1720256400, 56437, 56780, 56558, 56766],
            //         [1720260000, 56650, 56876, 56766, 56780],
            //         [1720263600, 56660, 56800, 56780, 56731],
            //         [1720267200, 56500, 56797, 56731, 56755],
            //         [1720270800, 56660, 57299, 56755, 56991],
            //         [1720274400, 56700, 57128, 56991, 57059],
            //         [1720278000, 56928, 57441, 57059, 57318],
            //         [1720281600, 57186, 58113, 57318, 57853],
            //         [1720285200, 57747, 58032, 57853, 57965],
            //         [1720288800, 57746, 58021, 57965, 57787],
            //         [1720292400, 57732, 57932, 57787, 57858],
            //         [1720296000, 57836, 58225, 57858, 57972],
            //         [1720299600, 57807, 58278, 57972, 57868],
            //         [1720303200, 57868, 58118, 57868, 58106],
            //         [1720306800, 58103, 58475, 58106, 58230],
            //         [1720310400, 57914, 58301, 58230, 57932],
            //         [1720314000, 57838, 58319, 57932, 58114],
            //         [1720317600, 58114, 58449, 58114, 58360],
            //         [1720321200, 57856, 58364, 58360, 57963],
            //         [1720324800, 57575, 58008, 57963, 57598],
            //         [1720328400, 57416, 57800, 57598, 57648],
            //         [1720332000, 57609, 57789, 57648, 57646],
            //         [1720335600, 57073, 57691, 57646, 57370],
            //         [1720339200, 57370, 57662, 57370, 57650],
            //         [1720342800, 57566, 57734, 57650, 57733],
            //         [1720346400, 57499, 57912, 57733, 57510],
            //         [1720350000, 57351, 57544, 57510, 57460],
            //         [1720353600, 57205, 57631, 57460, 57341],
            //         [1720357200, 56778, 57361, 57341, 56872],
            //         [1720360800, 56563, 56939, 56872, 56772],
            //         [1720364400, 56729, 57061, 56772, 56825],
            //     ],
            //     type_id: 34,
            //     module_id: 1,
            //     theme_id: 1,
            //     answ_id: 1,
            //     pointy: 10,
            // },
            {
                bot_id: 9,
                group_id: 517,
                mid: "005",
                name: "Отлично",
                message: "Отлично",
                type_id: 1,
                module_id: 1,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },

            {
                bot_id: 9,
                group_id: 517,
                mid: "201",
                name: "Изучим Doji",
                message: "Изучим Doji",
                type_id: 1,
                module_id: 2,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },

            {
                bot_id: 9,
                group_id: 517,
                mid: "202",
                name: "Виды Doji",
                message: "Виды Doji",
                type_id: 1,
                module_id: 2,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                id: 11,
                bot_id: 9,
                group_id: 517,
                mid: "=G$",
                name: "bitcoin: doji",
                message: [
                    [1650283200, 38999, 39530, 38999, 39400],
                    [1650286800, 39233, 39621, 39400, 39326],
                    [1650290400, 39302, 39570, 39326, 39340],
                    [1650294000, 39123, 39370, 39340, 39255],
                    [1650297600, 39000, 39622, 39255, 39464],
                    [1650301200, 39463, 40371, 39464, 40211],
                    [1650304800, 40211, 41117, 40211, 40646],
                    [1650308400, 40422, 40747, 40646, 40681],
                    [1650312000, 40633, 40909, 40681, 40664],
                    [1650315600, 40664, 41099, 40664, 40801],
                    [1650319200, 40710, 40950, 40801, 40924],
                    [1650322800, 40721, 40973, 40924, 40801],
                    [1650326400, 40792, 41263, 40801, 40881],
                    [1650330000, 40699, 40936, 40881, 40750],
                    [1650333600, 40601, 40765, 40750, 40611],
                    [1650337200, 40609, 40791, 40611, 40694],
                    [1650340800, 40658, 40884, 40694, 40864],
                    [1650344400, 40649, 40878, 40864, 40661],
                    [1650348000, 40661, 40806, 40661, 40712],
                    [1650351600, 40571, 40750, 40712, 40683],
                    [1650355200, 40667, 40855, 40683, 40726],
                    [1650358800, 40580, 40768, 40726, 40665],
                    [1650362400, 40637, 40816, 40665, 40796],
                    [1650366000, 40665, 41027, 40796, 40687],
                    [1650369600, 40662, 41104, 40687, 40975],
                    [1650373200, 40842, 41472, 40975, 41452],
                    [1650376800, 41381, 41687, 41452, 41566],
                    [1650380400, 41372, 41760, 41566, 41446],
                    [1650384000, 41237, 41547, 41446, 41342],
                    [1650387600, 41168, 41370, 41342, 41346],
                    [1650391200, 41312, 41504, 41346, 41401],
                    [1650394800, 41362, 41548, 41401, 41417],
                    [1650398400, 41213, 41417, 41417, 41293],
                    [1650402000, 41260, 41391, 41293, 41313],
                    [1650405600, 41266, 41461, 41313, 41395],
                    [1650409200, 41395, 41540, 41395, 41493],
                    [1650412800, 41250, 41541, 41493, 41295],
                    [1650416400, 41230, 41386, 41295, 41351],
                    [1650420000, 41312, 41399, 41351, 41372],
                    [1650423600, 41296, 41395, 41372, 41386],
                    [1650427200, 41262, 41397, 41386, 41337],
                    [1650430800, 41290, 41446, 41337, 41444],
                    [1650434400, 41383, 41472, 41444, 41472],
                    [1650438000, 41292, 41631, 41472, 41340],
                    [1650441600, 41302, 41520, 41340, 41478],
                    [1650445200, 41453, 41720, 41478, 41540],
                    [1650448800, 41448, 41893, 41540, 41821],
                    [1650452400, 41713, 42199, 41821, 42090],
                    [1650456000, 41944, 42173, 42090, 42103],
                    [1650459600, 41388, 42110, 42103, 41592],
                ],
                type_id: 34,
                module_id: 2,
                theme_id: 1,
                answ_id: 2,
                pointy: 10,
            },
            {
                id: 9,
                bot_id: 9,
                group_id: 517,
                mid: "=nU",
                name: "bitcoin: doji",
                message: [
                    [1645563600, 37831, 38051, 37909, 37875],
                    [1645567200, 37755, 37977, 37875, 37917],
                    [1645570800, 37884, 38429, 37917, 38230],
                    [1645574400, 38036, 38355, 38230, 38138],
                    [1645578000, 37938, 38160, 38138, 38063],
                    [1645581600, 37528, 38207, 38063, 37693],
                    [1645585200, 37593, 37796, 37693, 37751],
                    [1645588800, 37713, 38078, 37751, 38003],
                    [1645592400, 37813, 38124, 38003, 37976],
                    [1645596000, 37913, 38148, 37976, 37989],
                    [1645599600, 37957, 38213, 37989, 38151],
                    [1645603200, 38081, 38789, 38151, 38673],
                    [1645606800, 38600, 38990, 38673, 38877],
                    [1645610400, 38759, 38957, 38877, 38866],
                    [1645614000, 38735, 39125, 38866, 38783],
                    [1645617600, 38749, 39100, 38783, 39016],
                    [1645621200, 38889, 39250, 39016, 38960],
                    [1645624800, 38624, 38991, 38960, 38660],
                    [1645628400, 38450, 38673, 38660, 38513],
                    [1645632000, 38360, 38710, 38513, 38638],
                    [1645635600, 38005, 38780, 38638, 38084],
                    [1645639200, 37715, 38110, 38084, 37781],
                    [1645642800, 37536, 37875, 37781, 37616],
                    [1645646400, 37382, 37739, 37616, 37678],
                    [1645650000, 37423, 37796, 37678, 37513],
                    [1645653600, 37080, 37668, 37513, 37648],
                    [1645657200, 37037, 37720, 37648, 37250],
                    [1645660800, 36700, 37428, 37250, 36756],
                    [1645664400, 36638, 37080, 36756, 36950],
                    [1645668000, 35820, 37020, 36950, 35925],
                    [1645671600, 34721, 35955, 35925, 34937],
                    [1645675200, 34536, 35278, 34937, 35078],
                    [1645678800, 34322, 35111, 35078, 34681],
                    [1645682400, 34609, 35106, 34684, 34902],
                    [1645686000, 34379, 35516, 34902, 35312],
                    [1645689600, 35181, 35885, 35312, 35514],
                    [1645693200, 35287, 35790, 35514, 35346],
                    [1645696800, 34818, 35494, 35346, 35199],
                    [1645700400, 34885, 35598, 35199, 35442],
                    [1645704000, 35035, 35462, 35442, 35256],
                    [1645707600, 35127, 35700, 35256, 35476],
                    [1645711200, 35178, 35900, 35476, 35640],
                    [1645714800, 35404, 36100, 35640, 35920],
                    [1645718400, 35861, 36311, 35920, 36157],
                    [1645722000, 35722, 36173, 36157, 35832],
                    [1645725600, 35800, 36800, 35832, 36397],
                    [1645729200, 36221, 37366, 36397, 37300],
                    [1645732800, 37286, 39843, 37300, 38419],
                    [1645736400, 38184, 38684, 38422, 38396],
                    [1645740000, 37749, 38482, 38396, 37870],
                ],
                type_id: 34,
                module_id: 2,
                theme_id: 1,
                answ_id: 2,
                pointy: 10,
            },
            {
                bot_id: 9,
                group_id: 517,
                mid: "203",
                name: "Виды Doji",
                message: "Виды Doji",
                type_id: 1,
                module_id: 2,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                id: 1,
                bot_id: 9,
                group_id: 517,
                mid: "8Nn",
                name: "bitcoin: doji",
                message: [
                    [1717246800, 67714, 67837, 67718, 67720],
                    [1717250400, 67673, 67870, 67720, 67803],
                    [1717254000, 67668, 67824, 67803, 67691],
                    [1717257600, 67612, 67720, 67691, 67700],
                    [1717261200, 67557, 67900, 67700, 67791],
                    [1717264800, 67726, 67822, 67791, 67751],
                    [1717268400, 67686, 67783, 67751, 67754],
                    [1717272000, 67700, 67797, 67754, 67797],
                    [1717275600, 67713, 67820, 67797, 67760],
                    [1717279200, 67710, 67885, 67760, 67765],
                    [1717282800, 67723, 67820, 67765, 67767],
                    [1717286400, 67744, 67911, 67767, 67889],
                    [1717290000, 67710, 67900, 67889, 67755],
                    [1717293600, 67643, 67815, 67755, 67733],
                    [1717297200, 67726, 67808, 67733, 67789],
                    [1717300800, 67745, 67853, 67789, 67853],
                    [1717304400, 67728, 67868, 67853, 67728],
                    [1717308000, 67725, 67852, 67728, 67841],
                    [1717311600, 67720, 67843, 67841, 67721],
                    [1717315200, 67672, 67813, 67721, 67751],
                    [1717318800, 67378, 67773, 67751, 67530],
                    [1717322400, 67367, 67582, 67530, 67530],
                    [1717326000, 67493, 68320, 67530, 68252],
                    [1717329600, 67852, 68305, 68252, 67935],
                    [1717333200, 67800, 68215, 67935, 68166],
                    [1717336800, 68074, 68460, 68166, 68253],
                    [1717340400, 68016, 68297, 68253, 68142],
                    [1717344000, 67980, 68200, 68142, 68104],
                    [1717347600, 67809, 68104, 68104, 67879],
                    [1717351200, 67457, 67879, 67879, 67697],
                    [1717354800, 67257, 67798, 67697, 67737],
                    [1717358400, 67578, 67831, 67737, 67831],
                    [1717362000, 67755, 67902, 67831, 67816],
                    [1717365600, 67810, 67984, 67816, 67928],
                    [1717369200, 67766, 67984, 67928, 67766],
                    [1717372800, 67612, 67874, 67766, 67824],
                    [1717376400, 67824, 68554, 67824, 68476],
                    [1717380000, 68405, 68794, 68476, 68416],
                    [1717383600, 68201, 68519, 68416, 68383],
                    [1717387200, 68285, 68600, 68383, 68510],
                    [1717390800, 68441, 68780, 68510, 68700],
                    [1717394400, 68632, 69221, 68700, 69039],
                    [1717398000, 68804, 69180, 69039, 68907],
                    [1717401600, 68907, 69333, 68907, 69143],
                    [1717405200, 68914, 69187, 69143, 69015],
                    [1717408800, 68877, 69187, 69015, 68908],
                    [1717412400, 68824, 69249, 68908, 69080],
                    [1717416000, 69075, 69769, 69080, 69746],
                    [1717419600, 69521, 70288, 69746, 69795],
                    [1717423200, 68500, 70080, 69795, 68776],
                ],
                type_id: 34,
                module_id: 2,
                theme_id: 1,
                answ_id: 2,
                pointy: 10,
            },
            {
                id: 2,
                bot_id: 9,
                group_id: 517,
                mid: "4Ne",
                name: "bitcoin: doji",
                message: [
                    [1719691200, 60970, 61088, 61057, 61050],
                    [1719694800, 60945, 61090, 61050, 61045],
                    [1719698400, 60840, 61060, 61045, 60970],
                    [1719702000, 60940, 61059, 60970, 60987],
                    [1719705600, 60922, 61078, 60987, 61025],
                    [1719709200, 60930, 61043, 61025, 60962],
                    [1719712800, 60741, 60962, 60962, 60834],
                    [1719716400, 60792, 60949, 60834, 60898],
                    [1719720000, 60765, 60901, 60898, 60778],
                    [1719723600, 60712, 60810, 60778, 60789],
                    [1719727200, 60771, 61040, 60789, 61040],
                    [1719730800, 61040, 61782, 61040, 61528],
                    [1719734400, 61393, 61608, 61528, 61556],
                    [1719738000, 61511, 61714, 61556, 61530],
                    [1719741600, 61504, 61635, 61530, 61540],
                    [1719745200, 61525, 61668, 61540, 61560],
                    [1719748800, 61346, 61660, 61560, 61496],
                    [1719752400, 61456, 61747, 61496, 61720],
                    [1719756000, 61528, 61860, 61720, 61587],
                    [1719759600, 61587, 61777, 61587, 61718],
                    [1719763200, 61590, 61749, 61718, 61604],
                    [1719766800, 61604, 61864, 61604, 61708],
                    [1719770400, 61674, 62110, 61708, 62055],
                    [1719774000, 61892, 62100, 62055, 62027],
                    [1719777600, 61981, 62412, 62027, 61990],
                    [1719781200, 61835, 62014, 61990, 61940],
                    [1719784800, 61940, 62952, 61940, 62901],
                    [1719788400, 62762, 63059, 62901, 62772],
                    [1719792000, 62573, 62944, 62772, 62932],
                    [1719795600, 62902, 63730, 62932, 63641],
                    [1719799200, 63289, 63818, 63641, 63436],
                    [1719802800, 63340, 63536, 63436, 63438],
                    [1719806400, 63223, 63467, 63438, 63280],
                    [1719810000, 63262, 63457, 63280, 63433],
                    [1719813600, 63294, 63465, 63433, 63404],
                    [1719817200, 63261, 63432, 63404, 63278],
                    [1719820800, 62836, 63315, 63278, 62924],
                    [1719824400, 62789, 62998, 62924, 62939],
                    [1719828000, 62781, 62978, 62939, 62852],
                    [1719831600, 62593, 62858, 62852, 62662],
                    [1719835200, 62642, 62900, 62662, 62882],
                    [1719838800, 62497, 63081, 62882, 62685],
                    [1719842400, 62610, 62944, 62685, 62826],
                    [1719846000, 62816, 63166, 62826, 63133],
                    [1719849600, 62967, 63338, 63133, 63119],
                    [1719853200, 63057, 63698, 63119, 63698],
                    [1719856800, 63438, 63862, 63698, 63650],
                    [1719860400, 63071, 63650, 63650, 63227],
                    [1719864000, 63162, 63423, 63227, 63293],
                    [1719867600, 63037, 63293, 63293, 63130],
                ],
                type_id: 34,
                module_id: 2,
                theme_id: 1,
                answ_id: 2,
                pointy: 10,
            },
            {
                id: 3,
                bot_id: 9,
                group_id: 517,
                mid: "BI:",
                name: "bitcoin: doji",
                message: [
                    [1716998400, 67125, 67720, 67379, 67555],
                    [1717002000, 67330, 67680, 67555, 67611],
                    [1717005600, 67222, 67623, 67611, 67393],
                    [1717009200, 67216, 67742, 67393, 67267],
                    [1717012800, 67151, 67495, 67267, 67486],
                    [1717016400, 67325, 67810, 67486, 67700],
                    [1717020000, 67606, 67826, 67700, 67680],
                    [1717023600, 67576, 67732, 67680, 67652],
                    [1717027200, 67551, 67843, 67652, 67583],
                    [1717030800, 67472, 67862, 67583, 67800],
                    [1717034400, 67789, 68200, 67800, 67949],
                    [1717038000, 67924, 68182, 67949, 68118],
                    [1717041600, 67984, 68423, 68118, 68163],
                    [1717045200, 67968, 68195, 68163, 68134],
                    [1717048800, 67728, 68180, 68134, 67831],
                    [1717052400, 67508, 68037, 67831, 67585],
                    [1717056000, 67128, 67769, 67585, 67689],
                    [1717059600, 67460, 67760, 67689, 67746],
                    [1717063200, 67542, 67991, 67746, 67942],
                    [1717066800, 67815, 68100, 67942, 67817],
                    [1717070400, 67755, 68306, 67817, 68133],
                    [1717074000, 68050, 68679, 68133, 68624],
                    [1717077600, 68256, 68826, 68624, 68360],
                    [1717081200, 68144, 68612, 68360, 68531],
                    [1717084800, 68384, 68692, 68531, 68676],
                    [1717088400, 68664, 69500, 68676, 69315],
                    [1717092000, 69084, 69420, 69315, 69360],
                    [1717095600, 68435, 69366, 69360, 68653],
                    [1717099200, 68050, 68657, 68653, 68476],
                    [1717102800, 68324, 68534, 68476, 68417],
                    [1717106400, 68242, 68439, 68417, 68315],
                    [1717110000, 68251, 68519, 68315, 68352],
                    [1717113600, 68274, 68465, 68352, 68460],
                    [1717117200, 68314, 68626, 68460, 68440],
                    [1717120800, 68430, 68730, 68440, 68540],
                    [1717124400, 68488, 68628, 68540, 68536],
                    [1717128000, 68486, 68654, 68536, 68517],
                    [1717131600, 68290, 68517, 68517, 68443],
                    [1717135200, 68226, 68456, 68443, 68272],
                    [1717138800, 68076, 68432, 68272, 68175],
                    [1717142400, 68131, 68311, 68175, 68136],
                    [1717146000, 67876, 68184, 68136, 67950],
                    [1717149600, 67930, 68333, 67950, 68333],
                    [1717153200, 68280, 68461, 68333, 68350],
                    [1717156800, 68321, 69044, 68350, 68644],
                    [1717160400, 68025, 68821, 68644, 68078],
                    [1717164000, 67264, 68078, 68078, 67502],
                    [1717167600, 67200, 67548, 67502, 67216],
                    [1717171200, 66670, 67352, 67216, 67200],
                    [1717174800, 67160, 67460, 67200, 67343],
                ],
                type_id: 34,
                module_id: 2,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                id: 4,
                bot_id: 9,
                group_id: 517,
                mid: "4c6",
                name: "bitcoin: doji",
                message: [
                    [1716782400, 68765, 68902, 68873, 68770],
                    [1716786000, 68322, 68784, 68770, 68525],
                    [1716789600, 68393, 68622, 68525, 68393],
                    [1716793200, 68342, 68721, 68393, 68643],
                    [1716796800, 68522, 68944, 68643, 68624],
                    [1716800400, 68431, 68660, 68624, 68595],
                    [1716804000, 68422, 68598, 68595, 68578],
                    [1716807600, 68250, 68680, 68578, 68434],
                    [1716811200, 68384, 68643, 68434, 68621],
                    [1716814800, 68601, 69046, 68621, 68919],
                    [1716818400, 68829, 69500, 68919, 69500],
                    [1716822000, 69500, 70520, 69500, 70374],
                    [1716825600, 70153, 70688, 70374, 70161],
                    [1716829200, 70030, 70350, 70161, 70184],
                    [1716832800, 69882, 70250, 70184, 69986],
                    [1716836400, 69069, 70113, 69985, 69260],
                    [1716840000, 69177, 69724, 69260, 69660],
                    [1716843600, 69590, 69888, 69660, 69805],
                    [1716847200, 69556, 69805, 69805, 69592],
                    [1716850800, 69324, 69592, 69592, 69436],
                    [1716854400, 69100, 69473, 69436, 69377],
                    [1716858000, 68516, 69592, 69377, 68614],
                    [1716861600, 68420, 68842, 68614, 68680],
                    [1716865200, 67753, 68738, 68680, 68050],
                    [1716868800, 67487, 68134, 68050, 67797],
                    [1716872400, 67660, 68050, 67797, 68050],
                    [1716876000, 67787, 68093, 68050, 67973],
                    [1716879600, 67658, 68030, 67973, 67694],
                    [1716883200, 67582, 68250, 67694, 68160],
                    [1716886800, 68025, 68524, 68160, 68336],
                    [1716890400, 68250, 68903, 68336, 68592],
                    [1716894000, 68265, 68666, 68592, 68390],
                    [1716897600, 68154, 68560, 68390, 68209],
                    [1716901200, 67660, 68478, 68209, 67792],
                    [1716904800, 67530, 68254, 67792, 67969],
                    [1716908400, 67729, 68461, 67969, 68374],
                    [1716912000, 67713, 68457, 68374, 67713],
                    [1716915600, 67640, 68160, 67713, 67783],
                    [1716919200, 67278, 67993, 67783, 67879],
                    [1716922800, 67864, 68598, 67879, 68424],
                    [1716926400, 68124, 68539, 68424, 68326],
                    [1716930000, 68258, 68550, 68326, 68401],
                    [1716933600, 68384, 68661, 68401, 68615],
                    [1716937200, 68347, 68621, 68615, 68398],
                    [1716940800, 68266, 68605, 68398, 68496],
                    [1716944400, 68310, 68708, 68496, 68570],
                    [1716948000, 68455, 68923, 68570, 68685],
                    [1716951600, 68681, 68885, 68685, 68829],
                    [1716955200, 68760, 68936, 68829, 68844],
                    [1716958800, 68573, 68844, 68844, 68585],
                ],
                type_id: 34,
                module_id: 2,
                theme_id: 1,
                answ_id: 2,
                pointy: 10,
            },
            {
                id: 5,
                bot_id: 9,
                group_id: 517,
                mid: "I.s",
                name: "bitcoin: doji",
                message: [
                    [1641650400, 41715, 41998, 41905, 41741],
                    [1641654000, 41400, 41799, 41746, 41613],
                    [1641657600, 41238, 41741, 41610, 41346],
                    [1641661200, 40801, 41398, 41346, 40842],
                    [1641664800, 40501, 41314, 40842, 40680],
                    [1641668400, 40520, 41100, 40680, 40831],
                    [1641672000, 40720, 41175, 40831, 40975],
                    [1641675600, 40923, 42061, 40975, 41769],
                    [1641679200, 41740, 42300, 41769, 41911],
                    [1641682800, 41656, 41949, 41911, 41680],
                    [1641686400, 41388, 41831, 41680, 41773],
                    [1641690000, 41596, 41925, 41773, 41810],
                    [1641693600, 41776, 42170, 41810, 42099],
                    [1641697200, 41792, 42106, 42099, 41834],
                    [1641700800, 41801, 42103, 41834, 42079],
                    [1641704400, 41715, 42097, 42079, 41766],
                    [1641708000, 41681, 41948, 41766, 41874],
                    [1641711600, 41779, 41909, 41874, 41792],
                    [1641715200, 41615, 41950, 41792, 41667],
                    [1641718800, 41470, 41767, 41667, 41510],
                    [1641722400, 41410, 41729, 41510, 41645],
                    [1641726000, 41643, 41990, 41645, 41858],
                    [1641729600, 41665, 41923, 41858, 41747],
                    [1641733200, 41469, 41894, 41747, 41470],
                    [1641736800, 41470, 41743, 41470, 41536],
                    [1641740400, 41200, 41682, 41536, 41621],
                    [1641744000, 41556, 42146, 41621, 42143],
                    [1641747600, 41880, 42200, 42143, 42095],
                    [1641751200, 41862, 42279, 42094, 41967],
                    [1641754800, 41928, 42740, 41967, 42695],
                    [1641758400, 42368, 42787, 42695, 42439],
                    [1641762000, 42287, 42557, 42439, 42300],
                    [1641765600, 42080, 42497, 42300, 42205],
                    [1641769200, 41766, 42282, 42205, 41865],
                    [1641772800, 41624, 42037, 41865, 41655],
                    [1641776400, 41601, 41850, 41655, 41815],
                    [1641780000, 41712, 41998, 41815, 41928],
                    [1641783600, 41852, 41991, 41928, 41968],
                    [1641787200, 41926, 42249, 41968, 42187],
                    [1641790800, 41967, 42231, 42187, 42031],
                    [1641794400, 41812, 42049, 42031, 41925],
                    [1641798000, 41901, 42149, 41925, 42042],
                    [1641801600, 41713, 42117, 42042, 41817],
                    [1641805200, 41711, 41937, 41809, 41796],
                    [1641808800, 41742, 41986, 41796, 41779],
                    [1641812400, 41455, 41794, 41779, 41565],
                    [1641816000, 40917, 41617, 41565, 40930],
                    [1641819600, 40716, 41058, 40930, 40774],
                    [1641823200, 39650, 41276, 40774, 40787],
                    [1641826800, 40556, 41200, 40787, 40984],
                ],
                type_id: 34,
                module_id: 2,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                id: 6,
                bot_id: 9,
                group_id: 517,
                mid: "-)n",
                name: "bitcoin: doji",
                message: [
                    [1643659200, 38237, 38563, 38416, 38451],
                    [1643662800, 38335, 38532, 38451, 38410],
                    [1643666400, 38399, 38738, 38410, 38412],
                    [1643670000, 38336, 38510, 38412, 38467],
                    [1643673600, 38276, 38627, 38467, 38342],
                    [1643677200, 38176, 38407, 38342, 38238],
                    [1643680800, 38197, 38506, 38238, 38481],
                    [1643684400, 38385, 38650, 38481, 38597],
                    [1643688000, 38470, 38645, 38597, 38557],
                    [1643691600, 38444, 38619, 38557, 38453],
                    [1643695200, 38240, 38483, 38453, 38285],
                    [1643698800, 38281, 38463, 38285, 38441],
                    [1643702400, 38399, 38624, 38441, 38598],
                    [1643706000, 38224, 39100, 38598, 38256],
                    [1643709600, 38000, 38367, 38256, 38254],
                    [1643713200, 38205, 38471, 38255, 38425],
                    [1643716800, 38372, 38800, 38425, 38650],
                    [1643720400, 38454, 38966, 38650, 38872],
                    [1643724000, 38305, 39034, 38872, 38509],
                    [1643727600, 38190, 38650, 38509, 38630],
                    [1643731200, 38588, 39265, 38630, 39111],
                    [1643734800, 38655, 39172, 39111, 38867],
                    [1643738400, 38460, 38900, 38868, 38533],
                    [1643742000, 38341, 38596, 38533, 38378],
                    [1643745600, 38369, 38736, 38378, 38512],
                    [1643749200, 38490, 38819, 38513, 38786],
                    [1643752800, 38532, 38853, 38786, 38698],
                    [1643756400, 38602, 38889, 38698, 38695],
                    [1643760000, 38429, 38856, 38695, 38557],
                    [1643763600, 38375, 38669, 38557, 38440],
                    [1643767200, 38385, 38666, 38440, 38623],
                    [1643770800, 38545, 38750, 38620, 38553],
                    [1643774400, 38379, 38555, 38553, 38423],
                    [1643778000, 38300, 38520, 38423, 38416],
                    [1643781600, 38196, 38541, 38416, 38278],
                    [1643785200, 38200, 38388, 38281, 38285],
                    [1643788800, 38225, 38465, 38282, 38447],
                    [1643792400, 38388, 38565, 38448, 38531],
                    [1643796000, 38359, 38598, 38530, 38566],
                    [1643799600, 38427, 38577, 38566, 38490],
                    [1643803200, 38469, 38773, 38490, 38724],
                    [1643806800, 38336, 38772, 38724, 38362],
                    [1643810400, 37611, 38437, 38362, 37704],
                    [1643814000, 37361, 37824, 37704, 37430],
                    [1643817600, 37067, 37436, 37430, 37337],
                    [1643821200, 37269, 37550, 37337, 37470],
                    [1643824800, 37380, 37531, 37470, 37486],
                    [1643828400, 37402, 37674, 37486, 37567],
                    [1643832000, 37400, 37662, 37567, 37566],
                    [1643835600, 36587, 37613, 37566, 36923],
                ],
                type_id: 34,
                module_id: 2,
                theme_id: 1,
                answ_id: 2,
                pointy: 10,
            },
            {
                id: 7,
                bot_id: 9,
                group_id: 517,
                mid: "w9>",
                name: "bitcoin: doji",
                message: [
                    [1644105600, 41340, 41526, 41383, 41479],
                    [1644109200, 41476, 41640, 41479, 41606],
                    [1644112800, 41463, 41628, 41606, 41535],
                    [1644116400, 41431, 41556, 41535, 41444],
                    [1644120000, 41305, 41545, 41444, 41543],
                    [1644123600, 41490, 41587, 41543, 41573],
                    [1644127200, 41402, 41580, 41573, 41451],
                    [1644130800, 41406, 41714, 41451, 41612],
                    [1644134400, 41490, 41661, 41612, 41640],
                    [1644138000, 41580, 41673, 41640, 41649],
                    [1644141600, 41531, 41700, 41649, 41579],
                    [1644145200, 41444, 41628, 41579, 41522],
                    [1644148800, 41488, 41645, 41522, 41611],
                    [1644152400, 41479, 41835, 41611, 41692],
                    [1644156000, 41204, 42168, 41692, 41306],
                    [1644159600, 41117, 41600, 41306, 41519],
                    [1644163200, 41417, 41698, 41519, 41641],
                    [1644166800, 41547, 41747, 41641, 41645],
                    [1644170400, 41427, 41674, 41645, 41509],
                    [1644174000, 41488, 41732, 41509, 41718],
                    [1644177600, 41500, 41748, 41718, 41570],
                    [1644181200, 41535, 41721, 41570, 41650],
                    [1644184800, 41537, 41783, 41650, 41591],
                    [1644188400, 41479, 42656, 41591, 42381],
                    [1644192000, 41646, 42500, 42381, 41831],
                    [1644195600, 41700, 42266, 41831, 42098],
                    [1644199200, 42095, 42695, 42102, 42333],
                    [1644202800, 42332, 42944, 42333, 42819],
                    [1644206400, 42700, 42900, 42819, 42816],
                    [1644210000, 42685, 43000, 42816, 42798],
                    [1644213600, 42653, 42823, 42798, 42749],
                    [1644217200, 42552, 42768, 42749, 42724],
                    [1644220800, 42522, 42768, 42724, 42596],
                    [1644224400, 42234, 42636, 42596, 42411],
                    [1644228000, 42350, 42640, 42411, 42560],
                    [1644231600, 42560, 42755, 42560, 42720],
                    [1644235200, 42490, 42791, 42720, 42640],
                    [1644238800, 42612, 42900, 42640, 42799],
                    [1644242400, 42766, 43475, 42799, 43411],
                    [1644246000, 43105, 43895, 43411, 43836],
                    [1644249600, 43485, 44077, 43836, 43798],
                    [1644253200, 43601, 44313, 43798, 44204],
                    [1644256800, 43708, 44501, 44204, 44065],
                    [1644260400, 43954, 44495, 44065, 44253],
                    [1644264000, 43793, 44439, 44252, 44132],
                    [1644267600, 43770, 44249, 44132, 44085],
                    [1644271200, 43967, 44180, 44085, 44069],
                    [1644274800, 43743, 44191, 44070, 43840],
                    [1644278400, 43556, 43993, 43840, 43850],
                    [1644282000, 43799, 44078, 43850, 44042],
                ],
                type_id: 34,
                module_id: 2,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                id: 8,
                bot_id: 9,
                group_id: 517,
                mid: "rMV",
                name: "bitcoin: doji",
                message: [
                    [1645275600, 39639, 40077, 39755, 40018],
                    [1645279200, 39771, 40018, 40018, 39868],
                    [1645282800, 39804, 40014, 39868, 39970],
                    [1645286400, 39850, 40346, 39970, 39946],
                    [1645290000, 39873, 40168, 39946, 40142],
                    [1645293600, 40044, 40204, 40142, 40115],
                    [1645297200, 39904, 40125, 40115, 39975],
                    [1645300800, 39937, 40107, 39975, 40062],
                    [1645304400, 39800, 40072, 40062, 39922],
                    [1645308000, 39874, 40047, 39922, 40015],
                    [1645311600, 39949, 40140, 40015, 40079],
                    [1645315200, 39876, 40125, 40079, 39925],
                    [1645318800, 39825, 40019, 39925, 39997],
                    [1645322400, 39827, 40020, 39997, 39839],
                    [1645326000, 39700, 39907, 39839, 39795],
                    [1645329600, 39481, 39844, 39795, 39551],
                    [1645333200, 39285, 39699, 39551, 39350],
                    [1645336800, 38688, 39443, 39350, 38795],
                    [1645340400, 38581, 38884, 38795, 38797],
                    [1645344000, 38101, 38836, 38797, 38167],
                    [1645347600, 38000, 38426, 38167, 38185],
                    [1645351200, 38140, 38378, 38185, 38193],
                    [1645354800, 38123, 38358, 38193, 38344],
                    [1645358400, 38201, 38616, 38344, 38213],
                    [1645362000, 38180, 38320, 38213, 38205],
                    [1645365600, 38022, 38300, 38205, 38197],
                    [1645369200, 38180, 38415, 38197, 38335],
                    [1645372800, 38238, 38507, 38337, 38272],
                    [1645376400, 38205, 38400, 38272, 38279],
                    [1645380000, 38180, 38456, 38279, 38441],
                    [1645383600, 38336, 38445, 38441, 38397],
                    [1645387200, 38251, 38401, 38397, 38318],
                    [1645390800, 38048, 38360, 38318, 38240],
                    [1645394400, 38236, 38860, 38240, 38816],
                    [1645398000, 38341, 38979, 38816, 38387],
                    [1645401600, 38183, 38750, 38387, 38741],
                    [1645405200, 38552, 39388, 38740, 39136],
                    [1645408800, 39022, 39247, 39136, 39111],
                    [1645412400, 38801, 39250, 39111, 38919],
                    [1645416000, 38909, 39379, 38919, 39297],
                    [1645419600, 39210, 39494, 39297, 39229],
                    [1645423200, 39151, 39276, 39229, 39204],
                    [1645426800, 39073, 39321, 39204, 39294],
                    [1645430400, 39027, 39362, 39294, 39062],
                    [1645434000, 38874, 39110, 39062, 38924],
                    [1645437600, 38218, 38982, 38924, 38295],
                    [1645441200, 37348, 38437, 38295, 37577],
                    [1645444800, 37401, 37786, 37576, 37540],
                    [1645448400, 37214, 37700, 37540, 37563],
                    [1645452000, 37481, 38872, 37563, 38680],
                ],
                type_id: 34,
                module_id: 2,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                id: 10,
                bot_id: 9,
                group_id: 517,
                mid: "{n(",
                name: "bitcoin: doji",
                message: [
                    [1648861200, 46501, 47074, 46895, 46746],
                    [1648864800, 46234, 46845, 46745, 46498],
                    [1648868400, 46371, 46600, 46498, 46600],
                    [1648872000, 46490, 46684, 46600, 46530],
                    [1648875600, 46411, 46600, 46530, 46541],
                    [1648879200, 46463, 46649, 46541, 46632],
                    [1648882800, 46419, 46644, 46632, 46584],
                    [1648886400, 46490, 46590, 46584, 46551],
                    [1648890000, 46516, 46735, 46551, 46683],
                    [1648893600, 46548, 46719, 46683, 46719],
                    [1648897200, 46543, 46753, 46719, 46543],
                    [1648900800, 46226, 46881, 46543, 46717],
                    [1648904400, 46649, 46941, 46717, 46735],
                    [1648908000, 46572, 46802, 46735, 46617],
                    [1648911600, 46130, 46617, 46617, 46295],
                    [1648915200, 46155, 46425, 46295, 46307],
                    [1648918800, 46252, 46487, 46307, 46359],
                    [1648922400, 45620, 46395, 46359, 45832],
                    [1648926000, 45811, 46252, 45832, 46120],
                    [1648929600, 46027, 46350, 46120, 46181],
                    [1648933200, 45909, 46318, 46181, 46231],
                    [1648936800, 46041, 46333, 46231, 46111],
                    [1648940400, 45757, 46149, 46111, 45811],
                    [1648944000, 45775, 46065, 45811, 46065],
                    [1648947600, 45531, 46066, 46065, 45846],
                    [1648951200, 45760, 45961, 45846, 45777],
                    [1648954800, 45718, 45970, 45777, 45943],
                    [1648958400, 45928, 46364, 45943, 46364],
                    [1648962000, 46225, 46381, 46364, 46356],
                    [1648965600, 46312, 46570, 46356, 46433],
                    [1648969200, 46292, 46466, 46433, 46364],
                    [1648972800, 46219, 46417, 46364, 46272],
                    [1648976400, 46169, 46447, 46272, 46363],
                    [1648980000, 46123, 46400, 46363, 46180],
                    [1648983600, 46077, 46333, 46180, 46253],
                    [1648987200, 46188, 46435, 46253, 46383],
                    [1648990800, 46272, 46670, 46383, 46441],
                    [1648994400, 46302, 46634, 46441, 46634],
                    [1648998000, 46381, 46723, 46634, 46412],
                    [1649001600, 46220, 46519, 46412, 46294],
                    [1649005200, 46240, 46411, 46293, 46395],
                    [1649008800, 46352, 46771, 46395, 46586],
                    [1649012400, 46400, 46699, 46586, 46406],
                    [1649016000, 46364, 46482, 46405, 46365],
                    [1649019600, 46332, 46980, 46365, 46980],
                    [1649023200, 46585, 47444, 46980, 46695],
                    [1649026800, 46249, 46712, 46695, 46407],
                    [1649030400, 45745, 46461, 46407, 45862],
                    [1649034000, 45757, 46049, 45862, 45863],
                    [1649037600, 45757, 46068, 45863, 46037],
                ],
                type_id: 34,
                module_id: 2,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },

            {
                id: 12,
                bot_id: 9,
                group_id: 517,
                mid: "#<t",
                name: "bitcoin: doji",
                message: [
                    [1651190400, 39675, 39869, 39742, 39845],
                    [1651194000, 39770, 39925, 39845, 39820],
                    [1651197600, 39457, 39832, 39820, 39522],
                    [1651201200, 39286, 39558, 39522, 39533],
                    [1651204800, 39369, 39598, 39533, 39543],
                    [1651208400, 39481, 39641, 39543, 39501],
                    [1651212000, 39374, 39574, 39501, 39459],
                    [1651215600, 39413, 39625, 39459, 39610],
                    [1651219200, 39395, 39634, 39610, 39410],
                    [1651222800, 39070, 39410, 39410, 39148],
                    [1651226400, 38666, 39244, 39148, 38805],
                    [1651230000, 38616, 38944, 38805, 38750],
                    [1651233600, 38635, 39136, 38750, 38917],
                    [1651237200, 38800, 39406, 38917, 39277],
                    [1651240800, 38802, 39324, 39277, 38958],
                    [1651244400, 38846, 39144, 38958, 39005],
                    [1651248000, 38435, 39078, 39005, 38522],
                    [1651251600, 38467, 38736, 38522, 38642],
                    [1651255200, 38336, 38673, 38642, 38543],
                    [1651258800, 38175, 38610, 38543, 38332],
                    [1651262400, 38311, 38584, 38332, 38564],
                    [1651266000, 38450, 38650, 38564, 38560],
                    [1651269600, 38430, 38635, 38560, 38609],
                    [1651273200, 38556, 38665, 38609, 38596],
                    [1651276800, 38551, 38795, 38596, 38640],
                    [1651280400, 38630, 38764, 38640, 38702],
                    [1651284000, 38656, 38773, 38702, 38693],
                    [1651287600, 38587, 38706, 38693, 38656],
                    [1651291200, 38494, 38700, 38656, 38553],
                    [1651294800, 38523, 38779, 38553, 38703],
                    [1651298400, 38647, 38735, 38703, 38701],
                    [1651302000, 38634, 38746, 38701, 38650],
                    [1651305600, 38537, 38689, 38650, 38577],
                    [1651309200, 38541, 38644, 38577, 38623],
                    [1651312800, 38564, 38659, 38623, 38566],
                    [1651316400, 38563, 38636, 38566, 38580],
                    [1651320000, 38525, 38610, 38580, 38536],
                    [1651323600, 38501, 38638, 38536, 38506],
                    [1651327200, 38211, 38638, 38506, 38258],
                    [1651330800, 38156, 38530, 38258, 38467],
                    [1651334400, 38404, 38610, 38467, 38426],
                    [1651338000, 38202, 38448, 38426, 38286],
                    [1651341600, 38203, 38354, 38286, 38346],
                    [1651345200, 38303, 38499, 38346, 38467],
                    [1651348800, 38282, 38467, 38467, 38326],
                    [1651352400, 38249, 38371, 38326, 38301],
                    [1651356000, 38160, 38370, 38301, 38250],
                    [1651359600, 37578, 38278, 38250, 37631],
                    [1651363200, 37500, 37807, 37631, 37681],
                    [1651366800, 37386, 38077, 37681, 37937],
                ],
                type_id: 34,
                module_id: 2,
                theme_id: 1,
                answ_id: 1,
                pointy: 10,
            },
            {
                id: 13,
                bot_id: 9,
                group_id: 517,
                mid: "I!U",
                name: "bitcoin: doji",
                message: [
                    [1673114400, 16928, 16940, 16929, 16938],
                    [1673118000, 16935, 16946, 16938, 16944],
                    [1673121600, 16937, 16952, 16944, 16946],
                    [1673125200, 16937, 16950, 16945, 16939],
                    [1673128800, 16929, 16942, 16939, 16936],
                    [1673132400, 16931, 16948, 16936, 16944],
                    [1673136000, 16911, 16955, 16944, 16927],
                    [1673139600, 16915, 16936, 16927, 16930],
                    [1673143200, 16919, 16934, 16930, 16931],
                    [1673146800, 16926, 16945, 16931, 16944],
                    [1673150400, 16934, 16945, 16944, 16935],
                    [1673154000, 16934, 16944, 16935, 16944],
                    [1673157600, 16933, 16952, 16944, 16951],
                    [1673161200, 16943, 16957, 16951, 16954],
                    [1673164800, 16940, 16956, 16954, 16946],
                    [1673168400, 16925, 16968, 16946, 16936],
                    [1673172000, 16933, 16940, 16936, 16936],
                    [1673175600, 16926, 16954, 16936, 16927],
                    [1673179200, 16921, 16933, 16927, 16924],
                    [1673182800, 16920, 16938, 16924, 16933],
                    [1673186400, 16931, 16954, 16933, 16952],
                    [1673190000, 16938, 17020, 16952, 17000],
                    [1673193600, 16916, 17022, 17000, 16937],
                    [1673197200, 16920, 16952, 16937, 16929],
                    [1673200800, 16920, 16936, 16929, 16930],
                    [1673204400, 16924, 16939, 16930, 16927],
                    [1673208000, 16924, 16982, 16927, 16967],
                    [1673211600, 16946, 16988, 16967, 16957],
                    [1673215200, 16953, 16977, 16957, 16963],
                    [1673218800, 16963, 17177, 16963, 17128],
                    [1673222400, 17105, 17199, 17128, 17185],
                    [1673226000, 17146, 17230, 17185, 17175],
                    [1673229600, 17162, 17253, 17175, 17191],
                    [1673233200, 17177, 17217, 17191, 17197],
                    [1673236800, 17194, 17253, 17197, 17226],
                    [1673240400, 17201, 17263, 17227, 17206],
                    [1673244000, 17180, 17221, 17206, 17189],
                    [1673247600, 17184, 17215, 17189, 17198],
                    [1673251200, 17187, 17240, 17198, 17192],
                    [1673254800, 17190, 17270, 17192, 17238],
                    [1673258400, 17236, 17285, 17238, 17265],
                    [1673262000, 17236, 17279, 17264, 17239],
                    [1673265600, 17229, 17255, 17239, 17249],
                    [1673269200, 17191, 17296, 17249, 17227],
                    [1673272800, 17207, 17276, 17227, 17264],
                    [1673276400, 17229, 17335, 17264, 17268],
                    [1673280000, 17268, 17358, 17268, 17328],
                    [1673283600, 17316, 17376, 17328, 17357],
                    [1673287200, 17324, 17399, 17357, 17351],
                    [1673290800, 17293, 17353, 17351, 17294],
                ],
                type_id: 34,
                module_id: 2,
                theme_id: 1,
                answ_id: 2,
                pointy: 10,
            },
        ],
    }),

    actions: {
        async getZip() {
            if (!userStore().student.module_id || userStore().student.module_id < 2) {
                // Восстановим график для каждого объекта в массиве
                const updatedData = this.learn.map((data) => {
                    if (data.type_id == 34) {
                        const updatedM = data.message.map((item) => {
                            return { time: item[0], low: item[1], high: item[2], open: item[3], close: item[4] };
                        });
                        return { ...data, message: updatedM };
                    } else {
                        return { ...data };
                    }
                });

                this.trainings = updatedData;
                return;
            }

            const res = await API.goGET("predict/task", { id: 9, level: 1, fid: 406209800 });
            // console.log("GOGET", res.data);

            // Парсинг JSON-строки в массив объектов
            // let data = JSON.parse(res.data);
            let data = res.data;

            // Восстановим график для каждого объекта в массиве
            const updatedData = data.map((data) => {
                let time = data.time;
                const updatedM = data.message.map((item, index) => {
                    return { time: time + index * 3600, low: item[0], high: item[1], open: item[2], close: item[3] };
                });
                return { ...data, message: updatedM };
            });

            // Обновляем trainings массив с новыми заданиями
            this.trainings = updatedData;
            console.log("trainings", this.trainings);

            return;

            // const res = await API.GET("app/predict/zip2", { id: 9, level: 1, fid: 406209800 });
            // if (!res.data.base64) return;
            // const base64 = res.data.base64;
            // // Декодирование base64 в бинарные данные
            // const binaryString = atob(base64);
            // const len = binaryString.length;
            // const bytes = new Uint8Array(len);
            // for (let i = 0; i < len; i++) {
            //     bytes[i] = binaryString.charCodeAt(i);
            // }
            // // Декомпрессия данных с использованием pako
            // const decompressedBytes = pako.ungzip(bytes);
            // const decompressedString = new TextDecoder().decode(decompressedBytes);
            // // Парсинг JSON-строки в массив объектов
            // let dataArray = JSON.parse(decompressedString);
            // // Восстановим график для каждого объекта в массиве
            // const updatedDataArray = dataArray.map((data) => {
            //     let time = data.t;
            //     const updatedM = data.m.map((item, index) => {
            //         return { time: time + index * 3600, low: item[0], high: item[1], open: item[2], close: item[3] };
            //     });
            //     return { ...data, m: updatedM };
            // });
            // // Обновляем trainings массив с новыми заданиями
            // this.trainings = updatedDataArray;
            // console.log("trainings", this.trainings);
        },

        nextTraining() {
            this.training = {};
            if (!this.trainings || this.trainings.length === 0) return;

            // Фильтрация тренировок, которые соответствуют модулю и теме пользователя, если они не равны null
            let filteredTrainings = this.trainings;

            // Модуль
            if (userStore().student.module_id !== null) {
                filteredTrainings = filteredTrainings.filter((training) => training.module_id === userStore().student.module_id);
            }

            // Тема - в этом проекте нет такого
            // if (userStore().student.theme_id !== null) {
            //     filteredTrainings = filteredTrainings.filter((training) => training.topic_id === userStore().student.theme_id);
            // }

            if (filteredTrainings.length === 0) return;

            let nextTraining = null;

            for (let i = 0; i < filteredTrainings.length; i++) {
                if (!this.answ.includes(filteredTrainings[i]?.mid)) {
                    nextTraining = filteredTrainings[i];
                    break;
                }
            }

            // Все тренировки пройдены или нет подходящей тренировки
            if (!nextTraining) return;

            this.training.mid = nextTraining?.mid;
            this.training.name = nextTraining?.name;
            this.training.a = nextTraining?.answ_id;
            this.training.y = nextTraining?.pointy;
            this.training.n = nextTraining?.pointn ?? 0;
            if (nextTraining.type_id == 34) {
                this.training.graf = nextTraining.message.slice(0, -1);
                this.training.answer = [nextTraining.message[nextTraining.message.length - 1]];
                if (this.training.answer) this.training.time = moment(this.training.answer[0].time * 1000).format("DD.MM.YYYY HH:00:00");
            } else {
                this.training.message = nextTraining.message;
            }
        },

        async getTraining() {
            this.training = {};
            // if (!userStore().student) mainStore().setAlert({ msg: "нет fid", type: "info" });
            console.log("student", userStore().student);
            const res = await API.GET("app/predict/training", { level: 1, fid: 406209800 });
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.training = res.data.training;
        },

        async sendAnswer(answ) {
            this.answ.push(answ.mid);
            this.answers.push(answ);
            // const formData = new FormData();
            // formData.append("fid", 406209800); // userStore().student?.fid
            // formData.append("message_id", this.training?.id);
            // formData.append("answ", answ);

            // const res = await API.POST("app/predict/answer", formData);
            // if (res.data.alert) mainStore().setAlert(res.data.alert);

            // userStore().student = res.data.student;
        },

        async setLevel(level) {
            const formData = new FormData();
            formData.append("message_id", this.training?.id);
            formData.append("level", level);

            const res = await API.POST("app/predict/level", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
        },
    },
});
