import pako from "pako";

export function zip(data) {
    // Преобразование данных в JSON строку
    const jsonString = JSON.stringify(data);

    // Сжатие данных с использованием pako
    const compressedBytes = pako.gzip(jsonString);

    // Кодирование сжатых данных в base64
    const binaryString = String.fromCharCode.apply(null, compressedBytes);
    return btoa(binaryString);
}

export function unzip(data) {
    // Декодирование data в бинарные данные
    const binaryString = atob(data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    // Декомпрессия данных с использованием pako
    const decompressedBytes = pako.ungzip(bytes);
    const decompressedString = new TextDecoder().decode(decompressedBytes);

    // Парсинг JSON-строки в массив объектов
    return JSON.parse(decompressedString);
}
