<template>
    <div
        class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 flex justify-center items-center w-56 h-14 backdrop-blur-sm bg-[#ff0000]/50 text-base rounded-xl transform"
        @click="haptic(), setPopup('tasks')"
    >
        START
    </div>

    <!-- <div class="absolute" style="top: 1218.5px; left: 541.85px; transform: translate(-50%, -50%)">
        <span class="text-white text-4xl font-latobold leading-3">LEVEL</span>
    </div> -->

    <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-1 w-full h-full text-cyan-500">
        <!-- <div class="absolute top-1 left-1 flex justify-center items-center w-30p h-16 text-xl bg-lime-400/20">{{ student?.energy ?? 1230 }}</div> -->
        <!-- <div
            class="absolute top-1/2 translate-y-[75%] left-1/2 -translate-x-1/2 flex flex-col justify-center items-center w-30p h-20 font-latobold text-4xl text-white"
        >
            <span class="text-[8px] leading-3">LEVEL</span>
            {{ 1 }}
        </div> -->

        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            viewBox="0 0 1080 1920"
            stroke="currentColor"
            stroke-linecap="round"
            class="absolute -top-3 left-0 w-full"
        >
            <g id="energy">
                <path fill="none" stroke-width="3" class="line" d="M46.1,107H-1.2" />
                <path fill="none" stroke-width="3" class="line" d="M-1.2,92.2h47.3" />

                <path
                    fill="none"
                    stroke-width="5"
                    d="M54.6,152.8H191c4.7,0,8.5-3.8,8.5-8.5v-13.8h3.5c4.7,0,6.8-3,6.8-7.7v-14.7c0.2-4.7-2-7.7-6.6-7.7h-3.5V87.2 c0-4.7-3.8-8.5-8.5-8.5H54.6c-4.7,0-8.5,3.8-8.5,8.5v57.2C46.1,149,49.9,152.8,54.6,152.8z"
                />

                <g id="battery">
                    <path
                        stroke="none"
                        fill="currentColor"
                        opacity="0.5"
                        d="M69.4,145l9.6-58.5c0-1.8-1.7-3.2-3.8-3.2H56.2c-3,0-5.4,2.4-5.4,5.4v54.1c0,3,2.4,5.4,5.4,5.4h9.3 C67.7,148.2,69.4,146.7,69.4,145z"
                    />
                    <path
                        stroke="none"
                        fill="currentColor"
                        opacity="0.5"
                        d="M96.7,148.2H76.2c-2.1,0-3.8-1.5-3.8-3.2L82,86.5c0-1.8,1.7-3.2,3.8-3.2h20.4c2.1,0,3.8,1.5,3.8,3.2 l-9.6,58.5C100.5,146.7,98.8,148.2,96.7,148.2z"
                    />

                    <path
                        stroke="none"
                        fill="currentColor"
                        opacity="0.5"
                        d="M127.8,148.2h-20.4c-2.1,0-3.8-1.5-3.8-3.2l9.6-58.5c0-1.8,1.7-3.2,3.8-3.2h20.4c2.1,0,3.8,1.5,3.8,3.2 l-9.6,58.5C131.6,146.7,129.9,148.2,127.8,148.2z"
                    />
                    <path
                        stroke="none"
                        fill="currentColor"
                        opacity="0.5"
                        d="M158.9,148.2h-20.4c-2.1,0-3.8-1.5-3.8-3.2l9.6-58.5c0-1.8,1.7-3.2,3.8-3.2h20.4c2.1,0,3.8,1.5,3.8,3.2 l-9.6,58.5C162.7,146.7,161,148.2,158.9,148.2z"
                    />
                    <path
                        stroke="none"
                        fill="currentColor"
                        opacity="0.1"
                        d="M193.8,144.8l0.4-2.4V88.7c0-3-2.4-5.4-5.4-5.4h-9.7c-2.1,0-3.8,1.5-3.8,3.2l-9.6,58.5c0,1.8,1.7,3.2,3.8,3.2 h19.2C191.1,148.2,193,146.8,193.8,144.8z"
                    />
                </g>

                <!-- <g stroke-width="2" text-anchor="middle">
                    <text x="210" y="80" class="text-white text-xl latolight">ENERGY</text>
                    <text x="210" y="140" fill="#fff" class="text-white text-6xl latolight">{{ student?.energy ?? 0 }}</text>
                </g> -->
            </g>
        </svg>

        <!-- balance -->
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            viewBox="0 0 1080 1920"
            fill="none"
            stroke="currentColor"
            stroke-width="5"
            stroke-linecap="round"
            class="absolute -top-10 left-0 w-full"
        >
            <g id="balance">
                <path class="line" stroke-width="3" d="M945.7,299v-87.1c0-12.5,10.2-22.7,22.7-22.7H1080" />

                <path d="M748.9,370.2c-6.3,0-11.3,5.1-11.3,11.3c0,6.3,5.1,11.3,11.3,11.3" />
                <path
                    d="M1004.6,299H778.9c-16.6,0-30,13.4-30,30v2.5c6.3,0,11.3,5.1,11.3,11.3c0,6.3-5.1,11.3-11.3,11.3v16
			c6.3,0,11.3,5.1,11.3,11.3c0,6.3-5.1,11.3-11.3,11.3v4.2c0,16.6,13.4,30,30,30h225.7c16.6,0,30-13.4,30-30V329
			C1034.6,312.4,1021.1,299,1004.6,299z"
                />
                <path d="M748.9,331.5c-6.3,0-11.3,5.1-11.3,11.3c0,6.3,5.1,11.3,11.3,11.3" />

                <path d="M737.5,381.5h-87.1c-12.5,0-22.7-10.2-22.7-22.7V0.2" />
                <path d="M737.5,342.8h-57.1c-6.3,0-11.3-5.1-11.3-11.3V0.2" />

                <g stroke-width="2" text-anchor="middle">
                    <text x="892" y="335" class="text-white text-xl latolight">POINT</text>
                    <text x="892" y="400" fill="#fff" class="text-white text-6xl latolight">{{ student?.point ?? 0 }}</text>
                </g>
            </g>
        </svg>

        <!-- friends -->
        <!-- <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            viewBox="0 0 1080 1920"
            fill="none"
            stroke="currentColor"
            stroke-width="5"
            stroke-linecap="round"
            class="absolute bottom-0 left-0 w-full"
        >
            <g id="friends">
                <path d="M180.6,1904.7" />
                <path d="M309.7,1575.2l37.7,0.2c13.9,0,25.1,11.3,25.1,25.1l0,319.2" />
                <polygon points="278.5,1532.2 93.2,1532.2 60.5,1575.2 93.2,1618.2 278.5,1618.2 311.2,1575.2 	" />
                <polygon points="278.5,1646.1 93.2,1646.1 60.5,1689.1 93.2,1732.1 278.5,1732.1 311.2,1689.1 	" />
                <polygon points="278.5,1760 93.2,1760 60.5,1803 93.2,1846 278.5,1846 311.2,1803 	" />
                <path d="M0,1575.2l62.7,0.2" />
                <path d="M186,1618.2l-0.2,27.9" />
                <path d="M190.9,1730.7l-0.2,27.9" />
            </g>
        </svg> -->

        <!-- staking -->
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            viewBox="0 0 1080 1920"
            fill="none"
            stroke="currentColor"
            stroke-width="5"
            stroke-linecap="round"
            class="absolute bottom-0 left-0 w-full"
        >
            <g id="staking">
                <path d="M896.5,1712.5l0.2-57.7c0-13.9,11.3-25.1,25.1-25.1H1080" />
                <path
                    d="M960.6,1819.5v-65.7c0-16.6-13.4-30-30-30h-22.7c0,6.3-5.1,11.3-11.3,11.3c-6.3,0-11.3-5.1-11.3-11.3h-22.7
			c-16.6,0-30,13.4-30,30v65.7c0,16.6,13.4,30,30,30h68.1C947.2,1849.5,960.6,1836.1,960.6,1819.5z"
                />
                <path d="M907.9,1723.8c0-6.3-5.1-11.3-11.3-11.3c-6.3,0-11.3,5.1-11.3,11.3" />

                <g stroke-width="2" text-anchor="middle" class="relative z-30" @click="haptic(), setPopup('staking')">
                    <text x="897" y="1775" class="text-white text-xl latolight">STAKING</text>
                    <text x="897" y="1820" fill="#fff" class="text-white text-4xl latolight">{{ staking }}</text>
                </g>
            </g>
        </svg>

        <!-- level -->
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            viewBox="0 0 1080 1920"
            fill="none"
            stroke="currentColor"
            stroke-width="5"
            stroke-linecap="round"
            class="absolute top-1/2 -translate-y-1/2 left-0 w-full"
        >
            <g id="level">
                <path d="M392.6,1253.3H192.3c-2.8,0-12.9-10.2-12.9-22.7v-248c0-12.5,10.2-22.7,22.7-22.7h56.1" />
            </g>

            <path id="line2" class="line" stroke-width="3" d="M0,704.6l367.5,0.2c13.9,0,25.1,11.3,25.1,25.1l0,159.6" />
        </svg>

        <!-- level-bg -->

        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            viewBox="0 0 1080 1920"
            fill="none"
            stroke="currentColor"
            stroke-width="5"
            stroke-linecap="round"
            class="absolute top-1/2 -translate-y-1/2 left-0 w-full"
        >
            <g id="level-bg">
                <path
                    fill="rgba(6, 182, 212, 0.2)"
                    d="M676.1,1264.7c-6.3,0-11.3-5.1-11.3-11.3s5.1-11.3,11.3-11.3c0,0,0.1,0,0.1,0v-32.5c0-4.9,3.2-9.1,7.7-10.5
			v-36.7c0-6.1-4.9-11-11-11H407.6c-6.1,0-11,4.9-11,11v36.7c4.5,1.4,7.7,5.6,7.7,10.5v32.5c6.1,0.2,11,5.2,11,11.3
			s-4.9,11.1-11,11.3v32.5c0,4.9-3.2,9.1-7.7,10.5v36.7c0,6.1,4.9,11,11,11h265.2c6.1,0,11-4.9,11-11v-36.7
			c-4.5-1.4-7.7-5.6-7.7-10.5v-32.5C676.1,1264.7,676.1,1264.7,676.1,1264.7z"
                />
                <path d="M404.3,1242c-0.1,0-0.2,0-0.4,0c-6.3,0-11.3,5.1-11.3,11.3s5.1,11.3,11.3,11.3c0.1,0,0.2,0,0.4,0" />
                <path d="M676.2,1264.7c6.2-0.1,11.2-5.1,11.2-11.3s-5-11.3-11.2-11.3" />
                <g stroke-width="2" text-anchor="middle" class="relative z-30" @click="haptic(), setPopup('modules')">
                    <!-- <text x="542" y="1220" class="text-white text-xl">LEVEL</text> -->
                    <text x="542" y="1265" fill="#fff" class="text-white text-3xl">{{ student?.module_name }}</text>
                </g>
            </g>
        </svg>

        <!-- за пределы -->
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            viewBox="0 0 1080 2920"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            class="absolute top-1/2 -translate-y-1/2 left-0 w-full"
        >
            <g id="point1">
                <path
                    d="M648.2,1069.8h-18.4l-9.2,15.9l9.2,15.9h18.4l9.2-15.9L648.2,1069.8z M639,1094.8c-5.1,0-9.2-4.1-9.2-9.2
		s4.1-9.2,9.2-9.2s9.2,4.1,9.2,9.2S644.1,1094.8,639,1094.8z"
                >
                    <animate
                        attributeName="d"
                        values="M648.2,1069.8h-18.4l-9.2,15.9l9.2,15.9h18.4l9.2-15.9L648.2,1069.8z M639,1094.8c-5.1,0-9.2-4.1-9.2-9.2
		s4.1-9.2,9.2-9.2s9.2,4.1,9.2,9.2S644.1,1094.8,639,1094.8z;M831.6,1069.8h-18.4l-9.2,15.9l9.2,15.9h18.4l9.2-15.9L831.6,1069.8z M822.4,1094.8c-5.1,0-9.2-4.1-9.2-9.2
		s4.1-9.2,9.2-9.2s9.2,4.1,9.2,9.2S827.5,1094.8,822.4,1094.8z"
                        begin="3s"
                        dur="5s"
                        fill="freeze"
                    />
                </path>

                <path d="M658.3,1085.6l422.3,0.2">
                    <animate attributeName="d" values="M658.3,1085.6l422.3,0.2;M840.7,1085.6l239.9,0.2" begin="3s" dur="5s" fill="freeze" />
                </path>

                <path d="M439.4,1389.5v-281.2c0-12.5,10.2-22.7,22.7-22.7h160.3">
                    <animate
                        attributeName="d"
                        values="M439.4,1389.5v-281.2c0-12.5,10.2-22.7,22.7-22.7h160.3;M439.4,1389.5v-281.2c0-12.5,10.2-22.7,22.7-22.7H804"
                        begin="3s"
                        dur="5s"
                        fill="freeze"
                    />
                </path>

                <path d="M482.8,1085.8V-303.7" />
            </g>

            <path id="line4" d="M687.4,1753.3l37.7,0.2c13.9,0,25.1,11.3,25.1,25.1l0,1141.3" />
        </svg>

        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            viewBox="0 0 1080 1920"
            fill="none"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
            class="absolute top-0 left-0 w-full"
        >
            <path id="line1" class="line" d="M1138.2,1486.6H919.2c-12.5,0-22.7-10.2-22.7-22.7V0" />

            <!-- <path d="M0,783.6h388.8c54.7,0,99.1,44.4,99.1,99.1v304.1">
                <animate
                    attributeName="d"
                    values="M0,783.6h388.8c54.7,0,99.1,44.4,99.1,99.1v304.1; M0,1025.7h388.8c54.7,0,99.1,44.4,99.1,99.1v62"
                    begin="2s"
                    dur="1s"
                    fill="freeze"
                />
            </path> -->
        </svg>
    </div>

    <!-- Пример циклической анимции -->
    <!-- <path d="M220.9,165.6c0,6.3,5.1,11.3,11.3,11.3c6.3,0,11.3-5.1,11.3-11.3">
        <animate
            attributeName="d"
            values="M220.9,165.6c0,6.3,5.1,11.3,11.3,11.3c6.3,0,11.3-5.1,11.3-11.3;
                M70.9,165.6c0,6.3,5.1,11.3,11.3,11.3c6.3,0,11.3-5.1,11.3-11.3;
                M70.9,165.6c0,6.3,5.1,11.3,11.3,11.3c6.3,0,11.3-5.1,11.3-11.3;
                M220.9,165.6c0,6.3,5.1,11.3,11.3,11.3c6.3,0,11.3-5.1,11.3-11.3;
                M220.9,165.6c0,6.3,5.1,11.3,11.3,11.3c6.3,0,11.3-5.1,11.3-11.3"
            begin="2s"
            dur="10s"
            repeatCount="indefinite"
            keyTimes="0; 0.1; 0.4; 0.5; 1"
        />
    </path> -->
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";
import { usePredictorStore } from "@/store/predictor";

export default {
    name: "StartPage",

    data() {
        return {
            vivusInstance: null,
            list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "haptic"]),
        ...mapState(useUserStore, ["student"]),
        ...mapState(usePredictorStore, ["staking"]),
    },
};
</script>

<style scoped>
.path,
.line {
    stroke-dasharray: 20;
    stroke-dashoffset: 40;
    animation: draw 3s linear infinite;
    opacity: 0.5;
}

@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}
</style>
