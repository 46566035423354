<template>
    <div class="p-4">
        <!-- <div class="w-full flex justify-start items-center gap-2">
            <img
                v-if="coin && coin?.pic"
                class="h-8 my-2 aspect-square object-contain"
                :src="require('@/assets/images/' + coin?.pic)"
                :alt="coin?.name"
            />
            <div class="w-full text-white/70 text-lg">{{ coin?.name }}</div>
        </div> -->

        <!-- <div class="w-full text-white/70 text-sm border-b border-cyan-600/50 pb-2 mb-4">{{ title[Math.floor(Math.random() * title?.length)] }}</div> -->

        <template v-if="training?.graf">
            <img class="w-8 h-8 mx-auto object-contain" :src="require('@/assets/images/bitcoin.png')" alt="bitcoin" />

            <div class="w-fit mx-auto mt-4">{{ training.time }}</div>

            <CandleAnimatedGraf :candles="training?.graf" :answer="training?.answer" />

            <div v-if="!modal && !s.hidden" class="w-full grid grid-cols-2 gap-2 mt-4 place-items-center">
                <div class="btn btn-green w-20" @click="showAnswer(1)">UP</div>
                <div class="btn btn-red w-20" @click="showAnswer(2)">DOWN</div>
            </div>
        </template>

        <template v-else>
            <!-- {{ training }} -->

            <template v-if="training.mid == '001'">
                <div class="text-xl text-center font-black mb-4">Это молот</div>

                <div class="flex justify-center items-center">
                    <svg width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                        <line x1="50" y1="20" x2="50" y2="140" stroke="rgb(190, 18, 60)" stroke-width="2" />
                        <rect x="40" y="40" width="20" height="100" fill="rgb(190, 18, 60)" stroke="rgb(190, 18, 60)" stroke-width="2" />

                        <line x1="100" y1="120" x2="100" y2="200" stroke="rgb(14, 116, 144)" stroke-width="2" />
                        <rect x="90" y="120" width="20" height="20" fill="rgb(14, 116, 144)" stroke="rgb(14, 116, 144)" stroke-width="2" />

                        <line x1="150" y1="30" x2="150" y2="150" stroke="rgb(14, 116, 144)" stroke-width="2" />
                        <rect x="140" y="40" width="20" height="80" fill="rgb(14, 116, 144)" stroke="rgb(14, 116, 144)" stroke-width="2" />
                    </svg>
                </div>

                <div class="px-8">
                    <h2 class="text-xl mt-8">Описание</h2>
                    <p>Маленькое тело свечи с длинной нижней тенью (в два или три раза длиннее тела). Верхняя тень практически отсутствует.</p>

                    <h2 class="text-xl mt-8">Сигнал</h2>
                    <p>Разворот тренда вверх. Появляется на дне нисходящего тренда.</p>
                </div>

                <div class="my-8 mx-auto btn bg-cyan-600/50 w-fit text-xs text-white" @click="next(training)">Next</div>
            </template>

            <template v-if="training.mid == '002'">
                <div class="text-xl text-center font-black mb-4">Это тоже молот</div>

                <div class="flex justify-center items-center">
                    <svg width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                        <line x1="50" y1="20" x2="50" y2="140" stroke="rgb(190, 18, 60)" stroke-width="2" />
                        <rect x="40" y="40" width="20" height="80" fill="rgb(190, 18, 60)" stroke="rgb(190, 18, 60)" stroke-width="2" />

                        <!-- Hammer Candle -->
                        <line x1="100" y1="120" x2="100" y2="200" stroke="rgb(190, 18, 60)" stroke-width="2" />
                        <rect x="90" y="120" width="20" height="20" fill="rgb(190, 18, 60)" stroke="rgb(190, 18, 60)" stroke-width="2" />

                        <line x1="150" y1="30" x2="150" y2="150" stroke="rgb(14, 116, 144)" stroke-width="2" />
                        <rect x="140" y="60" width="20" height="80" fill="rgb(14, 116, 144)" stroke="rgb(14, 116, 144)" stroke-width="2" />
                    </svg>
                </div>

                <div class="px-8">
                    <h2 class="text-xl mt-8">Описание</h2>
                    <p>Все как в предыдущем молоте, только тело красное.</p>

                    <h2 class="text-xl mt-8">Сигнал</h2>
                    <p>Разворот тренда вверх. Появляется на дне нисходящего тренда.</p>
                </div>

                <div class="my-4 mx-auto btn bg-cyan-600/50 w-fit text-xs text-white" @click="next(training)">Next</div>
            </template>

            <template v-if="training.mid == '003'">
                <div class="text-xl text-center font-black mb-4">Медвежий молот</div>

                <div class="flex justify-center items-center">
                    <svg width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                        <line x1="50" y1="10" x2="50" y2="140" stroke="rgb(14, 116, 144)" stroke-width="2" />
                        <rect x="40" y="20" width="20" height="120" fill="rgb(14, 116, 144)" stroke="rgb(14, 116, 144)" stroke-width="2" />

                        <!-- Hammer Candle -->
                        <line x1="100" y1="20" x2="100" y2="100" stroke="rgb(190, 18, 60)" stroke-width="2" />
                        <rect x="90" y="20" width="20" height="25" fill="rgb(190, 18, 60)" stroke="rgb(190, 18, 60)" stroke-width="2" />

                        <line x1="150" y1="30" x2="150" y2="150" stroke="rgb(190, 18, 60)" stroke-width="2" />
                        <rect x="140" y="45" width="20" height="80" fill="rgb(190, 18, 60)" stroke="rgb(190, 18, 60)" stroke-width="2" />
                    </svg>
                </div>

                <div class="px-8">
                    <h2 class="text-xl mt-8">Описание</h2>
                    <p>Маленькое тело свечи с длинной нижней тенью (в два или три раза длиннее тела). Верхняя тень практически отсутствует.</p>

                    <h2 class="text-xl mt-8">Сигнал</h2>
                    <p>Разворот тренда вниз. Появляется на вершине восходящего тренда.</p>
                </div>

                <div class="my-4 mx-auto btn bg-cyan-600/50 w-fit text-xs text-white" @click="next(training)">Next</div>
            </template>

            <template v-if="training.mid == '004'">
                <div class="text-xl text-center font-black mb-4">Готов к задаче посложнее?</div>

                <p>Обрати внимание, что если перед молотом нет ярко выраженного тренда, то молот не работает</p>

                <div class="my-4 mx-auto btn bg-cyan-600/50 w-fit text-xs text-white" @click="next(training)">Next</div>
            </template>

            <template v-if="training.mid == '005'">
                <div class="text-xl text-center font-black mb-4">Отлично! Первый уровень пройден</div>

                <div class="my-4 mx-auto btn bg-cyan-600/50 w-fit text-xs text-white" @click="setModule(2), next(training)">Next</div>
            </template>

            <template v-if="training.mid == '201'">
                <div class="text-xl text-center font-black mb-4">Это Doji</div>

                <div class="flex justify-center items-center">
                    <svg width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                        <line x1="50" y1="20" x2="50" y2="130" stroke="rgb(14, 116, 144)" stroke-width="2" />
                        <rect x="40" y="40" width="20" height="70" fill="rgb(14, 116, 144)" stroke="rgb(14, 116, 144)" stroke-width="2" />

                        <line x1="100" y1="10" x2="100" y2="100" stroke="rgb(14, 116, 144)" stroke-width="2" />
                        <rect x="90" y="40" width="20" height="1" fill="rgb(14, 116, 144)" stroke="rgb(14, 116, 144)" stroke-width="1" />

                        <line x1="150" y1="40" x2="150" y2="160" stroke="rgb(190, 18, 60)" stroke-width="2" />
                        <rect x="140" y="40" width="20" height="80" fill="rgb(190, 18, 60)" stroke="rgb(190, 18, 60)" stroke-width="2" />
                    </svg>
                </div>

                <div class="px-8">
                    <h2 class="text-xl mt-8">Описание</h2>
                    <p>
                        Цена открытия и закрытия практически одинаковы, образуя очень маленькое тело свечи или его отсутствие. Свеча имеет длинные
                        верхние и/или нижние тени.
                    </p>

                    <h2 class="text-xl mt-8">Сигнал</h2>
                    <p>
                        Неопределенность на рынке. Может сигнализировать о развороте тренда, особенно если появляется после значительного движения
                        цены.
                    </p>
                </div>

                <div class="my-8 mx-auto btn bg-cyan-600/50 w-fit text-xs text-white" @click="next(training)">Next</div>
            </template>

            <template v-if="training.mid == '202'">
                <div class="text-xl text-center font-black mb-4">Виды Doji</div>

                <p class="text-center px-8">Помимо обычного доджи есть вариации</p>

                <h2 class="text-center text-xl px-8 my-4">Стрекоза (Dragonfly Doji)</h2>

                <div class="flex justify-center items-center">
                    <svg width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                        <line x1="50" y1="20" x2="50" y2="130" stroke="rgb(14, 116, 144)" stroke-width="2" />
                        <rect x="40" y="40" width="20" height="70" fill="rgb(14, 116, 144)" stroke="rgb(14, 116, 144)" stroke-width="2" />

                        <line x1="100" y1="10" x2="100" y2="100" stroke="rgb(14, 116, 144)" stroke-width="2" />
                        <rect x="90" y="10" width="20" height="1" fill="rgb(14, 116, 144)" stroke="rgb(14, 116, 144)" stroke-width="1" />

                        <line x1="150" y1="40" x2="150" y2="160" stroke="rgb(190, 18, 60)" stroke-width="2" />
                        <rect x="140" y="40" width="20" height="80" fill="rgb(190, 18, 60)" stroke="rgb(190, 18, 60)" stroke-width="2" />
                    </svg>
                </div>

                <h2 class="text-center text-xl px-8 my-4">Надгробие (Gravestone Doji)</h2>

                <div class="flex justify-center items-center">
                    <svg width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                        <line x1="50" y1="20" x2="50" y2="130" stroke="rgb(14, 116, 144)" stroke-width="2" />
                        <rect x="40" y="40" width="20" height="70" fill="rgb(14, 116, 144)" stroke="rgb(14, 116, 144)" stroke-width="2" />

                        <line x1="100" y1="10" x2="100" y2="100" stroke="rgb(14, 116, 144)" stroke-width="2" />
                        <rect x="90" y="100" width="20" height="1" fill="rgb(14, 116, 144)" stroke="rgb(14, 116, 144)" stroke-width="1" />

                        <line x1="150" y1="40" x2="150" y2="160" stroke="rgb(190, 18, 60)" stroke-width="2" />
                        <rect x="140" y="40" width="20" height="80" fill="rgb(190, 18, 60)" stroke="rgb(190, 18, 60)" stroke-width="2" />
                    </svg>
                </div>

                <div class="my-8 mx-auto btn bg-cyan-600/50 w-fit text-xs text-white" @click="next(training)">Next</div>
            </template>

            <template v-if="training.mid == '203'">
                <div class="text-xl text-center font-black mb-4">Spinning Top Doji</div>

                <div class="flex justify-center items-center">
                    <svg width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                        <line x1="50" y1="20" x2="50" y2="130" stroke="rgb(14, 116, 144)" stroke-width="2" />
                        <rect x="40" y="40" width="20" height="70" fill="rgb(14, 116, 144)" stroke="rgb(14, 116, 144)" stroke-width="2" />

                        <line x1="100" y1="10" x2="100" y2="100" stroke="rgb(14, 116, 144)" stroke-width="2" />
                        <rect x="90" y="40" width="20" height="10" fill="rgb(14, 116, 144)" stroke="rgb(14, 116, 144)" stroke-width="2" />

                        <line x1="150" y1="40" x2="150" y2="160" stroke="rgb(190, 18, 60)" stroke-width="2" />
                        <rect x="140" y="40" width="20" height="80" fill="rgb(190, 18, 60)" stroke="rgb(190, 18, 60)" stroke-width="2" />
                    </svg>
                </div>

                <div class="px-8">
                    <h2 class="text-xl mt-8">Описание</h2>
                    <p>Цена открытия и закрытия создают маленькое тело свечи. Свеча имеет длинные тени как сверху, так и снизу.</p>

                    <h2 class="text-xl mt-8">Сигнал</h2>
                    <p>Неопределенность на рынке. Может сигнализировать о развороте тренда или о начале консолидации (бокового движения).</p>
                </div>

                <div class="my-8 mx-auto btn bg-cyan-600/50 w-fit text-xs text-white" @click="next(training)">Next</div>
            </template>
        </template>

        <div v-if="modal == 'y'" class="text-center text-white/70 mt-2 text-sm flex flex-col items-center gap-2">
            <p>Congratulations, you've won!</p>

            <Coin :size="100" :text="String(training.y)" />

            <!-- <div
                class="w-24 h-24 p-4 bg-cyan-600/10 border border-cyan-600/50 rounded-full text-2xl flex justify-center items-center text-cyan-600 font-latobold"
            >
                + {{ training.y }}
            </div> -->

            <div class="my-8 mx-auto btn bg-cyan-600/50 w-fit text-xs text-white" @click="again()">Next</div>
        </div>

        <div v-if="modal == 'n'" class="text-center text-white/70 mt-2 text-sm flex flex-col items-center gap-2">
            <p>Nooooo, you've lost!</p>
            <div
                class="w-20 h-20 p-4 bg-red-600/10 border border-red-600/50 rounded-full text-2xl flex justify-center items-center text-red-600 font-latobold"
            >
                {{ training.n }}
            </div>

            <div class="my-8 mx-auto btn bg-cyan-600/50 w-fit text-xs text-white" @click="again()">Next</div>
        </div>
    </div>

    <!-- <div v-if="student.fid == 406209800" class="absolute bottom-4 w-full p-4">
        <div class="w-full grid grid-cols-4 gap-2 place-items-center">
            <div class="btn bg-cyan-600/20 w-full text-xs text-white/50" @click="setLevel(1)">Просто</div>
            <div class="btn bg-cyan-600/20 w-full text-xs text-white/50" @click="setLevel(2)">Средне</div>
            <div class="btn bg-cyan-600/20 w-full text-xs text-white/50" @click="setLevel(3)">Сложно</div>
            <div class="btn bg-cyan-600/20 w-full text-xs text-white/50" @click="setLevel(100)">Удалить</div>
        </div>
    </div> -->
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";
import { usePredictorStore } from "@/store/predictor";
import confetti from "canvas-confetti";

import CandleAnimatedGraf from "../components/CandleAnimatedGraf.vue";
import Coin from "../components/Coin.vue";

export default {
    name: "GrafPage",

    data() {
        return {
            modal: false,
            title: [
                "Predict the future",
                "See what's on the horizon",
                "Choose your option",
                "Decide where the chart will go",
                "Divine what's coming next",
                "Look into your crystal ball",
                "Decide the graph's trajectory",
                "Make your selection",
                "Chart the course ahead",
            ],
        };
    },

    components: { CandleAnimatedGraf, Coin },

    computed: {
        ...mapState(useMainStore, ["s", "auction", "setPopup", "setAnswer", "haptic"]),
        ...mapState(useUserStore, ["student", "setModule"]),
        ...mapState(usePredictorStore, ["coin", "training", "setCoin", "nextTraining", "sendAnswer", "setLevel"]),
    },

    methods: {
        showAnswer(answ) {
            this.haptic();
            this.sendAnswer({ mid: this.training.mid, answ: answ });
            this.setAnswer(true);
            if (answ == this.training.a) {
                this.modal = "y";
                confetti({ particleCount: 200, spread: 360, startVelocity: 30, origin: { x: 0.5, y: 0.5 } });
            } else {
                this.modal = "n";
            }
        },

        again() {
            this.haptic();
            this.modal = false;
            this.setAnswer(false);
            this.nextTraining();
        },

        next(training) {
            this.haptic();
            this.sendAnswer(training);
            this.nextTraining();
        },
    },

    mounted() {
        this.nextTraining();
    },
};
</script>
