<template>
    <svg
        class="fill-stroke"
        :class="'w-' + size + ' h-' + size"
        :width="size * 4"
        :height="size * 4"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M2 2V19C2 20.66 3.34 22 5 22H22" />
        <path d="M5 17L9.59 11.64C10.35 10.76 11.7 10.7 12.52 11.53L13.47 12.48C14.29 13.3 15.64 13.25 16.4 12.37L21 7" />
    </svg>
</template>

<script>
export default {
    name: "IconGraf",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
