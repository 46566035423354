<template>
    <div class="px-4 h-full overflow-hidden">
        <div class="h-[calc(100vh-124px)] flex flex-col justify-center items-center bg-white text-black text-center p-4 rounded-lg relative">
            <template v-if="!done">
                <!-- <div class="absolute top-10 left-8 right-8 flex justify-center flex-wrap items-center mb-16 gap-px">
                    <div v-for="(item, i) in result" :key="i" class="h-4 mb-1 relative">
                        <span class="absolute -top-[9px] left-0 w-full text-[6px] text-slate-400">{{ item.q }}</span>
                        <div class="w-6 h-2 rounded" :class="[item.y ? 'bg-green-400' : 'bg-rose-400']"></div>
                    </div>
                </div> -->

                <div class="absolute top-10 left-8 right-8 flex justify-center flex-wrap items-center mb-16">
                    <div v-for="(item, i) in this.all.results" :key="i" class="h-3 relative" :style="{ width: `${100 / this.all.results.length}%` }">
                        <div class="h-2 rounded" :class="[item.y ? 'bg-green-400' : 'bg-rose-400']"></div>
                    </div>
                </div>

                <!-- <div class="absolute top-10 left-0 w-full">
                    <p v-if="answId == q.answ" class="whitespace-pre-wrap mb-16">Да 😉</p>
                    <p v-else class="whitespace-pre-wrap mb-16">Ну, нет</p>
                </div> -->

                <!-- <div>{{ timeLeft }} сек</div> -->

                <div class="text-5xl whitespace-pre-wrap mb-4 mt-20">{{ q.qest }}</div>
                <div
                    @click="clearNumber(), haptic()"
                    class="flex justify-center items-center gap-4 text-slate-500 text-2xl whitespace-pre-wrap h-8 mb-16"
                >
                    {{ enteredNumber }}
                </div>

                <div class="grid grid-cols-3 gap-4 mb-4">
                    <button
                        v-for="n in [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]"
                        :key="n"
                        @click="addNumber(n), haptic()"
                        class="w-full bg-sky-500 rounded-full text-white text-2xl py-2 px-6"
                    >
                        {{ n }}
                    </button>
                    <!-- <button @click="setAnsw(), haptic()" class="col-span-2 w-full bg-green-500 rounded-full text-white text-xl py-2 px-6">
                        {{ $t("answer") }}
                    </button> -->

                    <button
                        @click="setAnsw(), haptic()"
                        class="col-span-2 w-full relative bg-gray-300 rounded-full text-white text-xl py-2 px-6 overflow-hidden"
                    >
                        <div
                            :style="{ width: `${progressBarWidth}%` }"
                            class="absolute top-0 left-0 h-full bg-green-500 transition-all duration-100"
                        ></div>
                        <span class="relative z-10">{{ $t("answer") }}</span>
                    </button>
                </div>
            </template>

            <template v-else>
                <!-- <h2 class="text-3xl font-bold mb-8">{{ $t("gameover") }}</h2> -->

                <div class="w-full grid grid-cols-2 mb-8">
                    <div class="flex flex-col text-xl text-green-500 mb-2">
                        <div class="text-5xl">{{ correctAnswers }}</div>
                        Верно
                    </div>
                    <div class="flex flex-col text-xl text-rose-500 mb-4">
                        <div class="text-5xl">{{ incorrectAnswers }}</div>
                        Ошибки
                    </div>
                </div>
                <button @click="restartGame()" class="bg-sky-500 text-white py-2 px-4 rounded">Начать заново</button>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useUserStore } from "@/store/user";
import { useMathStore } from "@/store/math";

export default {
    name: "StartPage",

    data() {
        return {
            done: false,
            timeLeft: 0, // Оставшееся время
            timer: null, // Ссылка на таймер
            enteredNumber: "",
            answId: null,
            next: 0,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "haptic"]),
        ...mapState(useUserStore, ["student"]),
        ...mapState(useMathStore, ["all", "quests", "addPoints", "addToResults", "clearResults"]),

        filteredQuests() {
            // Найти объект с нужным типом игры
            let gameQuests = this.quests.find((q) => q[this.all.game]);

            if (!gameQuests || !gameQuests[this.all.game]) return [];

            // Фильтровать задания по модулям
            return gameQuests[this.all.game].filter((q) => this.all.modules.includes(q.module));
        },

        q() {
            if (this.filteredQuests.length > 0) {
                return this.filteredQuests[this.next];
            }
            return { qest: "", answ: null }; // Обработка случая отсутствия вопросов
        },

        correctAnswers() {
            return this.all.results.filter((item) => item.y).length;
        },

        incorrectAnswers() {
            return this.all.results.filter((item) => !item.y).length;
        },

        progressBarWidth() {
            return (this.timeLeft / this.all.timeOne) * 100;
        },
    },

    methods: {
        // startTimer() {
        //     this.timeLeft = this.all.timeOne;
        //     if (this.timer) clearInterval(this.timer);

        //     this.timer = setInterval(() => {
        //         if (this.timeLeft > 0) {
        //             this.timeLeft--;
        //         } else {
        //             this.handleTimeout();
        //         }
        //     }, 1000);
        // },

        startTimer() {
            this.timeLeft = this.all.timeOne;
            if (this.timer) clearInterval(this.timer);

            this.timer = setInterval(() => {
                if (this.timeLeft > 0) {
                    this.timeLeft = parseFloat(this.timeLeft - 0.1).toFixed(2); // Уменьшение на 0.1 секунды для более плавного обновления
                } else {
                    this.handleTimeout();
                }
            }, 100); // Обновление каждые 100 миллисекунд
        },

        handleTimeout() {
            clearInterval(this.timer);
            this.addToResults({ q: this.q.qest, a: null, y: false });
            this.timeLeft = 0;
        },

        // использование
        addNumber(number) {
            this.enteredNumber += number;
        },

        clearNumber() {
            this.enteredNumber = "";
        },

        setAnsw() {
            if (this.enteredNumber === "") return; // Пустой ответ не считается

            clearInterval(this.timer); // Остановить таймер, так как ответ был дан

            // Если текущий вопрос уже записан в result, не записывать ответ повторно
            const existingIndex = this.all.results.findIndex((item) => item.q === this.q.qest);

            if (parseInt(this.enteredNumber) === this.q.answ) {
                // Если ответ правильный
                if (existingIndex === -1) this.addToResults({ q: this.q.qest, a: this.enteredNumber, y: true });
                this.answId = this.q.answ;
                this.enteredNumber = "";
                this.chooseNextQuestion();
                this.addPoints(this.q.point);
            } else {
                // Если ответ неправильный
                if (existingIndex === -1) this.addToResults({ q: this.q.qest, a: this.enteredNumber, y: false });
                this.enteredNumber = "";
                this.answId = null; // Чтобы оставаться на том же вопросе
            }
        },

        chooseNextQuestion() {
            this.startTimer(); // Перезапуск таймера

            if (this.repeat) {
                this.next = Math.floor(Math.random() * this.filteredQuests.length);
            } else {
                const answeredQ = this.all.results.map((res) => res.q);
                const availableQ = this.filteredQuests.filter((q) => !answeredQ.includes(q.qest));
                if (availableQ.length > 0) {
                    this.next = this.filteredQuests.indexOf(availableQ[Math.floor(Math.random() * availableQ.length)]);
                } else {
                    this.done = true; // Завершение тренировки
                }
            }
        },

        restartGame() {
            this.done = false;
            this.startTimer(); // Перезапуск таймера
            this.clearResults();
        },
    },

    mounted() {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        this.startTimer(); // Запуск таймера при загрузке компонента
        this.chooseNextQuestion();
    },

    beforeUnmount() {
        clearInterval(this.timer); // Очищение таймера при выходе из компонента
    },
};
</script>
