<template>
    <svg
        v-if="name == 'bot'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path d="M9 16C9.85038 16.6303 10.8846 17 12 17C13.1154 17 14.1496 16.6303 15 16" />
        <circle cx="15" cy="10.5" r="1" />
        <circle cx="9" cy="10.5" r="1" />
        <path d="M22 14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22" />
        <path d="M10 22C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14" />
        <path d="M10 2C6.22876 2 4.34315 2 3.17157 3.17157C2 4.34315 2 6.22876 2 10" />
        <path d="M14 2C17.7712 2 19.6569 2 20.8284 3.17157C22 4.34315 22 6.22876 22 10" />
    </svg>

    <svg
        v-if="name == 'bot1'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path d="M9 16C9.85038 16.6303 10.8846 17 12 17C13.1154 17 14.1496 16.6303 15 16" />
        <path
            d="M22 12.2039V13.725C22 17.6258 22 19.5763 20.8284 20.7881C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.7881C2 19.5763 2 17.6258 2 13.725V12.2039C2 9.91549 2 8.77128 2.5192 7.82274C3.0384 6.87421 3.98695 6.28551 5.88403 5.10813L7.88403 3.86687C9.88939 2.62229 10.8921 2 12 2C13.1079 2 14.1106 2.62229 16.116 3.86687L18.116 5.10812C20.0131 6.28551 20.9616 6.87421 21.4808 7.82274"
        />
    </svg>

    <svg
        v-if="name == 'ads'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path
            d="M20.965 7C20.8873 5.1277 20.6366 3.97975 19.8284 3.17157C18.6569 2 16.7712 2 13 2H11C7.22876 2 5.34315 2 4.17157 3.17157C3 4.34315 3 6.22876 3 10V14C3 17.7712 3 19.6569 4.17157 20.8284C5.34315 22 7.22876 22 11 22H13C16.7712 22 18.6569 22 19.8284 20.8284C21 19.6569 21 17.7712 21 14V11"
        />
        <path
            d="M6 12C6 10.5858 6 9.87868 6.43934 9.43934C6.87868 9 7.58579 9 9 9H15C16.4142 9 17.1213 9 17.5607 9.43934C18 9.87868 18 10.5858 18 12V16C18 17.4142 18 18.1213 17.5607 18.5607C17.1213 19 16.4142 19 15 19H9C7.58579 19 6.87868 19 6.43934 18.5607C6 18.1213 6 17.4142 6 16V12Z"
        />
        <path d="M7 6H12" />
    </svg>

    <svg
        v-if="name == 'calendar'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path
            d="M14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C22 6.34315 22 8.22876 22 12V14C22 17.7712 22 19.6569 20.8284 20.8284C20.1752 21.4816 19.3001 21.7706 18 21.8985"
        />
        <path d="M7 4V2.5" />
        <path d="M17 4V2.5" />
        <path d="M21.5 9H16.625H10.75M2 9H5.875" />
        <path d="M18 17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17C16 16.4477 16.4477 16 17 16C17.5523 16 18 16.4477 18 17Z" />
        <path d="M18 13C18 13.5523 17.5523 14 17 14C16.4477 14 16 13.5523 16 13C16 12.4477 16.4477 12 17 12C17.5523 12 18 12.4477 18 13Z" />
        <path d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z" />
        <path d="M13 13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13C11 12.4477 11.4477 12 12 12C12.5523 12 13 12.4477 13 13Z" />
        <path d="M8 17C8 17.5523 7.55228 18 7 18C6.44772 18 6 17.5523 6 17C6 16.4477 6.44772 16 7 16C7.55228 16 8 16.4477 8 17Z" fill="#1C274C" />
        <path d="M8 13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13C6 12.4477 6.44772 12 7 12C7.55228 12 8 12.4477 8 13Z" fill="#1C274C" />
    </svg>

    <svg
        v-if="name == 'chat'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 800 800"
        fill="none"
        stroke="currentColor"
        stroke-width="50"
        stroke-linecap="round"
    >
        <path d="M291.7,247.4 M291.7,385.9" />
        <path d="M266.4,303.1H533" />
        <path d="M266.4,419.7h183.3" />
        <path
            d="M66.4,400.5c0-157.1,0-235.7,48.8-284.5C164,67.1,242.6,67.1,399.7,67.1c157.1,0,235.7,0,284.5,48.8
	c46,46,48.8,125.5,48.8,284.5c0,42.7,1.9,180.4-48.8,222c-52.5,43.1-146,38.9-199.5,42.2l-173.2-2c-105.2-7.6-177.3,75.3-220.2,46.4
	C51,681.9,74,630.9,68.2,533.8"
        />
    </svg>

    <svg
        v-if="name == 'edit'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path
            d="M14.3601 4.07866L15.2869 3.15178C16.8226 1.61607 19.3125 1.61607 20.8482 3.15178C22.3839 4.68748 22.3839 7.17735 20.8482 8.71306L19.9213 9.63993M14.3601 4.07866C14.3601 4.07866 14.4759 6.04828 16.2138 7.78618C17.9517 9.52407 19.9213 9.63993 19.9213 9.63993M14.3601 4.07866L12 6.43872M19.9213 9.63993L14.6607 14.9006L11.5613 18L11.4001 18.1612C10.8229 18.7383 10.5344 19.0269 10.2162 19.2751C9.84082 19.5679 9.43469 19.8189 9.00498 20.0237C8.6407 20.1973 8.25352 20.3263 7.47918 20.5844L4.19792 21.6782M4.19792 21.6782L3.39584 21.9456C3.01478 22.0726 2.59466 21.9734 2.31063 21.6894C2.0266 21.4053 1.92743 20.9852 2.05445 20.6042L2.32181 19.8021M4.19792 21.6782L2.32181 19.8021M2.32181 19.8021L3.41556 16.5208C3.67368 15.7465 3.80273 15.3593 3.97634 14.995C4.18114 14.5653 4.43213 14.1592 4.7249 13.7838C4.97308 13.4656 5.26166 13.1771 5.83882 12.5999L8.5 9.93872"
        />
    </svg>

    <svg
        v-if="name == 'equal2'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path d="M16 10H8M10 0" />
        <path d="M8 14L16 14M10 0" />

        <path
            d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
        />
    </svg>

    <svg
        v-if="name == 'left'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path d="M15 19L9 12L10.5 10.25M15 5L13 7.33333" />
    </svg>

    <svg
        v-if="name == 'left2'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path d="M13.5 9L10.5 12L13.5 15" />
        <path
            d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
        />
    </svg>

    <svg
        v-if="name == 'logo-predictor'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path
            d="M12.0001 7.88989L10.9301 9.74989C10.6901 10.1599 10.8901 10.4999 11.3601 10.4999H12.6301C13.1101 10.4999 13.3001 10.8399 13.0601 11.2499L12.0001 13.1099"
        />
        <path
            d="M8.30011 18.0399V16.8799C6.00011 15.4899 4.11011 12.7799 4.11011 9.89993C4.11011 4.94993 8.66011 1.06993 13.8001 2.18993C16.0601 2.68993 18.0401 4.18993 19.0701 6.25993C21.1601 10.4599 18.9601 14.9199 15.7301 16.8699V18.0299C15.7301 18.3199 15.8401 18.9899 14.7701 18.9899H9.26011C8.16011 18.9999 8.30011 18.5699 8.30011 18.0399Z"
        />
        <path d="M8.5 22C10.79 21.35 13.21 21.35 15.5 22" />
    </svg>

    <svg
        v-if="name == 'no'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" />
        <path
            d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
        />
    </svg>

    <svg
        v-if="name == 'ok'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path d="M8.5 12.5L10.5 14.5L15.5 9.5" />
        <path
            d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
        />
    </svg>

    <svg
        v-if="name == 'params'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <circle cx="8" cy="10" r="2" />
        <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 14 16)" />
        <path d="M8 14V19" />
        <path d="M16 10V5" />
        <path d="M8 5V6" />
        <path d="M16 19V18" />
        <path
            d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
        />
    </svg>

    <svg
        v-if="name == 'plus'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" />
        <path
            d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
        />
    </svg>

    <svg
        v-if="name == 'product'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path
            d="M20.2236 12.5257C19.6384 9.40452 19.3458 7.84393 18.2349 6.92196C17.124 6 15.5362 6 12.3606 6H11.6394C8.46386 6 6.87608 6 5.76518 6.92196C4.65428 7.84393 4.36167 9.40452 3.77645 12.5257C2.95353 16.9146 2.54207 19.1091 3.74169 20.5545C4.94131 22 7.17402 22 11.6394 22H12.3606C16.826 22 19.0587 22 20.2584 20.5545C20.9543 19.7159 21.108 18.6252 20.9537 17"
        />
        <path d="M9 6V5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5V6" />
    </svg>

    <svg
        v-if="name == 'protect'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path d="M9.5 12.4L10.9286 14L14.5 10" />
        <path
            d="M3 10.4167C3 7.21907 3 5.62028 3.37752 5.08241C3.75503 4.54454 5.25832 4.02996 8.26491 3.00079L8.83772 2.80472C10.405 2.26824 11.1886 2 12 2C12.8114 2 13.595 2.26824 15.1623 2.80472L15.7351 3.00079C18.7417 4.02996 20.245 4.54454 20.6225 5.08241C21 5.62028 21 7.21907 21 10.4167C21 10.8996 21 11.4234 21 11.9914C21 14.4963 20.1632 16.4284 19 17.9041M3.19284 14C4.05026 18.2984 7.57641 20.5129 9.89856 21.5273C10.62 21.8424 10.9807 22 12 22C13.0193 22 13.38 21.8424 14.1014 21.5273C14.6796 21.2747 15.3324 20.9478 16 20.5328"
        />
    </svg>

    <svg
        v-if="name == 'right'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path d="M9 5L11 7.33333M9 19L15 12L13.5 10.25" />
    </svg>

    <svg
        v-if="name == 'right2'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path d="M10.5 9L13.5 12L10.5 15" />
        <path
            d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
        />
    </svg>

    <svg
        v-if="name == 'text'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path
            d="M5.5 15.5C5.5 14.5572 5.5 14.0858 5.79289 13.7929C6.08579 13.5 6.55719 13.5 7.5 13.5H8.5C9.44281 13.5 9.91421 13.5 10.2071 13.7929C10.5 14.0858 10.5 14.5572 10.5 15.5V16.5C10.5 17.4428 10.5 17.9142 10.2071 18.2071C9.91421 18.5 9.44281 18.5 8.5 18.5C7.08579 18.5 6.37868 18.5 5.93934 18.0607"
        />
        <path
            d="M5.5 8.5C5.5 7.08579 5.5 6.37868 5.93934 5.93934C6.37868 5.5 7.08579 5.5 8.5 5.5C9.44281 5.5 9.91421 5.5 10.2071 5.79289C10.5 6.08579 10.5 6.55719 10.5 7.5V8.5C10.5 9.44281 10.5 9.91421 10.2071 10.2071C9.91421 10.5 9.44281 10.5 8.5 10.5H7.5C6.55719 10.5 6.08579 10.5 5.79289 10.2071C5.5 9.91421 5.5 9.44281 5.5 8.5Z"
        />
        <path
            d="M13.5 15.5C13.5 14.5572 13.5 14.0858 13.7929 13.7929C14.0858 13.5 14.5572 13.5 15.5 13.5H16.5C17.4428 13.5 17.9142 13.5 18.2071 13.7929C18.5 14.0858 18.5 14.5572 18.5 15.5C18.5 16.9142 18.5 17.6213 18.0607 18.0607C17.6213 18.5 16.9142 18.5 15.5 18.5C14.5572 18.5 14.0858 18.5 13.7929 18.2071C13.5 17.9142 13.5 17.4428 13.5 16.5V15.5Z"
        />
        <path
            d="M18.5 8.5C18.5 9.44281 18.5 9.91421 18.2071 10.2071C17.9142 10.5 17.4428 10.5 16.5 10.5H15.5C14.5572 10.5 14.0858 10.5 13.7929 10.2071C13.5 9.91421 13.5 9.44281 13.5 8.5V7.5C13.5 6.55719 13.5 6.08579 13.7929 5.79289C14.0858 5.5 14.5572 5.5 15.5 5.5C16.9142 5.5 17.6213 5.5 18.0607 5.93934"
        />
        <path d="M22 14C22 14.3492 22 14.6822 21.9991 15M14 22C17.7712 22 19.6569 22 20.8284 20.8284C21.4816 20.1752 21.7706 19.3001 21.8985 18" />
        <path d="M10 22C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14" />
        <path d="M10 2C6.22876 2 4.34315 2 3.17157 3.17157C2.51839 3.82475 2.22937 4.69989 2.10149 6M2 10C2 9.65081 2 9.31779 2.00093 9" />
        <path d="M14 2C17.7712 2 19.6569 2 20.8284 3.17157C22 4.34315 22 6.22876 22 10" />
    </svg>

    <svg
        v-if="name == 'stat'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path d="M3 22H21" />
        <path
            d="M3 17C3 17.9428 3 18.4142 3.29289 18.7071C3.58579 19 4.05719 19 5 19C5.94281 19 6.41421 19 6.70711 18.7071C7 18.4142 7 17.9428 7 17V11C7 10.0572 7 9.58579 6.70711 9.29289C6.41421 9 5.94281 9 5 9C4.05719 9 3.58579 9 3.29289 9.29289C3 9.58579 3 10.0572 3 11V13"
        />
        <path
            d="M10 7C10 6.05719 10 5.58579 10.2929 5.29289C10.5858 5 11.0572 5 12 5C12.9428 5 13.4142 5 13.7071 5.29289C14 5.58579 14 6.05719 14 7V17C14 17.9428 14 18.4142 13.7071 18.7071C13.4142 19 12.9428 19 12 19C11.0572 19 10.5858 19 10.2929 18.7071C10 18.4142 10 17.9428 10 17V7Z"
        />
        <path
            d="M21 11V17C21 17.9428 21 18.4142 20.7071 18.7071C20.4142 19 19.9428 19 19 19C18.0572 19 17.5858 19 17.2929 18.7071C17 18.4142 17 17.9428 17 17V4C17 3.05719 17 2.58579 17.2929 2.29289C17.5858 2 18.0572 2 19 2C19.9428 2 20.4142 2 20.7071 2.29289C21 2.58579 21 3.05719 21 4V7"
        />
    </svg>

    <svg
        v-if="name == 'star'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path
            d="M13.73 3.51001L15.49 7.03001C15.73 7.52002 16.37 7.99001 16.91 8.08001L20.1 8.61001C22.14 8.95001 22.62 10.43 21.15 11.89L18.67 14.37C18.25 14.79 18.02 15.6 18.15 16.18L18.86 19.25C19.42 21.68 18.13 22.62 15.98 21.35L12.99 19.58C12.45 19.26 11.56 19.26 11.01 19.58L8.01997 21.35C5.87997 22.62 4.57997 21.67 5.13997 19.25L5.84997 16.18C5.97997 15.6 5.74997 14.79 5.32997 14.37L2.84997 11.89C1.38997 10.43 1.85997 8.95001 3.89997 8.61001L7.08997 8.08001C7.61997 7.99001 8.25997 7.52002 8.49997 7.03001L10.26 3.51001C11.22 1.60001 12.78 1.60001 13.73 3.51001Z"
        />
    </svg>

    <svg
        v-if="name == 'timer'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <path d="M12 9V13L14.5 15.5" />
        <path d="M3.5 4.5L7.50002 2" />
        <path d="M20.5 4.5L16.5 2" />
        <path
            d="M7.5 5.20404C8.82378 4.43827 10.3607 4 12 4C16.9706 4 21 8.02944 21 13C21 17.9706 16.9706 22 12 22C7.02944 22 3 17.9706 3 13C3 11.3607 3.43827 9.82378 4.20404 8.5"
        />
    </svg>

    <svg
        v-if="name == 'user'"
        :class="'w-' + size + ' h-' + size"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
    >
        <circle cx="12" cy="9" r="3" />
        <path d="M17.9691 20C17.81 17.1085 16.9247 15 11.9999 15C7.07521 15 6.18991 17.1085 6.03076 20" />
        <path d="M22 14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22" />
        <path d="M10 22C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14" />
        <path d="M10 2C6.22876 2 4.34315 2 3.17157 3.17157C2 4.34315 2 6.22876 2 10" />
        <path d="M14 2C17.7712 2 19.6569 2 20.8284 3.17157C22 4.34315 22 6.22876 22 10" />
    </svg>

    <!-- ------ -->

    <!-- ------ -->

    <!-- ------ -->

    <!-- ------ -->

    <svg
        v-if="name == 'botOLD'"
        class="fill-stroke transition duration-300 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path class="st0" d="M4,7h16c1.1,0,2,0.9,2,2v11c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V9C2,7.9,2.9,7,4,7z" />
        <path class="st0" d="M11.9,1.2v2.5" />
        <path
            class="st0"
            d="M8.1,3.8h7.5c0.7,0,2.2,1.2,2.2,1.4v1.4c0,0.2-0.5,0.4-1.2,0.4H7.1C6.5,7,5.9,6.8,5.9,6.6V5.2
	C5.9,5,7.5,3.8,8.1,3.8z"
        />
        <path class="st0" d="M5.7,22v-0.8c0-1.1,1.2-2,2.7-2h6.9c1.5,0,2.7,0.9,2.7,2V22" />
        <circle class="st0" cx="7.1" cy="13.1" r="2.4" />
        <circle class="st0" cx="16.7" cy="13.1" r="2.4" />
        <path class="st0" d="M4.6,13.1H2" />
        <path class="st0" d="M22,13.1h-2.6" />
    </svg>
    <svg
        v-if="name == 'text2'"
        class="fill-stroke transition duration-300 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M20 7h-9" />
        <path d="M14 17H5" />
        <circle cx="17" cy="17" r="3" />
        <circle cx="7" cy="7" r="3" />
    </svg>
    <svg
        v-if="name == 'setting'"
        class="fill-stroke transition duration-300 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M20 7h-9" />
        <path d="M14 17H5" />
        <circle cx="17" cy="17" r="3" />
        <circle cx="7" cy="7" r="3" />
    </svg>

    <svg
        v-if="name == 'user2'"
        class="fill-stroke fill-white transition duration-300 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        viewBox="0 0 59.018 59.018"
        stroke-width="0"
    >
        <path
            d="M58.741,56.809l-5.969-6.244c1.746-1.919,2.82-4.458,2.82-7.25c0-5.953-4.843-10.796-10.796-10.796S34,37.361,34,43.314
		S38.843,54.11,44.796,54.11c2.441,0,4.688-0.824,6.499-2.196l6.001,6.277c0.196,0.205,0.459,0.309,0.723,0.309
		c0.249,0,0.497-0.092,0.691-0.277C59.108,57.841,59.123,57.208,58.741,56.809z M36,43.314c0-4.85,3.946-8.796,8.796-8.796
		s8.796,3.946,8.796,8.796s-3.946,8.796-8.796,8.796S36,48.164,36,43.314z"
        />
        <path
            d="M31,43.314c0-6.383,4.363-11.751,10.259-13.319c-1.01-2.515-1.807-5.313-1.996-8.186c-0.143-2.147-0.547-3.938-1.237-5.473
		c-1.424-3.164-4.469-6.947-10.91-7c-10.964,0.09-11.33,11.206-11.332,11.32c-0.125,6.47-4.134,12.855-5.851,15.3
		c-0.467,0.665-0.616,1.312-0.444,1.921c0.188,0.66,0.719,1.171,1.625,1.56c1.917,0.823,4.322,1.066,4.521,1.081h6.084
		c-0.167,0.324-0.395,0.735-0.512,0.813l-9.166,3.933c-0.713,0.356-1.349,0.83-1.915,1.395C5.132,42.002,2,35.371,2,28.018
		c0-14.061,11.439-25.5,25.5-25.5S53,13.958,53,28.018c0,1.359-0.112,2.691-0.318,3.992c0.63,0.441,1.224,0.93,1.771,1.468
		C54.811,31.714,55,29.888,55,28.018c0-15.163-12.336-27.5-27.5-27.5S0,12.855,0,28.018c0,7.976,3.417,15.167,8.86,20.195
		l-0.072,0.098l0.705,0.604c3.904,3.342,8.656,5.483,13.681,6.26c0.356,0.056,0.715,0.102,1.075,0.145
		c0.391,0.045,0.782,0.084,1.176,0.112c0.579,0.043,1.162,0.071,1.75,0.078c0.062,0,0.123,0.008,0.185,0.008
		c0.017,0,0.035-0.002,0.052-0.002c0.03,0,0.059,0.002,0.089,0.002c3.045,0,5.972-0.505,8.712-1.423
		C33.041,51.566,31,47.677,31,43.314z"
        />
    </svg>

    <svg
        v-if="name == 'plus2'"
        class="fill-stroke transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path stroke="none" d="M0 0h24v24H0z"></path>
        <circle cx="12" cy="12" r="9"></circle>
        <line x1="9" y1="12" x2="15" y2="12"></line>
        <line x1="12" y1="9" x2="12" y2="15"></line>
    </svg>
    <svg
        v-if="name == 'upload'"
        class="fill-stroke transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
        <polyline points="16 6 12 2 8 6"></polyline>
        <line x1="12" y1="2" x2="12" y2="15"></line>
    </svg>

    <svg
        v-if="name == 'image'"
        class="fill-stroke transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
        <circle cx="8.5" cy="8.5" r="1.5" />
        <polyline points="21 15 16 10 5 21" />
    </svg>

    <svg
        v-if="name == 'audio'"
        class="fill-stroke transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M3 18v-6a9 9 0 0118 0v6" />
        <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z" />
    </svg>

    <svg
        v-if="name == 'video'"
        class="fill-stroke transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path
            d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"
        ></path>
        <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
    </svg>

    <svg
        v-if="name == 'delete'"
        class="fill-stroke text-gray-400 cursor-pointer transition duration-300 group-hover:text-red-500 hover:text-red-500"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="15" y1="9" x2="9" y2="15"></line>
        <line x1="9" y1="9" x2="15" y2="15"></line>

        <!-- <path stroke="none" d="M0 0h24v24H0z"></path>
        <circle cx="12" cy="12" r="9"></circle>
        <line x1="9" y1="12" x2="15" y2="12"></line>
        <line x1="12" y1="9" x2="12" y2="15"></line> -->
    </svg>

    <svg
        v-if="name == 'back'"
        class="h-8 w-8 fill-stroke transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
    >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"></path>
    </svg>

    <svg
        v-if="name == 'badge'"
        class="h-8 w-8 fill-stroke text-gray-800 hover:text-blue-500"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <circle cx="18" cy="18" r="3"></circle>
        <circle cx="6" cy="6" r="3"></circle>
        <path d="M6 21V9a9 9 0 0 0 9 9"></path>
    </svg>

    <svg
        v-if="name == 'trash'"
        class="fill-stroke text-gray-400 cursor-pointer transition duration-300 group-hover:text-red-500 hover:text-red-500"
        :class="'w-' + size + ' h-' + size"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M20.5001 6H3.5" />
        <path d="M9.5 11L10 16" />
        <path d="M14.5 11L14 16" />
        <path
            d="M6.5 6C6.55588 6 6.58382 6 6.60915 5.99936C7.43259 5.97849 8.15902 5.45491 8.43922 4.68032C8.44784 4.65649 8.45667 4.62999 8.47434 4.57697L8.57143 4.28571C8.65431 4.03708 8.69575 3.91276 8.75071 3.8072C8.97001 3.38607 9.37574 3.09364 9.84461 3.01877C9.96213 3 10.0932 3 10.3553 3H13.6447C13.9068 3 14.0379 3 14.1554 3.01877C14.6243 3.09364 15.03 3.38607 15.2493 3.8072C15.3043 3.91276 15.3457 4.03708 15.4286 4.28571L15.5257 4.57697C15.5433 4.62992 15.5522 4.65651 15.5608 4.68032C15.841 5.45491 16.5674 5.97849 17.3909 5.99936C17.4162 6 17.4441 6 17.5 6"
        />
        <path
            d="M18.3735 15.3991C18.1965 18.054 18.108 19.3815 17.243 20.1907C16.378 21 15.0476 21 12.3868 21H11.6134C8.9526 21 7.6222 21 6.75719 20.1907C5.89218 19.3815 5.80368 18.054 5.62669 15.3991L5.16675 8.5M18.8334 8.5L18.6334 11.5"
        />
    </svg>

    <svg
        v-if="name == 'tl'"
        class="fill-sky-500 cursor-pointer transition duration-300 group-hover:fill-sky-400 hover:fill-sky-400"
        :class="'w-' + size + ' h-' + size"
        viewBox="0 0 100 100"
        stroke-width="0"
    >
        <path
            d="M88.723,12.142C76.419,17.238,23.661,39.091,9.084,45.047c-9.776,3.815-4.053,7.392-4.053,7.392    s8.345,2.861,15.499,5.007c7.153,2.146,10.968-0.238,10.968-0.238l33.62-22.652c11.922-8.107,9.061-1.431,6.199,1.431    c-6.199,6.2-16.452,15.975-25.036,23.844c-3.815,3.338-1.908,6.199-0.238,7.63c6.199,5.246,23.129,15.976,24.082,16.691    c5.037,3.566,14.945,8.699,16.452-2.146c0,0,5.961-37.435,5.961-37.435c1.908-12.637,3.815-24.321,4.053-27.659    C97.307,8.804,88.723,12.142,88.723,12.142z"
        />
    </svg>

    <svg
        v-if="name == 'vk'"
        class="fill-blue-500 cursor-pointer transition duration-300 group-hover:fill-blue-400 hover:fill-blue-400"
        :class="'w-' + size + ' h-' + size"
        viewBox="0 0 512 512"
        stroke-width="0"
    >
        <path
            d="M440.649,295.361c16.984,16.582,34.909,32.182,50.142,50.436
	c6.729,8.112,13.099,16.482,17.973,25.896c6.906,13.382,0.651,28.108-11.348,28.907l-74.59-0.034
	c-19.238,1.596-34.585-6.148-47.489-19.302c-10.327-10.519-19.891-21.714-29.821-32.588c-4.071-4.444-8.332-8.626-13.422-11.932
	c-10.182-6.609-19.021-4.586-24.84,6.034c-5.926,10.802-7.271,22.762-7.853,34.8c-0.799,17.564-6.108,22.182-23.751,22.986
	c-37.705,1.778-73.489-3.926-106.732-22.947c-29.308-16.768-52.034-40.441-71.816-67.24
	C58.589,258.194,29.094,200.852,2.586,141.904c-5.967-13.281-1.603-20.41,13.051-20.663c24.333-0.473,48.663-0.439,73.025-0.034
	c9.89,0.145,16.437,5.817,20.256,15.16c13.165,32.371,29.274,63.169,49.494,91.716c5.385,7.6,10.876,15.201,18.694,20.55
	c8.65,5.923,15.236,3.96,19.305-5.676c2.582-6.11,3.713-12.691,4.295-19.234c1.928-22.513,2.182-44.988-1.199-67.422
	c-2.076-14.001-9.962-23.065-23.933-25.714c-7.129-1.351-6.068-4.004-2.616-8.073c5.995-7.018,11.634-11.387,22.875-11.387h84.298
	c13.271,2.619,16.218,8.581,18.035,21.934l0.072,93.637c-0.145,5.169,2.582,20.51,11.893,23.931
	c7.452,2.436,12.364-3.526,16.836-8.251c20.183-21.421,34.588-46.737,47.457-72.951c5.711-11.527,10.622-23.497,15.381-35.458
	c3.526-8.875,9.059-13.242,19.056-13.049l81.132,0.072c2.406,0,4.84,0.035,7.17,0.434c13.671,2.33,17.418,8.211,13.195,21.561
	c-6.653,20.945-19.598,38.4-32.255,55.935c-13.53,18.721-28.001,36.802-41.418,55.634
	C424.357,271.756,425.336,280.424,440.649,295.361L440.649,295.361z"
        />
    </svg>
    <svg
        v-if="name == 'woman'"
        class="fill-pink-500 cursor-pointer transition duration-300 group-hover:fill-pink-400 hover:fill-pink-400"
        :class="'w-' + size + ' h-' + size"
        viewBox="0 0 59.018 59.018"
        stroke-width="0"
    >
        <path
            d="M58.741,56.809l-5.969-6.244c1.746-1.919,2.82-4.458,2.82-7.25c0-5.953-4.843-10.796-10.796-10.796S34,37.361,34,43.314
		S38.843,54.11,44.796,54.11c2.441,0,4.688-0.824,6.499-2.196l6.001,6.277c0.196,0.205,0.459,0.309,0.723,0.309
		c0.249,0,0.497-0.092,0.691-0.277C59.108,57.841,59.123,57.208,58.741,56.809z M36,43.314c0-4.85,3.946-8.796,8.796-8.796
		s8.796,3.946,8.796,8.796s-3.946,8.796-8.796,8.796S36,48.164,36,43.314z"
        />
        <path
            d="M31,43.314c0-6.383,4.363-11.751,10.259-13.319c-1.01-2.515-1.807-5.313-1.996-8.186c-0.143-2.147-0.547-3.938-1.237-5.473
		c-1.424-3.164-4.469-6.947-10.91-7c-10.964,0.09-11.33,11.206-11.332,11.32c-0.125,6.47-4.134,12.855-5.851,15.3
		c-0.467,0.665-0.616,1.312-0.444,1.921c0.188,0.66,0.719,1.171,1.625,1.56c1.917,0.823,4.322,1.066,4.521,1.081h6.084
		c-0.167,0.324-0.395,0.735-0.512,0.813l-9.166,3.933c-0.713,0.356-1.349,0.83-1.915,1.395C5.132,42.002,2,35.371,2,28.018
		c0-14.061,11.439-25.5,25.5-25.5S53,13.958,53,28.018c0,1.359-0.112,2.691-0.318,3.992c0.63,0.441,1.224,0.93,1.771,1.468
		C54.811,31.714,55,29.888,55,28.018c0-15.163-12.336-27.5-27.5-27.5S0,12.855,0,28.018c0,7.976,3.417,15.167,8.86,20.195
		l-0.072,0.098l0.705,0.604c3.904,3.342,8.656,5.483,13.681,6.26c0.356,0.056,0.715,0.102,1.075,0.145
		c0.391,0.045,0.782,0.084,1.176,0.112c0.579,0.043,1.162,0.071,1.75,0.078c0.062,0,0.123,0.008,0.185,0.008
		c0.017,0,0.035-0.002,0.052-0.002c0.03,0,0.059,0.002,0.089,0.002c3.045,0,5.972-0.505,8.712-1.423
		C33.041,51.566,31,47.677,31,43.314z"
        />
    </svg>
</template>

<script>
export default {
    name: "IconPack",
    props: {
        name: String,
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
