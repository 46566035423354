<template>
    <svg
        class="fill-stroke transition duration-300 cursor-pointer"
        :class="'w-' + size + ' h-' + size"
        :width="size * 4"
        :height="size * 4"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path
            d="M8 11.4C8 12.17 8.6 12.8 9.33 12.8H10.83C11.47 12.8 11.99 12.25 11.99 11.58C11.99 10.85 11.67 10.59 11.2 10.42L8.8 9.57995C8.32 9.40995 8 9.14995 8 8.41995C8 7.74995 8.52 7.19995 9.16 7.19995H10.66C11.4 7.20995 12 7.82995 12 8.59995"
        />
        <path d="M10 12.8501V13.5901" />
        <path d="M10 6.40991V7.18991" />
        <path
            d="M9.99 17.98C14.4028 17.98 17.98 14.4028 17.98 9.99C17.98 5.57724 14.4028 2 9.99 2C5.57724 2 2 5.57724 2 9.99C2 14.4028 5.57724 17.98 9.99 17.98Z"
        />
        <path d="M12.98 19.88C13.88 21.15 15.35 21.98 17.03 21.98C19.76 21.98 21.98 19.76 21.98 17.03C21.98 15.37 21.16 13.9 19.91 13" />
    </svg>
</template>

<script>
export default {
    name: "IconCoin2",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
